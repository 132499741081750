import React, { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import {
	Accordion,
	// Button,
} from "react-bootstrap"
import Scrollbar from "smooth-scrollbar"
// import { useDispatch, useSelector } from "react-redux"
import { PersonLinesFill, HddRack, Clipboard2Data, Server } from "react-bootstrap-icons"

//img
// import logo from "../../../../../src/assets/images/logo.png"

const minisidbar = () => {
	document.body.classList.toggle("sidebar-main")
}

const ManagerSidebar = (props) => {
	let location = useLocation()

	const urlParams = new URLSearchParams(window.location.search)
	const sidebar = urlParams.get("sidebar")
	var variant = ""
	if (sidebar !== null) {
		variant = ""
		switch (sidebar) {
			case "0":
				variant = "sidebar-dark"
				break
			case "1":
				variant = "sidebar-light"
				break
			default:
				variant = ""
				break
		}
	}

	// Collapse state
	const [activeMenu, setActiveMenu] = useState(false)
	const [activesubMenu, setSubmenu] = useState(false)
	useEffect(() => {
		Scrollbar.init(document.querySelector("#my-scrollbar"))
	})
	return (
		<>
			<div className={`iq-sidebar sidebar-default ${variant}`}>
				<div className="iq-sidebar-logo d-flex align-items-end justify-content-between">
					<Link to="/" className="header-logo">
						{/* <img
							src={logo}
							className={`img-fluid rounded-normal light-logo ${
								props.darkMode ? "d-none" : ""
							}`}
							alt="logo"
						/> */}
						<span>Cryptotech OÜ</span>
					</Link>
					<div className="side-menu-bt-sidebar-1">
						<svg
							onClick={minisidbar}
							xmlns="http://www.w3.org/2000/svg"
							className="text-light wrapper-menu"
							width="30"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M6 18L18 6M6 6l12 12"
							/>
						</svg>
					</div>
				</div>
				<div className="data-scrollbar" data-scroll="1" id="my-scrollbar">
					<nav className="iq-sidebar-menu">
						<Accordion
							as="ul"
							id="iq-sidebar-toggle"
							className="side-menu"
							onSelect={(e) => setActiveMenu(e)}
						>
							<li
								className={`${
									location.pathname === "/" ? "active" : ""
								}  sidebar-layout`}
							>
								<Link to="/" className="svg-icon">
									<i className="">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="18"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
											/>
										</svg>
									</i>
									<span className="ml-2">Dashboard</span>
									<p className="mb-0 w-10 badge badge-pill badge-primary">6</p>
								</Link>
							</li>
							<li className="px-3 pt-3 pb-2 ">
								<span className="text-uppercase small font-weight-bold">
									HQ
								</span>
							</li>
							<li
								className={`${
									location.pathname === "/commander-monitor" ? "active" : ""
								}  sidebar-layout`}
							>
								<Link to="/hq/farms" className="svg-icon ">
									<i>
										<Clipboard2Data />
									</i>
									<span className="ml-2">Farms</span>
								</Link>
							</li>
							<li className="px-3 pt-3 pb-2 ">
								<span className="text-uppercase small font-weight-bold">
									Commander
								</span>
							</li>
							<li
								className={`${
									location.pathname === "/commander-profiles" ? "active" : ""
								}  sidebar-layout`}
							>
								<Link to="/commander-profiles" className="svg-icon ">
									<i>
										<PersonLinesFill />
									</i>
									<span className="ml-2">Profiles</span>
								</Link>
							</li>
							<li
								className={`${
									location.pathname === "/commander-farms" ? "active" : ""
								}  sidebar-layout`}
							>
								<Link to="/commander-farms" className="svg-icon ">
									<i>
										<HddRack />
									</i>
									<span className="ml-2">Farms</span>
								</Link>
							</li>
							<li
								className={`${
									location.pathname === "/commander-monitor" ? "active" : ""
								}  sidebar-layout`}
							>
								<Link to="/commander-monitor" className="svg-icon ">
									<i>
										<Clipboard2Data />
									</i>
									<span className="ml-2">Monitor</span>
								</Link>
							</li>
							<li
								className={`${
									location.pathname === "/commander-database" ? "active" : ""
								}  sidebar-layout`}
							>
								<Link to="/commander-database" className="svg-icon ">
									<i>
										<Server />
									</i>
									<span className="ml-2">Database</span>
								</Link>
							</li>
							<li className="px-3 pt-3 pb-2 ">
								<span className="text-uppercase small font-weight-bold">
									Application
								</span>
							</li>
							<li
								className={`${
									location.pathname === "/customer" ? "active" : ""
								}  sidebar-layout`}
							>
								<Link to="/customer" className="svg-icon ">
									<i className="">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="18"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
											/>
										</svg>
									</i>
									<span className="ml-2">Customer</span>
								</Link>
							</li>
							<li
								className={`${
									location.pathname === "/product" ? "active" : ""
								}  sidebar-layout`}
							>
								<Link to="/product" className="svg-icon">
									<i className="">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="18"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
											/>
										</svg>
									</i>
									<span className="ml-2">Product</span>
								</Link>
							</li>
							<li
								className={`${
									location.pathname === "/order" ? "active" : ""
								}  sidebar-layout`}
							>
								<Link to="/order" className="svg-icon">
									<i className="">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="18"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
											/>
										</svg>
									</i>
									<span className="ml-2">Order</span>
								</Link>
							</li>
							<li
								className={`${
									location.pathname === "/invoice" ? "active" : ""
								}  sidebar-layout`}
							>
								<Link to="/invoice" className="svg-icon">
									<i className="">
										<svg
											className="icon line"
											width="18"
											id="receipt"
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												d="M17,16V3L13,5,10,3,7,5,3,3V17.83A3.13,3.13,0,0,0,5.84,21,3,3,0,0,0,9,18V17a1,1,0,0,1,1-1H20a1,1,0,0,1,1,1v1a3,3,0,0,1-3,3H6"
												strokeWidth="2"
												fill="none"
												strokeLinecap="round"
												strokeLinejoin="round"
											></path>
											<line
												x1="8"
												y1="10"
												x2="12"
												y2="10"
												strokeWidth="2"
												fill="none"
												strokeLinecap="round"
												strokeLinejoin="round"
											></line>
										</svg>
									</i>
									<span className="ml-2">Invoice</span>
								</Link>
							</li>
							<li
								className={`${
									location.pathname === "/todo" ? "active" : ""
								}  sidebar-layout`}
							>
								<Link to="/todo" className="svg-icon">
									<i className="">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="18"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
											/>
										</svg>
									</i>
									<span className="ml-2">Todo</span>
								</Link>
							</li>
							<li
								className={`${
									location.pathname === "/calendar" ? "active" : ""
								}  sidebar-layout`}
							>
								<Link to="/calendar" className="svg-icon">
									<i className="">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="18"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
											/>
										</svg>
									</i>
									<span className="ml-2">Calendar</span>
									<p className="mb-0 px-2 badge badge-pill badge-success">
										New
									</p>
								</Link>
							</li>
						</Accordion>
					</nav>
					<div className="pt-5 pb-5"></div>
				</div>
			</div>
		</>
	)
}

// export default ManagerSidebar;
export default ManagerSidebar
