import { useState } from "react"
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap"
import { database } from "../../../../config"

const Index = {
  clear: () => {
    database.ref("/database/index/").set(null)
    database.ref("/database/index-names/").set(null)
  },
  add: ({ uuid, value, type }) =>
    database.ref("/database/index/" + type + "/" + uuid).set(value || uuid),
}

const getIndex = async (item, cb) => {
  if (item === null) return cb(undefined)

  const { type } = item

  if (type === undefined) return cb(undefined, undefined)

  const definition = await database.ref("/database/type/" + type).once("value")

  if (definition === null) return cb(null, type)

  const data = definition.val()

  if (data === undefined || data === null) return cb(null, type)

  const { index } = data

  if (index === undefined || index === null) return cb(null, type)

  return cb(index, type)
}

const reindex = (setProcessing) => {
  //   Index.clear()
  //   return
  setProcessing(true)

  let inProgress = 0

  database.ref("/database/type/").once("value", (snapshot) => {
    const data = snapshot.val()

    Object.keys(data).map((uuid) => {
      if (data[uuid].name !== undefined)
        database.ref("/database/index-names/" + uuid).set(data[uuid].name)
    })
  })

  database.ref("/database/items").once("value", (snapshot) =>
    snapshot.forEach((child) => {
      const item = child.val()

      inProgress++

      getIndex(item, (index, type) => {
        if (type !== undefined)
          Index.add({
            uuid: child.key,
            value: index !== null ? item[index] : child.key || "dummy",
            type,
          })

        inProgress--

        if (inProgress === 0) {
          setProcessing(false)
        }
      })
    })
  )
}

const UI = ({ variant }) => {
  const [processing, setProcessing] = useState(false)

  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip>
          Iterates the whole database and adds their index properties to the
          search index.
        </Tooltip>
      }
    >
      <Button
        onClick={() => reindex(setProcessing)}
        disabled={processing}
        variant={variant}
      >
        Reindex
      </Button>
    </OverlayTrigger>
  )
}

export { UI }
