const EnContract = ({
	companyName,
	companyCode,
	fullName,
	idCode,
	address,
}) => {
	return (
		<div className="d-none">
			<div id="contractPDF" className="contractPDF">
				<div>
					<div style={{ textAlign: "center" }}>
						<b>Contract for the services of equipment co-location</b>
					</div>
					<br />
					<br />
					Tallinn, {new Date().toLocaleDateString()}
					<br />
					<br /> OÜ CryptoTech, registry code 14725379, registered address
					Pikaliiva 90/1-6, 13516, Tallinn, represented by the member of the
					management board Vladislav Žurbenko / Jan Kolossovski acting on the
					basis of the Articles of Association (hereinafter CryptoTech), as the
					First Party
					<br />
					and
					<br />
					{companyName ? companyName : fullName},{" "}
					{companyName ? `registry code ${companyCode} /` : null} personal ID
					code ({idCode}),{" "}
					{companyName
						? `registered address ${address} represented by the member of the management board ${fullName} acting on
							the basis of the Articles of Association`
						: `address ${address}`}
					, (hereinafter the Customer), as the Second Party have entered into
					this contract (hereinafter the Contract) as follows:
					<br />
					<br />
				</div>
				<div>
					<ol>
						<li>
							<b>Object of the Contract</b>
							<ol>
								<li>
									The object of this Contract shall constitute the rendering of
									computer equipment co-location services by CryptoTech to the
									Customer, i.e., the provision of the space for the placement
									of the Customer’s equipment in CryptoTech’s data centre and
									the provision of the internet connection, power supply and
									other additional services (hereinafter the Services) to the
									Customer.
								</li>
								<li>
									The characteristics of the CryptoTech’s data centre are
									provided in Annex 1 to the Contract.
								</li>
							</ol>
						</li>
						<li>
							<b>Procedure for rendering the Services</b>
							<ol>
								<li>
									The starting date of rendering the Services shall be the date
									when the Customer’s equipment has been placed in CryptoTech’s
									data centre and connected to the power network and the
									internet.
								</li>
								<li>
									The list of the equipment transferred by the Customer to
									CryptoTech shall be provided in Annex 2 to the Contract.
								</li>
							</ol>
						</li>
						<li>
							<b>Rights and obligations of CryptoTech</b>
							<ol>
								<li>
									CryptoTech shall ensure that the Services are rendered to the
									Customer around the clock.
								</li>
								<li>
									CryptoTech shall inform the Customer about any malfunction of
									the equipment and start fault diagnosis within three business
									days. Having completed the fault diagnosis, CryptoTech shall
									contact the Customer to discuss the options for resolving the
									problem and the cost thereof in case the fault has not
									originated with CryptoTech.
								</li>
								<li>
									CryptoTech shall eliminate the fault at its own expense within
									(3) days in case the circumstances of the fault originate with
									CryptoTech.
								</li>
								<li>
									CryptoTech shall inform the Customer about any potential
									circumstances that might prevent the Service form being used,
									including irregularities due to technical reasons, at least 48
									hours before.
								</li>
								<li>
									CryptoTech shall have the right to make changes to the prices
									and conditions of the Services to be rendered, informing the
									Customer thereof in writing at least 30 days before. In case
									the Customer does not find the changes acceptable, they shall
									have the right to withdraw from the Contract by the end of the
									30-day period of notice given by CryptoTech.
								</li>
								<li>
									CryptoTech shall have the right to suspend or restrict
									rendering the Services in case the Customer has breached the
									terms of this Contract, particularly but not exclusively if
									the Customer has failed to pay an invoice by the due date
									stated on the invoice or delayed the payment of the invoice by
									no less than 5 days. CryptoTech shall inform the Customer
									about suspending or restricting the Services no less than 5
									days before by e-mail. In case the Customer has failed to pay
									the outstanding amount within the said 5 days, CryptoTech
									shall disconnect the equipment form the power network and the
									internet. The suspension or restriction of the service shall
									not suspend the accrual of the fee for equipment co-location
									which shall be paid under Annex 3 to the Contract.
								</li>
								<li>
									CryptoTech shall ensure that the personal data of the Customer
									are processed in accordance with the requirements of law.
								</li>
								<li>
									As a supplementary service, CryptoTech offers an option of
									replacing 95% of all the faulty components of the Customer’s
									equipment. This service shall be rendered on the basis of a
									separate price quotation.
								</li>
							</ol>
						</li>
						<li>
							<b>Rights and obligations of the Customer</b>
							<ol>
								<li>
									The Customer shall have the right to use the Service in
									accordance with the Contract and legal acts.
								</li>
								<li>
									The Customer shall:
									<ol>
										<li>
											pay for the Services by the due dates stated on the
											invoices issued by CryptoTech;
										</li>
										<li>
											inform CryptoTech about any identified faults, failures or
											malfunctions by phone + 372 53236058 or by e-mail to
											info@cryptotech.com.
										</li>
									</ol>
								</li>
								<li>
									The Customer shall bear full responsibility for the
									configuration, operable condition and monitoring of their
									equipment.
								</li>
								<li>
									In the process of transferring the equipment for co-location,
									the Customer may order equipment diagnosis by CryptoTech. In
									case any faults are identified, CryptoTech will suggest
									options for eliminating the faults of the equipment and offer
									a price quotation on the above.
								</li>
							</ol>
						</li>
						<li>
							<b>Payment for the Services</b>
							<ol>
								<li>
									The prices of the Services of CryptoTech’s data centre shall
									be provided in Annex 3 to the Contract.
								</li>
								<li>
									The cost of the Services shall be determined on the basis of
									the number of equipment units transferred to CryptoTech as
									well as the expenses associated with the operation of the
									Customer’s equipment which are to be compensated. The expenses
									associated with the operation of the Customer’s equipment
									shall be calculated on a monthly basis depending on actual
									consumption.
								</li>
								<li>
									CryptoTech shall issue monthly invoices for the Services to
									the Customer. The due date for the payment of the invoice
									shall constitute 7 days after it is issued.
								</li>
								<li>
									In case of delaying a payment, the Customer shall pay a
									penalty in the amount of 0.066% of the outstanding amount for
									each day of delay.
								</li>
								<li>
									In the event of a failure to make the payment on time, the
									Customer’s equipment shall be disconnected from power supply
									and the internet in accordance with section 3.6 of the
									Contract. The suspension or restriction of the service shall
									not suspend the accrual of the fee for equipment co-location
									which shall be paid under Annex 3 to the Contract.
								</li>
								<li>
									The Customer shall compensate for all the expenses resulting
									from debt recovery, including the delivery of reminders, legal
									assistance and the services of a collecting agency.
								</li>
							</ol>
						</li>
						<li>
							<b>Confidentiality</b>
							<ol>
								<li>
									The Customer shall not disclose the exact location, access
									codes (if any) and any other information concerning
									CryptoTech’s data centre and this Contract. In the event of
									violating this clause, the Customer shall pay a fine in the
									amount of 15,000 euros for each instance of violation within
									10 days after receiving the relevant claim from CryptoTech.
								</li>
								<li>
									The information provided by the Customer shall be regarded as
									confidential, and CryptoTech shall not disclose it to third
									parties without a prior written agreement of the other Party
									except for the cases provided by law.
								</li>
								<li>
									CryptoTech may transfer and disclose the Customer’s data to
									the credit registry and collecting agencies in connection with
									the Customer’s debt. The Customer shall hereby consent to the
									above.
								</li>
								<li>
									The duty of confidentiality shall remain in force indefinitely
									and shall survive the expiration of the Contract.
								</li>
							</ol>
						</li>
						<li>
							<b>Contract duration, amendment and termination</b>
							<ol>
								<li>
									The Contract shall enter into force upon it signing and shall
									remain in force indefinitely.
								</li>
								<li>
									The Parties shall have the right to withdraw from the Contract
									for any reason, informing the other Party thereof 10 days in
									advance. The equipment shall be returned to the Customer upon
									the termination of the above notice period.
								</li>
								<li>
									In case of a major breach of the Contract, either Party shall
									have the right to withdraw from the Contract without a prior
									notice.
								</li>
								<li>
									In the event of a breach of Contract which is not major,
									withdrawal from the Contract shall only be possible after the
									extension period given to the Party at fault for the
									performance of their obligations. If the Party fails to
									perform the obligation within the extension period, the
									Contract shall be terminated.
								</li>
							</ol>
						</li>
						<li>
							<b>Liability of the Parties</b>
							<ol>
								<li>
									The Parties shall not be liable for a failure to perform on
									substandard performance of their contractual obligations
									resulting from force majeure.
								</li>
								<li>
									Force majeure shall refer to any unforeseeable situation which
									is beyond any control of the Parties, including but not
									limited to a fire, explosion, natural disasters, war, etc.
								</li>
								<li>
									The occurrence of force majeure shall not relieve the Parties
									from performing their obligations to remedy the force majeure
									and its consequences; the Parties shall also continue to
									perform their obligations once the above obstacles have been
									removed.
								</li>
								<li>
									CryptoTech shall compensate for the harm resulting from the
									loss of or damage to the Customer’s equipment if the above
									harm is inflicted in the period between the acceptance of the
									equipment for co-location and its return and if the above harm
									results from the circumstances within the control of
									CryptoTech. CryptoTech shall not bear responsibility for the
									loss of or damage to the Customer’s equipment if the above
									harm is inflicted by a fault in the Customer’s equipment or
									other circumstances beyond the control of CryptoTech.
								</li>
								<li>
									The Customer shall compensate CryptoTech and/or other
									customers of CryptoTech’s data centre for the harm resulting
									from a hazard or fault in the Customer’s equipment transferred
									to CryptoTech’s data centre for co-location.
								</li>
								<li>
									To secure all the claims arising from this Contract,
									CryptoTech shall have a lien over the Customer’s equipment
									placed for co-location in CryptoTech’s data centre.
								</li>
							</ol>
						</li>
						<li>
							<b>Final provisions</b>
							<ol>
								<li>
									The Customer warrants and guarantees that they have reviewed
									and understood all the terms and conditions of the Contract
									before the conclusion of the Contract and have been informed
									about the rights and obligations pursuant to the Contract.
								</li>
								<li>
									Any disputes pertaining to the performance, amendment or
									termination of the Contract shall be resolved by agreement of
									the Parties. In the event of a failure to reach an agreement,
									disputes shall be resolved in Harju County Court.
								</li>
								<li>
									The Contract is executed in two identical copies of equal
									legal force, one for CryptoTech and the other for the
									Customer.
								</li>
							</ol>
						</li>
						<li>
							<b>Signatures of the Parties</b>
							<br />
							<div>CryptoTech OÜ Representor</div>
							<br />
							<br />
							<div>____________________</div>
							<br />
							<br />
							<div>Customer</div>
							<br />
							<br />
							<div>____________________</div>
						</li>
					</ol>
				</div>
			</div>
		</div>
	)
}

export default EnContract
