/**
 * Converts month number to a human readable name
 *
 * @param {number} monthNumber
 * @param {string} [locale='en-US'] Locale code, default `en-US`
 * @param {string} [format='long'] Month name format, default `long`
 *
 * @returns {string} `string`
 */
export default function toMonthName(
  monthNumber,
  locale = "en-US",
  format = "long"
) {
  const date = new Date()
  const options = { month: format }

  date.setMonth(monthNumber - 1)

  return date.toLocaleString(locale, options)
}
