import React, { useState, useEffect } from "react"
import { Row, Col, ButtonGroup, Button, Modal } from "react-bootstrap"
import Chart from "react-apexcharts"
import Form from "react-bootstrap/Form"

import Card from "../../../components/Card"

import Daily from "./Charts/Daily"

import {
  Transform,
  LeftRightArrows,
  sum_up_entries,
  compute_uptime,
  toMonthName,
  useStateRealtimeReference,
} from "./Utils"

import { database } from "../../../config"

import { InvoiceSender } from "./SmartAccounts"
import { Bank } from "react-bootstrap-icons"

const DAYS_IN_MONTH = 31

const parseConsumptionHistory = (data) => {
  if (Array.isArray(data) === true) return data.reduce((a, b) => a + b, 0)
  else
    return Object.keys(data)
      .map((item) => parseConsumptionHistory(data[item]))
      .reduce((a, b) => a + b, 0)
}

const compute_daily_consumptions = (
  data,
  year,
  month,
  efficiency = 100,
  overhead = 0
) => {
  const daily = []
  let sum = 0
  const workers = Object.keys(data || {})
  const days = [...Array(DAYS_IN_MONTH).keys()]
  days.map((day) => {
    const ts = year + "/" + month + "/" + (day + 1)
    let todays_consumption = 0

    workers.map((worker) => {
      if (
        data === undefined ||
        data[worker] === undefined ||
        data[worker][year] === undefined ||
        data[worker][year][month] === undefined ||
        data[worker][year][month][day] === undefined
      ) {
        todays_consumption += 0
      } else {
        const today = data[worker][year][month][day]

        todays_consumption += sum_up_entries(
          today,
          (value) => (value + overhead) / (efficiency / 100)
        )
      }
    })

    daily.push([ts, todays_consumption])

    sum += todays_consumption
  })

  return {
    series: [
      {
        name: "Daily consumptions",
        data: daily,
      },
    ],
    total: sum,
  }
}

function updateInvoiceRows(
  data,
  year,
  month,
  set,
  prices,
  efficiency = 100,
  overhead = 0
) {
  const result = []

  // Find total known workers for this year and month
  const uptime = compute_uptime(data, year, month)
  const workers = Object.keys(uptime)
  const discount = Math.floor(
    100 -
      (Object.values(uptime).reduce((a, b) => a + b, 0) / workers.length) * 100
  )

  const rent = {
    code: "00010",
    description: "Rent - Riiuli rent hotelis",
    price: prices.rent,
    quantity: workers.length,
    discount,
    vatPc: prices.vat,
    unit: "rack(s)",
  }

  result.push(rent)

  workers
    .map((worker) => [worker, data[worker][year][month]])
    .map(([worker, consumption]) => {
      let total = 0

      Object.keys(consumption).forEach((day) => {
        Object.keys(consumption[day]).forEach((hour) => {
          if (
            consumption[day][hour] !== undefined &&
            consumption[day][hour][0] !== undefined
          )
            total += (consumption[day][hour][0] + overhead) / (efficiency / 100)
        })
      })

      const row = {
        code: "00010",
        description: `Elekter 8 - Elektri kuulud Hotelis - Worker #${worker} - (${prices.electricity.day} + ${prices.electricity.night} vorguteenus kW/h)`,
        price: prices.electricity.day + prices.electricity.night,
        quantity: Math.floor(total / 1000),
        vatPc: "20",
        unit: "kW/h",
      }

      result.push(row)
    })

  set(result)
}

const defaults = {
  supply_efficiency: 90,
  overhead_consumption: 100,
}

const ConsumptionChart = ({ farm }) => {
  const root = `/settings/farm/${farm}`
  const [supplyEfficiency, setSupplyEfficiency] = useStateRealtimeReference(
    `${root}/supply_efficiency`,
    defaults.supply_efficiency
  )
  const [overheadConsumption, setOverheadConsumption] =
    useStateRealtimeReference(
      `${root}/overhead_consumption`,
      defaults.overhead_consumption
    )

  const [showInvoicePreview, setShowInvoicePreview] = useState(false)
  const [invoiceRows, setInvoiceRows] = useState([])

  const [series, setSeries] = useState([])
  const [data, setData] = useState({})
  const [total, setTotal] = useState(0)
  const [year, setYear] = useState(new Date().getFullYear())
  const [month, setMonth] = useState(new Date().getMonth() + 1)

  const prices = {
    rent: 25,
    electricity: {
      day: 0.1309,
      night: 0.0478,
    },
    vat: "20",
  }

  useEffect(() => {
    const result = compute_daily_consumptions(
      data,
      year,
      month,
      supplyEfficiency,
      overheadConsumption
    )

    updateInvoiceRows(
      data,
      year,
      month,
      setInvoiceRows,
      prices,
      supplyEfficiency,
      overheadConsumption
    )

    setSeries(result.series)
    setTotal(result.total)
  }, [data, year, month, supplyEfficiency, overheadConsumption])

  useEffect(
    () =>
      database
        .ref("/consumption/" + farm + "/")
        .on(
          "value",
          (snapshot) => snapshot !== undefined && setData(snapshot.val())
        ),
    [farm]
  )

  const Controls = (
    <Form>
      <Row>
        <Col>
          <LeftRightArrows value={year} setValue={setYear}>
            {year}
          </LeftRightArrows>
        </Col>
        <Col>
          <LeftRightArrows value={month} setValue={setMonth}>
            {toMonthName(month)}
          </LeftRightArrows>
        </Col>
        <Col>
          <ButtonGroup className="btn-group-toggle">
            <Button variant="outline-primary button button-icon">
              Total:&nbsp;<b>{Transform.W.to.kW(total)}&nbsp;kW</b>
            </Button>
            <Button
              onClick={() => setShowInvoicePreview(true)}
              variant="outline-primary button button-icon"
            >
              <Bank />
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </Form>
  )

  const Configurations = (
    <Form>
      <Row>
        <Col>
          <Form.Label>Power supply efficiency</Form.Label>
        </Col>
        <Col>
          <Form.Label>Fans and motherboard consumption</Form.Label>
        </Col>
      </Row>
      <Row>
        <Col>
          <LeftRightArrows
            value={supplyEfficiency}
            setValue={setSupplyEfficiency}
            min={50}
            max={100}
            step={5}
          >
            {supplyEfficiency}%
          </LeftRightArrows>
        </Col>
        <Col>
          <LeftRightArrows
            value={overheadConsumption}
            setValue={setOverheadConsumption}
            min={0}
            max={300}
            step={10}
          >
            {overheadConsumption}W
          </LeftRightArrows>
        </Col>
      </Row>
    </Form>
  )

  return (
    <Card>
      <InvoiceSender
        farm={farm}
        show={showInvoicePreview}
        setShow={setShowInvoicePreview}
        rows={invoiceRows}
      />
      <Card.Body>
        {Configurations}
        <br />
        <Chart options={Daily} series={series} type="bar" height="350" />
        <br />
        {Controls}
        <br />
      </Card.Body>
    </Card>
  )
}

const ConsumptionCommander = ({ farm, show, setShow }) => {
  return (
    <Modal size="lg" onHide={() => setShow(false)} show={show}>
      <Modal.Header>
        <Modal.Title>Electricity consumption: {farm}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ConsumptionChart farm={farm} />
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup className="btn-group-toggle">
          <Button
            href="#"
            variant="outline-primary button button-icon"
            onClick={() => setShow(false)}
          >
            Close
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  )
}

export default ConsumptionCommander
