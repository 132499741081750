import { Fragment, useState } from "react"
import { Button, ButtonGroup, Form } from "react-bootstrap"
import { ArrowDownShort, ArrowUpShort } from "react-bootstrap-icons"
import { Item } from "."

const Error = ({ description }) => {
  return (
    <>
      <>This is Error</>
      <br />
      <>Description: {description}</>
    </>
  )
}

const CreateShowHide = ({ initial }) => {
  const [show, setShow] = useState(initial)
  const toggle = () => setShow(!show)

  const element = ({ children }) => {
    return show ? <>{children}</> : <></>
  }

  return [element, toggle, show]
}

const GPU = ({ serial, model, owner, warranty, folded, foldable }) => {
  const [BodyElement, toggleBody, bodyStatus] = CreateShowHide({
    initial: !folded,
  })

  return (
    <>
      <>
        {foldable && (
          <>
            &nbsp;
            <Button size="sm" variant="outline-primary" onClick={toggleBody}>
              {bodyStatus ? <ArrowDownShort /> : <ArrowUpShort />}
            </Button>
          </>
        )}
      </>
      <BodyElement>
        <br />
        <>
          Model: <Item uuid={model} />
        </>
        <br />
        <>
          Owner: <Item uuid={owner} />
        </>
        <br />
        <>Warranty untill: {warranty}</>
      </BodyElement>
    </>
  )
}

GPU.defaultProps = {
  folded: false,
  foldable: false,
}

const Batch = ({ items, date, source }) => {
  return (
    <>
      <h4>Batch</h4>
      <br />
      <>Date: {date}</>
      <br />
      <>From: {source}</>
      <br />
      <>Items: {items.length}</>
      <br />
      <>
        {items.map((uuid) => (
          <Fragment key={uuid}>{uuid}</Fragment>
        ))}
      </>
    </>
  )
}

Batch.defaultProps = {
  items: [],
  date: "unkown",
  source: "unkown",
}

const GPUModel = ({ manufacturer, memory, model, name }) => {
  return (
    <>
      Common name: {name}.
      <br />
      Manufactured by {manufacturer}.
      <br />
      Memory: {memory.manufacturer} {memory.interface}, size: {memory.size} GB
    </>
  )
}

const GPUList = ({ list, editing }) => {
  return (
    <>
      <>Count: {list.length.toString()}</>
      {list.map((gpu, index) => (
        <Item uuid={gpu} key={gpu + index} folded foldable editing={editing} />
      ))}
      {editing && (
        <>
          <ButtonGroup>
            <Button size="sm" variant="outline-primary">
              Add
            </Button>
          </ButtonGroup>
        </>
      )}
    </>
  )
}

GPUList.defaultProps = {
  list: [],
  editing: false,
}

const Rig = ({ built, model, owner, gpus, editing }) => {
  return (
    <>
      <>
        <b>Model</b>:
        <Item uuid={model} editing={editing} />
      </>
      <br />
      <>
        <b>Owner</b>:
        <Item uuid={owner} editing={editing} />
      </>
      <br />
      <>
        <Item uuid={gpus} folded={false} />
      </>
      <br />
      Status: <b>In office for fixing</b>
      <br />
      Last change: <b>{built}</b>
      <br />
      Built: <b>{built}</b>
    </>
  )
}

Rig.defaultProps = {
  editing: false,
}

const RigType = ({ name, manufacturer }) => {
  return (
    <>
      <>
        Mining rig: {name}, <small>by {manufacturer}</small>
      </>
    </>
  )
}

const UserProfile = ({ name, joined }) => {
  return (
    <>
      <>Name: {name}</>
      <br />
      <>
        Customer since: <span>{joined}</span>
      </>
    </>
  )
}

UserProfile.defaultProps = {
  name: "unkown",
  joined: "unkown",
}

export { Error, GPU, GPUModel, GPUList, Rig, RigType, UserProfile, Batch }
