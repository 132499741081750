import { Modal } from "react-bootstrap"

const MyModal = ({ title, size, children, footer, show, hide }) => {
  return (
    <Modal size={size} show={show} onHide={hide}>
      {title && (
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      )}
      {children && <Modal.Body>{children}</Modal.Body>}
      {footer && <Modal.Footer>{footer}</Modal.Footer>}
    </Modal>
  )
}

MyModal.defaultProps = {
  title: null,
  size: "lg",
  children: [],
  footer: null,
  hide: () => {},
}

export default MyModal
