import { useContext } from "react"
import { ArticleContext } from "../contexts"

const Article = ({ children: cb }) => {
  const context = useContext(ArticleContext)

  return <>{cb(context)}</>
}

Article.defaultProps = {
  children: () => {},
}

export default Article
