import { useContext, useEffect, useState, useRef } from "react"
import { Dropdown } from "react-bootstrap"
import { database } from "../../../../config"
import { FarmContext, HQContext } from "../contexts"
import BasicTooltip from "./BasicTooltip"

const ProfileSelectorTooltip = <>Select an additional configuration for all <b>workers</b> in a farm.</>

const ProfileSelector = ({ variant }) => {
  const { profiles } = useContext(HQContext)
  const { farm } = useContext(FarmContext)

  let firstload = useRef(true)

  const [loading, setLoading] = useState(true)
  const [selected, setSelected] = useState(null)

  useEffect(() => {
    let mounted = true

    database.ref(`/farm/${farm.id}/default`).on("value", (snapshot) => {
      if (mounted === true) {
        if (firstload.current === true) {
          setLoading(false)
          firstload.current = false
        }

        setSelected(snapshot.val())
      }
    })

    return () => {
      mounted = false
    }
  }, [])

  const onProfileSelect = (profile) => {
    setLoading(true)

    database.ref(`/farm/${farm.id}/default`).set(profile)

    farm.workers
      .all()
      .then((workers) =>
        Promise.all(
          workers.map(
            (worker) =>
              worker.data.stats.online === true &&
              worker.command("miner restart")
          )
        )
      )
      .then(() => setLoading(false))
  }

  return (
    <div className="property" style={{ cursor: "pointer" }}>
      <BasicTooltip text={ProfileSelectorTooltip}>
        <div className="value">
          <Dropdown>
            <Dropdown.Toggle
              disabled={loading}
              variant={variant}
              style={{ margin: 0, padding: 0 }}
            >
              {loading ? "Loading..." : selected || "Select profile"}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {Object.keys(profiles).map((name) => (
                <Dropdown.Item key={name} onClick={() => onProfileSelect(name)}>
                  {name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="label">Profile</div>
      </BasicTooltip>
    </div>
  )
}

ProfileSelector.defaultProps = {
  variant: "outline",
}

export default ProfileSelector
