import React, { useState, useEffect, useRef } from "react"
import { Container, Row, Col, Button, Tab, Nav, Form } from "react-bootstrap"
import Card from "../../../components/Card"
import { Link, useParams, useHistory } from "react-router-dom"
import { db } from "../../../config"

import user1 from "../../../assets/images/user/1.jpg"

const CustomerEdit = () => {
	const { id } = useParams()
	const [fullName, setFullName] = useState("")
	const [clientType, setClientType] = useState("customer")
	const [idCode, setIdCode] = useState("")
	const [email, setEmail] = useState("")
	const [companyName, setCompanyName] = useState("")
	const [companyRegCode, setCompanyRegCode] = useState("")
	const [companyVatNumber, setCompanyVatNumber] = useState("")
	const [socialMedia, setSocialMedia] = useState("")
	const [phone, setPhone] = useState("")
	const [country, setCountry] = useState("")
	const [address, setAddress] = useState("")
	const [zipcode, setZipcode] = useState("")
	const [bio, setBio] = useState("")
	const [notifications, setNotifications] = useState([])

	const history = useHistory()

	const fetchCustomer = async () => {
		const response = db.collection("customers").doc(id)
		await response.get().then((item) => {
			const data = item.data()
			setFullName(data.fullName)
			setIdCode(data.idCode)
			setClientType(data.clientType)
			setEmail(data.email)
			setCompanyName(data.companyName)
			setCompanyRegCode(data.companyRegCode)
			setCompanyVatNumber(data.companyVatNumber)
			setSocialMedia(data.socialMedia)
			setPhone(data.phone)
			setCountry(data.country)
			setAddress(data.address)
			setZipcode(data.zipcode)
			setBio(data.bio)
			setNotifications(data.notifications)
		})
	}

	const handleCheckboxChange = (value) => {
		let newArray = [...notifications, value]
		if (notifications.includes(value)) {
			newArray = newArray.filter((notification) => notification !== value)
		}
		setNotifications(newArray)
	}

	const handleUpdateCustomer = async (e) => {
		e.preventDefault()
		const response = db.collection("customers").doc(id)
		await response
			.update({
				fullName: fullName,
				idCode: idCode,
				email: email,
				companyName: companyName,
				companyRegCode: companyRegCode,
				companyVatNumber: companyVatNumber,
				socialMedia: socialMedia,
				phone: phone,
				country: country,
				clientType: clientType,
				address: address,
				zipcode: zipcode,
				bio: bio,
				notifications: notifications,
				updated: Date.now(),
			})
			.then(history.push(`/customer-view/${id}`))
		// const response = db.collection("miners").doc(id)
		// await response.get().then((item) => {
		// 	const data = item.data()
		// 	setSerials(data)
		// 	setDescription(data.minerBio)
		// })
	}

	useEffect(() => {
		fetchCustomer()
	}, [])
	return (
		<>
			<Container fluid>
				<Row>
					<Col lg="12">
						<div className="d-flex flex-wrap align-items-center justify-content-between">
							<div className="d-flex align-items-center justify-content-between">
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb p-0 mb-0">
										<li className="breadcrumb-item">
											<Link to="/customer">Customers</Link>
										</li>
										<li className="breadcrumb-item active" aria-current="page">
											Edit Customer
										</li>
									</ol>
								</nav>
							</div>
							<Link
								to="/customer"
								className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="20"
									viewBox="0 0 20 20"
									fill="currentColor"
								>
									<path
										fillRule="evenodd"
										d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
										clipRule="evenodd"
									/>
								</svg>
								<span className="ml-2">Back</span>
							</Link>
						</div>
					</Col>
					<Col lg="12" className="mb-3 d-flex justify-content-between">
						<h4 className="font-weight-bold d-flex align-items-center">
							Edit Customer
						</h4>
					</Col>
					<Col lg="12">
						<Card>
							<Card.Body>
								<Row>
									<Col md="12">
										<div className="col-md-6 mb-3 p-0">
											<Form.Label className="font-weight-bold text-muted text-uppercase">
												Client Type
											</Form.Label>
											<br />
											<div className="form-check form-check-inline">
												<div className="custom-control custom-radio custom-control-inline">
													<Form.Control
														type="radio"
														id="customer"
														name="customRadio-1"
														className="custom-control-input"
														value={clientType}
														onChange={(e) => setClientType(e.target.id)}
														defaultChecked
													/>
													<Form.Label
														className="custom-control-label"
														htmlFor="customer"
													>
														Customer
													</Form.Label>
												</div>
											</div>
											<div className="form-check form-check-inline">
												<div className="custom-control custom-radio custom-control-inline">
													<Form.Control
														type="radio"
														id="company"
														name="customRadio-1"
														className="custom-control-input"
														value={clientType}
														onChange={(e) => setClientType(e.target.id)}
													/>
													<Form.Label
														className="custom-control-label"
														htmlFor="company"
													>
														Company
													</Form.Label>
												</div>
											</div>
										</div>
										<Form className="row g-3 date-icon-set-modal">
											<div className="col-md-6 mb-3">
												<Form.Label
													htmlFor="Text1"
													className="font-weight-bold text-muted text-uppercase"
												>
													Full Name
												</Form.Label>
												<Form.Control
													type="text"
													id="Text1"
													placeholder="Enter Full Name"
													value={fullName}
													onChange={(e) => setFullName(e.target.value)}
												/>
											</div>
											<div className="col-md-6 mb-3">
												<Form.Label
													htmlFor="Text1"
													className="font-weight-bold text-muted text-uppercase"
												>
													Personal ID Code
												</Form.Label>
												<Form.Control
													type="text"
													id="Text1"
													placeholder="Enter Personal ID Code"
													value={idCode}
													onChange={(e) => setIdCode(e.target.value)}
												/>
											</div>
											<div className="col-md-6 mb-3">
												<Form.Label
													htmlFor="Text3"
													className="font-weight-bold text-muted text-uppercase"
												>
													Social Media
												</Form.Label>
												<Form.Control
													type="text"
													id="Text3"
													placeholder="Enter Social Media"
													value={socialMedia}
													onChange={(e) => setSocialMedia(e.target.value)}
												/>
											</div>
											<div className="col-md-6 mb-3">
												<Form.Label
													htmlFor="Text4"
													className="font-weight-bold text-muted text-uppercase"
												>
													Email
												</Form.Label>
												<Form.Control
													type="text"
													id="Text4"
													placeholder="Enter Email"
													value={email}
													onChange={(e) => setEmail(e.target.value)}
												/>
											</div>
											<div className="col-md-6 mb-3">
												<Form.Label
													htmlFor="Text5"
													className="font-weight-bold text-muted text-uppercase"
												>
													Phone
												</Form.Label>
												<Form.Control
													type="text"
													id="Text5"
													placeholder="Enter Phone"
													value={phone}
													onChange={(e) => setPhone(e.target.value)}
												/>
											</div>
											{clientType === "company" ? (
												<div className="col-md-6 mb-3">
													<Form.Label
														htmlFor="Text3"
														className="font-weight-bold text-muted text-uppercase"
													>
														Company Name
													</Form.Label>
													<Form.Control
														type="text"
														id="Text3"
														placeholder="Enter Company Name"
														value={companyName}
														onChange={(e) => setCompanyName(e.target.value)}
													/>
												</div>
											) : null}
											<div className="col-md-6 mb-3">
												<Form.Label
													htmlFor="inputcountry"
													className="font-weight-bold text-muted text-uppercase"
												>
													Country
												</Form.Label>
												<select
													defaultValue=""
													id="inputcountry"
													className="form-select form-control choicesjs"
													value={country}
													onChange={(e) => setCountry(e.target.value)}
												>
													<option value="" hidden disabled>
														Select Country
													</option>
													<option value="Afganistan">Afghanistan</option>
													<option value="Albania">Albania</option>
													<option value="Algeria">Algeria</option>
													<option value="American Samoa">American Samoa</option>
													<option value="Andorra">Andorra</option>
													<option value="Angola">Angola</option>
													<option value="Anguilla">Anguilla</option>
													<option value="Antigua &amp; Barbuda">
														Antigua &amp; Barbuda
													</option>
													<option value="Argentina">Argentina</option>
													<option value="Armenia">Armenia</option>
													<option value="Aruba">Aruba</option>
													<option value="Australia">Australia</option>
													<option value="Austria">Austria</option>
													<option value="Azerbaijan">Azerbaijan</option>
													<option value="Bahamas">Bahamas</option>
													<option value="Bahrain">Bahrain</option>
													<option value="Bangladesh">Bangladesh</option>
													<option value="Barbados">Barbados</option>
													<option value="Belarus">Belarus</option>
													<option value="Belgium">Belgium</option>
													<option value="Belize">Belize</option>
													<option value="Benin">Benin</option>
													<option value="Bermuda">Bermuda</option>
													<option value="Bhutan">Bhutan</option>
													<option value="Bolivia">Bolivia</option>
													<option value="Bonaire">Bonaire</option>
													<option value="Bosnia &amp; Herzegovina">
														Bosnia &amp; Herzegovina
													</option>
													<option value="Botswana">Botswana</option>
													<option value="Brazil">Brazil</option>
													<option value="British Indian Ocean Ter">
														British Indian Ocean Ter
													</option>
													<option value="Brunei">Brunei</option>
													<option value="Bulgaria">Bulgaria</option>
													<option value="Burkina Faso">Burkina Faso</option>
													<option value="Burundi">Burundi</option>
													<option value="Cambodia">Cambodia</option>
													<option value="Cameroon">Cameroon</option>
													<option value="Canada">Canada</option>
													<option value="Canary Islands">Canary Islands</option>
													<option value="Cape Verde">Cape Verde</option>
													<option value="Cayman Islands">Cayman Islands</option>
													<option value="Central African Republic">
														Central African Republic
													</option>
													<option value="Chad">Chad</option>
													<option value="Channel Islands">
														Channel Islands
													</option>
													<option value="Chile">Chile</option>
													<option value="China">China</option>
													<option value="Christmas Island">
														Christmas Island
													</option>
													<option value="Cocos Island">Cocos Island</option>
													<option value="Colombia">Colombia</option>
													<option value="Comoros">Comoros</option>
													<option value="Congo">Congo</option>
													<option value="Cook Islands">Cook Islands</option>
													<option value="Costa Rica">Costa Rica</option>
													<option value="Cote DIvoire">Cote DIvoire</option>
													<option value="Croatia">Croatia</option>
													<option value="Cuba">Cuba</option>
													<option value="Curaco">Curacao</option>
													<option value="Cyprus">Cyprus</option>
													<option value="Czech Republic">Czech Republic</option>
													<option value="Denmark">Denmark</option>
													<option value="Djibouti">Djibouti</option>
													<option value="Dominica">Dominica</option>
													<option value="Dominican Republic">
														Dominican Republic
													</option>
													<option value="East Timor">East Timor</option>
													<option value="Ecuador">Ecuador</option>
													<option value="Egypt">Egypt</option>
													<option value="El Salvador">El Salvador</option>
													<option value="Equatorial Guinea">
														Equatorial Guinea
													</option>
													<option value="Eritrea">Eritrea</option>
													<option value="Estonia">Estonia</option>
													<option value="Ethiopia">Ethiopia</option>
													<option value="Falkland Islands">
														Falkland Islands
													</option>
													<option value="Faroe Islands">Faroe Islands</option>
													<option value="Fiji">Fiji</option>
													<option value="Finland">Finland</option>
													<option value="France">France</option>
													<option value="French Guiana">French Guiana</option>
													<option value="French Polynesia">
														French Polynesia
													</option>
													<option value="French Southern Ter">
														French Southern Ter
													</option>
													<option value="Gabon">Gabon</option>
													<option value="Gambia">Gambia</option>
													<option value="Georgia">Georgia</option>
													<option value="Germany">Germany</option>
													<option value="Ghana">Ghana</option>
													<option value="Gibraltar">Gibraltar</option>
													<option value="Great Britain">Great Britain</option>
													<option value="Greece">Greece</option>
													<option value="Greenland">Greenland</option>
													<option value="Grenada">Grenada</option>
													<option value="Guadeloupe">Guadeloupe</option>
													<option value="Guam">Guam</option>
													<option value="Guatemala">Guatemala</option>
													<option value="Guinea">Guinea</option>
													<option value="Guyana">Guyana</option>
													<option value="Haiti">Haiti</option>
													<option value="Hawaii">Hawaii</option>
													<option value="Honduras">Honduras</option>
													<option value="Hong Kong">Hong Kong</option>
													<option value="Hungary">Hungary</option>
													<option value="Iceland">Iceland</option>
													<option value="Indonesia">Indonesia</option>
													<option value="India">India</option>
													<option value="Iran">Iran</option>
													<option value="Iraq">Iraq</option>
													<option value="Ireland">Ireland</option>
													<option value="Isle of Man">Isle of Man</option>
													<option value="Israel">Israel</option>
													<option value="Italy">Italy</option>
													<option value="Jamaica">Jamaica</option>
													<option value="Japan">Japan</option>
													<option value="Jordan">Jordan</option>
													<option value="Kazakhstan">Kazakhstan</option>
													<option value="Kenya">Kenya</option>
													<option value="Kiribati">Kiribati</option>
													<option value="Korea North">Korea North</option>
													<option value="Korea Sout">Korea South</option>
													<option value="Kuwait">Kuwait</option>
													<option value="Kyrgyzstan">Kyrgyzstan</option>
													<option value="Laos">Laos</option>
													<option value="Latvia">Latvia</option>
													<option value="Lebanon">Lebanon</option>
													<option value="Lesotho">Lesotho</option>
													<option value="Liberia">Liberia</option>
													<option value="Libya">Libya</option>
													<option value="Liechtenstein">Liechtenstein</option>
													<option value="Lithuania">Lithuania</option>
													<option value="Luxembourg">Luxembourg</option>
													<option value="Macau">Macau</option>
													<option value="Macedonia">Macedonia</option>
													<option value="Madagascar">Madagascar</option>
													<option value="Malaysia">Malaysia</option>
													<option value="Malawi">Malawi</option>
													<option value="Maldives">Maldives</option>
													<option value="Mali">Mali</option>
													<option value="Malta">Malta</option>
													<option value="Marshall Islands">
														Marshall Islands
													</option>
													<option value="Martinique">Martinique</option>
													<option value="Mauritania">Mauritania</option>
													<option value="Mauritius">Mauritius</option>
													<option value="Mayotte">Mayotte</option>
													<option value="Mexico">Mexico</option>
													<option value="Midway Islands">Midway Islands</option>
													<option value="Moldova">Moldova</option>
													<option value="Monaco">Monaco</option>
													<option value="Mongolia">Mongolia</option>
													<option value="Montserrat">Montserrat</option>
													<option value="Morocco">Morocco</option>
													<option value="Mozambique">Mozambique</option>
													<option value="Myanmar">Myanmar</option>
													<option value="Nambia">Nambia</option>
													<option value="Nauru">Nauru</option>
													<option value="Nepal">Nepal</option>
													<option value="Netherland Antilles">
														Netherland Antilles
													</option>
													<option value="Netherlands">
														Netherlands (Holland, Europe)
													</option>
													<option value="Nevis">Nevis</option>
													<option value="New Caledonia">New Caledonia</option>
													<option value="New Zealand">New Zealand</option>
													<option value="Nicaragua">Nicaragua</option>
													<option value="Niger">Niger</option>
													<option value="Nigeria">Nigeria</option>
													<option value="Niue">Niue</option>
													<option value="Norfolk Island">Norfolk Island</option>
													<option value="Norway">Norway</option>
													<option value="Oman">Oman</option>
													<option value="Pakistan">Pakistan</option>
													<option value="Palau Island">Palau Island</option>
													<option value="Palestine">Palestine</option>
													<option value="Panama">Panama</option>
													<option value="Papua New Guinea">
														Papua New Guinea
													</option>
													<option value="Paraguay">Paraguay</option>
													<option value="Peru">Peru</option>
													<option value="Phillipines">Philippines</option>
													<option value="Pitcairn Island">
														Pitcairn Island
													</option>
													<option value="Poland">Poland</option>
													<option value="Portugal">Portugal</option>
													<option value="Puerto Rico">Puerto Rico</option>
													<option value="Qatar">Qatar</option>
													<option value="Republic of Montenegro">
														Republic of Montenegro
													</option>
													<option value="Republic of Serbia">
														Republic of Serbia
													</option>
													<option value="Reunion">Reunion</option>
													<option value="Romania">Romania</option>
													<option value="Russia">Russia</option>
													<option value="Rwanda">Rwanda</option>
													<option value="St Barthelemy">St Barthelemy</option>
													<option value="St Eustatius">St Eustatius</option>
													<option value="St Helena">St Helena</option>
													<option value="St Kitts-Nevis">St Kitts-Nevis</option>
													<option value="St Lucia">St Lucia</option>
													<option value="St Maarten">St Maarten</option>
													<option value="St Pierre &amp; Miquelon">
														St Pierre &amp; Miquelon
													</option>
													<option value="St Vincent &amp; Grenadines">
														St Vincent &amp; Grenadines
													</option>
													<option value="Saipan">Saipan</option>
													<option value="Samoa">Samoa</option>
													<option value="Samoa American">Samoa American</option>
													<option value="San Marino">San Marino</option>
													<option value="Sao Tome &amp; Principe">
														Sao Tome &amp; Principe
													</option>
													<option value="Saudi Arabia">Saudi Arabia</option>
													<option value="Senegal">Senegal</option>
													<option value="Seychelles">Seychelles</option>
													<option value="Sierra Leone">Sierra Leone</option>
													<option value="Singapore">Singapore</option>
													<option value="Slovakia">Slovakia</option>
													<option value="Slovenia">Slovenia</option>
													<option value="Solomon Islands">
														Solomon Islands
													</option>
													<option value="Somalia">Somalia</option>
													<option value="South Africa">South Africa</option>
													<option value="Spain">Spain</option>
													<option value="Sri Lanka">Sri Lanka</option>
													<option value="Sudan">Sudan</option>
													<option value="Suriname">Suriname</option>
													<option value="Swaziland">Swaziland</option>
													<option value="Sweden">Sweden</option>
													<option value="Switzerland">Switzerland</option>
													<option value="Syria">Syria</option>
													<option value="Tahiti">Tahiti</option>
													<option value="Taiwan">Taiwan</option>
													<option value="Tajikistan">Tajikistan</option>
													<option value="Tanzania">Tanzania</option>
													<option value="Thailand">Thailand</option>
													<option value="Togo">Togo</option>
													<option value="Tokelau">Tokelau</option>
													<option value="Tonga">Tonga</option>
													<option value="Trinidad &amp; Tobago">
														Trinidad &amp; Tobago
													</option>
													<option value="Tunisia">Tunisia</option>
													<option value="Turkey">Turkey</option>
													<option value="Turkmenistan">Turkmenistan</option>
													<option value="Turks &amp; Caicos Is">
														Turks &amp; Caicos Is
													</option>
													<option value="Tuvalu">Tuvalu</option>
													<option value="Uganda">Uganda</option>
													<option value="United Kingdom">United Kingdom</option>
													<option value="Ukraine">Ukraine</option>
													<option value="United Arab Erimates">
														United Arab Emirates
													</option>
													<option value="United States of America">
														United States of America
													</option>
													<option value="Uraguay">Uruguay</option>
													<option value="Uzbekistan">Uzbekistan</option>
													<option value="Vanuatu">Vanuatu</option>
													<option value="Vatican City State">
														Vatican City State
													</option>
													<option value="Venezuela">Venezuela</option>
													<option value="Vietnam">Vietnam</option>
													<option value="Virgin Islands (Brit)">
														Virgin Islands (Brit)
													</option>
													<option value="Virgin Islands (USA)">
														Virgin Islands (USA)
													</option>
													<option value="Wake Island">Wake Island</option>
													<option value="Wallis &amp; Futana Is">
														Wallis &amp; Futana Is
													</option>
													<option value="Yemen">Yemen</option>
													<option value="Zaire">Zaire</option>
													<option value="Zambia">Zambia</option>
													<option value="Zimbabwe">Zimbabwe</option>
												</select>
											</div>
											{clientType === "company" ? (
												<div className="col-md-6 mb-3">
													<Form.Label
														htmlFor="Text3"
														className="font-weight-bold text-muted text-uppercase"
													>
														Company Registration Code
													</Form.Label>
													<Form.Control
														type="text"
														id="Text3"
														placeholder="Enter Company Name"
														value={companyRegCode}
														onChange={(e) => setCompanyRegCode(e.target.value)}
													/>
												</div>
											) : null}
											{/* <div className="col-md-6 mb-3">
												<Form.Label
													htmlFor="inputState"
													className="font-weight-bold text-muted text-uppercase"
												>
													State/Region
												</Form.Label>
												<select
													defaultValue=""
													id="inputState"
													className="form-select form-control choicesjs"
													onChange={(e) => setState(e.target.value)}
												>
													<option value="" hidden disabled>
														Select State/Region
													</option>
													<option value="Ohio">Ohio</option>
													<option value="Briston">Briston</option>
													<option value="Nevada">Nevada</option>
													<option value="Georgia">Georgia</option>
													<option value="Texas">Texas</option>
													<option value="California">California</option>
												</select> 
											</div> */}
											{clientType === "company" ? (
												<div className="col-md-6 mb-3">
													<Form.Label
														htmlFor="Text3"
														className="font-weight-bold text-muted text-uppercase"
													>
														Company Vat Number
													</Form.Label>
													<Form.Control
														type="text"
														id="Text3"
														placeholder="Enter Company Name"
														value={companyVatNumber}
														onChange={(e) =>
															setCompanyVatNumber(e.target.value)
														}
													/>
												</div>
											) : null}
											<div className="col-md-6 mb-3">
												<Form.Label
													htmlFor="Text7"
													className="font-weight-bold text-muted text-uppercase"
												>
													Zipcode
												</Form.Label>
												<Form.Control
													type="text"
													id="Text7"
													placeholder="Enter Zipcode"
													value={zipcode}
													onChange={(e) => setZipcode(e.target.value)}
												/>
											</div>
											<div className="col-md-6 mb-3">
												<Form.Label
													htmlFor="Text6"
													className="font-weight-bold text-muted text-uppercase"
												>
													{clientType === "company" ? "Company" : null} Address
												</Form.Label>
												<Form.Control
													type="text"
													id="Text6"
													placeholder="Enter Address"
													value={address}
													onChange={(e) => setAddress(e.target.value)}
												/>
											</div>
											<div className="col-md-12 mb-3">
												<Form.Label
													htmlFor="Text9"
													className="font-weight-bold text-muted text-uppercase"
												>
													Bio
												</Form.Label>
												<Form.Control
													as="textarea"
													id="Text9"
													rows="2"
													placeholder="Enter Bio"
													value={bio}
													onChange={(e) => setBio(e.target.value)}
												/>
											</div>
											<div className="col-md-12 mb-3">
												<Form.Label className="form-label font-weight-bold text-muted text-uppercase mb-3">
													Notification Type
												</Form.Label>
												<br />
												<div className="form-check form-check-inline">
													<div className="custom-control custom-checkbox custom-control-inline">
														<Form.Control
															type="checkbox"
															className="custom-control-input m-0"
															id="inlineCheckbox1"
															value="Email"
															checked={notifications.includes("Email")}
															onChange={(e) =>
																handleCheckboxChange(e.target.value)
															}
														/>
														<Form.Label
															className="custom-control-label"
															htmlFor="inlineCheckbox1"
														>
															Email
														</Form.Label>
													</div>
												</div>
												<div className="form-check form-check-inline">
													<div className="custom-control custom-checkbox custom-control-inline">
														<Form.Control
															type="checkbox"
															className="custom-control-input m-0"
															id="inlineCheckbox3"
															value="Phone"
															checked={notifications.includes("Phone")}
															onChange={(e) =>
																handleCheckboxChange(e.target.value)
															}
														/>
														<Form.Label
															className="custom-control-label"
															htmlFor="inlineCheckbox3"
														>
															Phone
														</Form.Label>
													</div>
												</div>
												<div className="form-check form-check-inline">
													<div className="custom-control custom-checkbox custom-control-inline">
														<Form.Control
															type="checkbox"
															className="custom-control-input m-0"
															id="inlineCheckbox4"
															value="Social Media"
															onChange={(e) =>
																handleCheckboxChange(e.target.value)
															}
															checked={notifications.includes("Social Media")}
														/>
														<Form.Label
															className="custom-control-label"
															htmlFor="inlineCheckbox4"
														>
															Social Media
														</Form.Label>
													</div>
												</div>
											</div>
										</Form>
										<div className="d-flex flex-wrap justify-content-between mt-3">
											<Button variant="btn btn-secondary font-weight-bold btn-sm">
												<div className="d-flex justify-content-between align-items-center">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														className="mr-2"
														width="20"
														fill="none"
														viewBox="0 0 24 24"
														stroke="currentColor"
													>
														<path
															strokeLinecap="round"
															strokeLinejoin="round"
															strokeWidth="2"
															d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
														/>
													</svg>
													Delete Customer
												</div>
											</Button>
											<Button
												variant="btn btn-primary font-weight-bold btn-sm"
												onClick={(e) => handleUpdateCustomer(e)}
											>
												Update Customer
											</Button>
										</div>
									</Col>
								</Row>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	)
}

export default CustomerEdit
