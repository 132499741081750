import React, { useContext, useEffect, useState } from "react"

import { ArrowUpShort, ArrowDownShort } from "react-bootstrap-icons"

import {
  Breadcrumb,
  Container,
  Row,
  Col,
  ButtonGroup,
  Button,
} from "react-bootstrap"

import Card from "../../../components/Card"

import { hiveapi } from "../../../config"
import LeftRightArrows from "./Utils/LeftRightArrows"

import { RenderPropSticky } from "react-sticky-el"

import { useSelector } from "react-redux"

const TIME_DAY = 24 * 60 * 60 * 1000
const TIME_MONTH = 30 * TIME_DAY

const online = (worker) => worker?.data?.stats?.online === true
const stats_time = (worker) =>
  worker?.data?.stats?.stats_time
    ? worker.data.stats.stats_time * 1000
    : undefined
const ip_remote = (worker) => worker?.data.remote_address?.ip || undefined
const ip_local = (worker) =>
  (worker?.data?.ip_addresses !== undefined &&
    worker?.data?.ip_addresses[0] !== undefined &&
    worker?.data?.ip_addresses[0]) ||
  undefined

const IP = {
  Paldiski: {
    Hangar1: {
      remote: "213.168.14.143",
    },
  },
  Viimsi: {
    Hangar1: {
      remote: "80.235.74.40",
    },
    Hangar2: {
      remote: "88.196.244.70",
    },
  },
  Riga: {
    Hangar1: {
      local: "10.",
    },
  },
}

const variables = {
  colors: {
    red: "#ff504c",
    green: "#84bf40",
    muted: "#6c757d",
  },
  border: {
    width: "2px",
    radius: "3px",
  },
}

const styles = {
  card: {
    online: {
      borderTop: `solid ${variables.border.width} ${variables.colors.green}`,
      borderRadius: `0 0 ${variables.border.radius} ${variables.border.radius}`,
    },
    offline: {
      borderTop: `solid ${variables.border.width} ${variables.colors.red}`,
      borderRadius: `0 0 ${variables.border.radius} ${variables.border.radius}`,
    },
  },
}

const MonitorContext = React.createContext()

const Worker = ({ worker }) => {
  const label =
    online(worker) === true ? "Online" : `Offline: ${worker.downtime}`
  const card =
    online(worker) === true ? styles.card.online : styles.card.offline
  const footnote = {
    color:
      online(worker) === true ? variables.colors.muted : variables.colors.red,
  }

  return (
    <div className="card" style={card}>
      <Card.Header>
        <Card.Header.Title>{worker.name}</Card.Header.Title>
      </Card.Header>
      <Card.Body>
        <p>Some stats here</p>
      </Card.Body>
      <Card.Footer>
        <small style={footnote}>{label}</small>
      </Card.Footer>
    </div>
  )
}

const workersFilterByStatus = (workers, context) =>
  workers.filter(context.view.statuses[context.view.status.get])

const workersFilterByLocation = (workers, context) => {
  const [locations] = context.locations

  // Retrun as-is if 'All' is enabled
  if (locations["All"].enabled === true) return workers

  return workers.filter((worker) =>
    Object.keys(locations)
      .map((name) => {
        const location = locations[name]

        if (location.enabled === false) return false

        return location.filter(worker)
      })
      .includes(true)
  )
}

const WorkersList = ({
  farm,
  previous = undefined,
  next = undefined,
  id = "",
}) => {
  const context = useContext(MonitorContext)
  const { refreshTrigger } = context
  const [workers, setWorkers] = useState([])
  const [filtered, setFiltered] = useState([])
  const [refreshing, setRefreshing] = useState(false)

  const [loading, setLoading] = useState(true)

  const darkMode = useSelector((state) => state.darkMode.darkMode)
  const [locations, setLocations] = context.locations

  const up = () =>
    previous !== undefined && previous === "#"
      ? window.scrollTo({ top: 0, behavior: "smooth" })
      : document.querySelector(previous) !== null &&
        document.querySelector(previous).scrollIntoView({ behavior: "smooth" })

  const down = () =>
    next !== undefined &&
    document.querySelector(next) !== null &&
    document.querySelector(next).scrollIntoView({ behavior: "smooth" })

  const refresh = async () => {
    setRefreshing(true)

    await farm.workers.all().then((result) => setWorkers(result))
  }

  useEffect(() => {
    refresh().then(() => setLoading(false))
  }, [refreshTrigger])

  useEffect(() => {
    setFiltered(
      workersFilterByLocation(workersFilterByStatus(workers, context), context)
    )
    setRefreshing(false)
  }, [workers, context.view.status.get, locations])

  const action_buttons = (
    <ButtonGroup>
      <Button
        size="sm"
        disabled={refreshing}
        variant={refreshing ? "outline-secondary" : "outline-primary"}
        onClick={refresh}
      >
        {refreshing ? "In progress" : "Refresh"}
      </Button>
      <Button size="sm" variant={"outline-primary" + " " + next} onClick={up}>
        <ArrowUpShort />
      </Button>
      <Button
        size="sm"
        variant={"outline-primary" + " " + previous}
        onClick={down}
      >
        <ArrowDownShort />
      </Button>
    </ButtonGroup>
  )

  if (workers.length === 0) return <></>
  if (filtered.length === 0) return <></>

  const common = {
    paddingLeft: "28px",
    height: "75px",
  }

  const normal = {
    position: "absolute",
    zIndex: 9000,
  }

  const sticky = {
    position: "fixed",
    top: "17px",
    zIndex: 8000,
  }

  return (
    <div id={id} className="sticky-parent">
      <RenderPropSticky boundaryElement=".sticky-parent" topOffset={-37.5}>
        {({ isFixed, holderRef, wrapperRef }) => (
          <div ref={holderRef} style={{ height: "37.5px" }}>
            <div
              ref={wrapperRef}
              style={
                isFixed ? { ...common, ...sticky } : { ...common, ...normal }
              }
            >
              <h3 style={{ backgroundColor: darkMode ? "#181818" : "white" }}>
                {action_buttons}
                &nbsp; &nbsp;
                {farm.name}
              </h3>
            </div>
          </div>
        )}
      </RenderPropSticky>
      <br />
      <Row className={`row-cols-1 row-cols-md-${context.view.columns.get} g-4`}>
        {loading === true ? (
          <p>Loading workers...</p>
        ) : refreshing === true ? (
          <p>Refreshing...</p>
        ) : (
          filtered.map((worker) => (
            <Col key={worker.id}>
              <Worker worker={worker} />
            </Col>
          ))
        )}
      </Row>
    </div>
  )
}

const FarmsList = () => {
  const { refreshTrigger } = useContext(MonitorContext)
  const [farms, setFarms] = useState([])

  useEffect(() => {
    hiveapi.farms.all().then((result) => setFarms(result))
  }, [refreshTrigger])

  if (farms.length === 0)
    return (
      <>
        <p>Loading farms...</p>
      </>
    )

  let previous = "#"
  let next = undefined

  return (
    <>
      {farms.map((farm, index) => {
        previous = index === 0 ? "#" : "#farm-" + farms[index - 1].id
        next =
          index === farms.length - 1
            ? undefined
            : "#farm-" + farms[index + 1].id

        return (
          <Row key={farm.id}>
            <Col>
              <WorkersList
                id={"farm-" + farm.id}
                farm={farm}
                previous={previous}
                next={next}
              />
            </Col>
          </Row>
        )
      })}
    </>
  )
}

const FilterControls = () => {
  return (
    <>
      <StatusControls />
      &nbsp;&nbsp;
      <LocationControls />
    </>
  )
}

const StatusControls = () => {
  const context = useContext(MonitorContext)
  const { status, statuses } = context.view

  return (
    <ButtonGroup>
      {Object.keys(statuses).map((name) => (
        <Button
          key={name}
          value={name}
          variant={status.get === name ? "primary" : "outline-secondary"}
          onClick={() => status.set(name)}
        >
          {name}
        </Button>
      ))}
    </ButtonGroup>
  )
}

const LocationControls = () => {
  const context = useContext(MonitorContext)
  const [locations, setLocations] = context.locations

  const toggle = (name) => {
    const temp = Object.assign({}, locations)

    // Disabled toggling if 'All' is enabled
    // Exception: 'All' itself
    if (locations["All"].enabled !== true || name === "All")
      temp[name].enabled = !temp[name].enabled
    else return

    setLocations(temp)
  }

  return (
    <>
      <ButtonGroup>
        {Object.keys(locations).map((name) => (
          <Button
            key={name}
            type="checkbox"
            variant={
              locations["All"].enabled === true && name !== "All"
                ? "secondary"
                : locations[name].enabled
                ? "primary"
                : "outline-secondary"
            }
            onClick={() => toggle(name)}
            disabled={locations["All"].enabled === true && name !== "All"}
          >
            {name}
          </Button>
        ))}
      </ButtonGroup>
    </>
  )
}

const ViewControls = () => {
  const context = useContext(MonitorContext)

  return (
    <>
      <LeftRightArrows
        value={context.view.columns.get}
        setValue={(value) => context.view.columns.set(value)}
      >
        {context.view.columns.get}
      </LeftRightArrows>
    </>
  )
}

const Monitor = () => {
  const [refreshTrigger, setRefreshTrigger] = useState(Date.now())

  const refreshAll = () => setRefreshTrigger(Date.now())

  const [viewColumns, setViewColumns] = useState(3)
  const [locations, setLocations] = useState({
    All: {
      enabled: true,
      filter: (worker) => false,
    },
    Viimsi: {
      enabled: false,
      filter: (worker) =>
        [IP.Viimsi.Hangar1.remote, IP.Viimsi.Hangar2.remote].includes(
          ip_remote(worker)
        ),
    },
    Paldiski: {
      enabled: false,
      filter: (worker) =>
        [IP.Paldiski.Hangar1.remote].includes(ip_remote(worker)),
    },
    Riga: {
      enabled: false,
      filter: (worker) =>
        ip_local(worker) !== undefined &&
        ip_local(worker).startsWith(IP.Riga.Hangar1.local),
    },
    Other: {
      enabled: false,
      filter: (worker) =>
        [IP.Viimsi.Hangar1.remote, IP.Viimsi.Hangar2.remote].includes(
          ip_remote(worker)
        ) == false &&
        [IP.Paldiski.Hangar1.remote].includes(ip_remote(worker)) === false &&
        ip_local(worker) !== undefined &&
        ip_local(worker).startsWith(IP.Riga.Hangar1.local) === false,
    },
  })

  const [status, setStatus] = useState("All")
  const statuses = {
    Online: (worker) => online(worker) === true,
    All: (worker) => true,
    Offline: (worker) => online(worker) !== true,
    Day: (worker) =>
      online(worker) !== true &&
      (Date.now() - stats_time(worker) < TIME_DAY ||
        stats_time(worker) === undefined),
    Month: (worker) =>
      online(worker) !== true &&
      (Date.now() - stats_time(worker) < TIME_MONTH ||
        stats_time(worker) === undefined),
  }

  const context = {
    view: {
      columns: {
        get: viewColumns,
        set: (value) => setViewColumns(Math.min(8, Math.max(1, value))),
      },
      statuses,
      status: {
        get: status,
        set: setStatus,
      },
    },
    locations: [locations, setLocations],
    refreshTrigger,
  }

  return (
    <MonitorContext.Provider value={context}>
      <Container fluid>
        <Card>
          <Card.Header>
            <Breadcrumb>
              <Breadcrumb.Item href="/">
                <i className="ri-home-4-line pt-1 mr-2 float-left"></i>
                Cryptotech
              </Breadcrumb.Item>
              <Breadcrumb.Item>Commander</Breadcrumb.Item>
              <Breadcrumb.Item active>Monitor</Breadcrumb.Item>
            </Breadcrumb>
            <Card.Header.Action>
              <Button variant="outline-primary" onClick={refreshAll}>
                Refresh all
              </Button>
              &nbsp;&nbsp;
              <ViewControls />
            </Card.Header.Action>
          </Card.Header>
          <Card.Body>
            <FilterControls />
            <br />
            <br />
            <FarmsList />
          </Card.Body>
        </Card>
      </Container>
    </MonitorContext.Provider>
  )
}

export default Monitor
