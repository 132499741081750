import React, { useState, useEffect, useRef } from "react"
import classnames from "classnames"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import Card from "../../../components/Card"
import { Link, useHistory } from "react-router-dom"
//datepicker
import Datepickers from "../../../components/Datepicker"
import { db } from "../../../config"
import styles from "./MinerAdd.css"

const MinerAdd = () => {
	const [minerName, setMinerName] = useState("")
	const [minerBio, setMinerBio] = useState("")
	const [minersCount, setMinersCount] = useState("")
	const [fields, setFields] = useState([{}])
	const history = useHistory()

	const handleAddMiner = async (e) => {
		e.preventDefault()
		const filterFields = []
		fields.forEach((element) =>
			element.value !== undefined && element.value !== ""
				? filterFields.push(element)
				: null,
		)
		await db
			.collection("miners")
			.add({
				minerName: minerName,
				minerBio: minerBio,
				gpuSerials: filterFields,
				created: Date.now(),
			})
			.then(function (docRef) {
				history.push(`/miner-view/${docRef.id}`)
			})
	}

	const addGpuField = (value, index) => {
		if (value !== "" && fields.length - 1 === index) {
			setFields([...fields, { value: "" }])
		}
	}

	const fetchMinerAmount = async () => {
		const minersAmount = db.collection("miners")
		await minersAmount.get().then((snapshot) => {
			// setMinersCount()
			setMinerName(snapshot.size ? `Rig #${snapshot.size + 1}` : "")
		})
	}

	// useEffect(() => {
	// 	// fields.focus()
	// 	fields[fields.length].focus()
	// }, [fields])
	const inputRef = useRef(null)
	useEffect(() => {
		fetchMinerAmount()
	}, [])

	useEffect(() => inputRef.current && inputRef.current.focus(), [fields])

	return (
		<>
			<Container fluid>
				<Row>
					<Col lg="12">
						<div className="d-flex flex-wrap align-items-center justify-content-between">
							<div className="d-flex align-items-center justify-content-between">
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb p-0 mb-0">
										<li className="breadcrumb-item">
											<Link to="/miner">Miners</Link>
										</li>
										<li className="breadcrumb-item active" aria-current="page">
											Add Miner
										</li>
									</ol>
								</nav>
							</div>
							<Link
								to="/miner"
								className="btn btn-primary btn-sm d-flex align-items-center justify-content-between"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="20"
									viewBox="0 0 20 20"
									fill="currentColor"
								>
									<path
										fillRule="evenodd"
										d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
										clipRule="evenodd"
									/>
								</svg>
								<span className="ml-2">Back</span>
							</Link>
						</div>
					</Col>
					<Col lg="12" className="mb-3 d-flex justify-content-between">
						<h4 className="font-weight-bold0 d-flex align-items-center">
							Add New Miner
						</h4>
					</Col>
					<Col lg="12">
						<Card>
							<Card.Body>
								<Row>
									<Col md="12">
										<Form className="row g-3 date-icon-set-modal">
											<div className="col-md-6 mb-3">
												<Form.Label
													htmlFor="Text1"
													className="font-weight-bold text-muted text-uppercase"
												>
													Miner Name
												</Form.Label>
												<Form.Control
													type="text"
													id="Text1"
													placeholder="Enter Full Name"
													value={minerName}
													onChange={(e) => setMinerName(e.target.value)}
													disabled={minerName}
												/>
											</div>
											<div className="col-md-12 mb-3">
												<Form.Label
													htmlFor="Text9"
													className="font-weight-bold text-muted text-uppercase"
												>
													Bio
												</Form.Label>
												<Form.Control
													as="textarea"
													id="Text9"
													rows="2"
													placeholder="Enter Bio"
													onChange={(e) => setMinerBio(e.target.value)}
												/>
											</div>
											<div className="col-md-6 mb-3">
												<Form.Label
													htmlFor="Text1"
													className="font-weight-bold text-muted text-uppercase"
												>
													GPU Serial
												</Form.Label>
												<table className="w-100">
													<tbody>
														{fields &&
															fields.map((field, index) => (
																<tr>
																	<td>
																		<div className="mx-2">#{index + 1}</div>
																	</td>
																	<td className="w-100">
																		<Form.Control
																			type="text"
																			id="Text1"
																			placeholder="Enter Full Name"
																			className={classnames(
																				"mb-1",
																				fields.map((mapField, mapIndex) =>
																					mapField.value === field.value &&
																					mapIndex !== index
																						? "error"
																						: null,
																				),
																			)}
																			// onChange={(e) => addGpuField(e.target.value)}
																			onChange={(e) =>
																				(fields[index].value = e.target.value)
																			}
																			onKeyUp={(e) =>
																				e.key === "Enter"
																					? addGpuField(e.target.value, index)
																					: null
																			}
																			tabIndex={index}
																			ref={inputRef}
																		/>
																	</td>
																</tr>
															))}
													</tbody>
												</table>
											</div>
										</Form>
										<div className="d-flex justify-content-end mt-3">
											<Button
												variant="btn btn-primary"
												onClick={(e) => {
													handleAddMiner(e)
												}}
											>
												Add Miner
											</Button>
										</div>
									</Col>
								</Row>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	)
}

export default MinerAdd
