import { useEffect, useState } from "react"
import date from "date-and-time"
import useStateRealtimeReference from "../Hooks/useStateRealtimeReference"
import { DropdownSelector } from "../Components"
import { database, SmartAccountsAPI } from "../../../../config"
import { ButtonGroup, Button, Table, Modal, Dropdown } from "react-bootstrap"
import { Clients, Client, Articles, Article } from "./Components/"

const currencies = ["EUR", "USD", "RUB"]

const defaults = {
	client: {
		id: "b23b37aa-1cd3-48fc-aeaa-98ddd32fa427",
		name: "TEST CLIENT 0xFF",
	},
	vatpc: {
		vatPc: "20",
		pc: 20,
		descriptionEt: "20%",
		descriptionEn: "20%",
		descriptionRu: "20%",
		activePurchase: true,
		activeSales: true,
		order: 3,
		custom: false,
	},
	currency: "EUR",
	article: {
		code: "Elekter 8",
		description: "Elektri kuulud Hotelis (0.1359+ 0,0478 võrguteenus KW/h)",
		type: "PRODUCT",
		vatPc: "20",
		activeSales: true,
		activePurchase: true,
		priceSales: 0.1877,
		pricePurchase: 0,
		accountSales: "30121",
		accountPurchase: "40511",
		dateCreated: "10.05.2022 15:04:53",
		dateUpdated: "09.06.2022 00:11:20",
		createdBy: "info@cryptotech.ee",
		updatedBy: "info@cryptotech.ee",
	},
}

let cache_clients = undefined
let cache_vatpc = undefined

const update = () => {
	database
		.ref("/cache/sa/clients")
		.on("value", (snapshot) => (cache_clients = snapshot.val() || {}))

	database
		.ref("/cache/sa/vatpc")
		.on("value", (snapshot) => (cache_vatpc = snapshot.val() || {}))
}

update()

const InvoiceSender = ({ show, setShow, items, farm }) => {
	const root = `/settings/farm/${farm}`

	const [client, setClient] = useStateRealtimeReference(
		`${root}/smart_accounts_client_id`,
		defaults.client,
	)
	const [currency, setCurrency] = useStateRealtimeReference(
		`${root}/currency`,
		defaults.currency,
	)
	//   const [vatpc, setVatpc] = useStateRealtimeReference(
	//     `${root}/vatpc`,
	//     defaults.vatpc
	//   )

	const [article, setArticle] = useStateRealtimeReference(
		`${root}/article`,
		defaults.article,
	)

	const [rows, setRows] = useState([])

	useEffect(() => {
		setRows(
			items.map((item, index) => {
				if (index === 0) {
					return {
						code: "Rent",
						description: "Rent - Riiuli rent hotelis",
						price: 25,
						quantity: item.quantity,
						discount: item.discount,
						vatPc: "20",
						unit: "rack(s)",
					}
				} else {
					return {
						code: article.code,
						description: article.description + " - " + item.description,
						price: article.priceSales,
						quantity: item.quantity,
						vatPc: article.vatPc,
						unit: "kW/h",
					}
				}
			}),
		)
	}, [items, article])

	const pt = date.format(new Date(), "DD.MM.YYYY")

	const invoice = {
		clientId: client.id,
		currency,
		rows,
		date: pt,
	}

	// Update each row's vatpc if vatpc changes
	//   useEffect(() => {
	//     invoice.rows = invoice.rows.map((i) => {
	//       i.vatPc = vatpc.vatPc

	//       return i
	//     })
	//   }, [vatpc, invoice])

	const sendInvoice = () =>
		SmartAccountsAPI.invoices
			.create(invoice)
			.then((res) => {
				alert("Sent!")
				console.log(JSON.stringify(res))
			})
			.catch((e) => alert("Error: " + e.message))

	let total = 0

	return (
		<Modal size="lg" onHide={() => setShow(false)} show={show}>
			<Modal.Header>
				<Modal.Title>Invoice for: Electricity consumption</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>
					Invoice date: <b>{pt}</b>
				</p>
				<Table hover>
					<thead>
						<tr>
							<th scope="col">Position</th>
							<th scope="col">Description</th>
							<th scope="col">Quantity</th>
							<th scope="col">Price</th>
							<th scope="col">Discount</th>
							<th scope="col">Total</th>
						</tr>
					</thead>
					<tbody style={{ fontFamily: "sans-serif", fontSize: "12px" }}>
						{rows.map((row, index) => {
							const price =
								(Math.floor(row.quantity * row.price * 100) / 100) *
								(row.discount ? 1 - row.discount / 100 : 1)

							total += price

							return (
								<tr key={index}>
									<th scope="row">#{index + 1}</th>
									<td>{row.description}</td>
									<td>
										{row.quantity} {row.unit}
									</td>
									<td>
										{row.price}&nbsp;{currency}
									</td>
									<td>{row.discount && row.discount + " %"}</td>
									<td>
										{price.toFixed(2)}&nbsp;{currency}
									</td>
								</tr>
							)
						})}
						<tr key="total">
							<th scope="row"></th>
							<td></td>
							<td></td>
							<td></td>
							<td>
								<b>Total</b>
							</td>
							<td>
								{total.toFixed(2)}&nbsp;{currency}
							</td>
						</tr>
						{/* <tr key="vat">
              <th scope="row"></th>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <b>VAT</b>
              </td>
              <td>
                {((total * vatpc.pc) / 100).toFixed(2)}&nbsp;{currency}
              </td>
            </tr>
            <tr key="combined">
              <th></th>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <b>Combined</b>
              </td>
              <td>
                {total.toFixed(2)}&nbsp;{currency}
              </td>
            </tr> */}
					</tbody>
				</Table>
			</Modal.Body>
			<Modal.Footer>
				<Dropdown>
					<Dropdown.Toggle variant="outline-primary">
						{client.name}
					</Dropdown.Toggle>

					<Dropdown.Menu
						style={{
							overflowY: "scroll",
							maxHeight: "300px",
						}}
					>
						<Clients>
							<Client>
								{(c) => (
									<Dropdown.Item onClick={() => setClient(c)}>
										{c.name}
									</Dropdown.Item>
								)}
							</Client>
						</Clients>
					</Dropdown.Menu>
				</Dropdown>
				<Dropdown>
					<Dropdown.Toggle variant="outline-primary">
						{article.description}
					</Dropdown.Toggle>

					<Dropdown.Menu
						style={{
							overflowY: "scroll",
							maxHeight: "300px",
						}}
					>
						<Articles>
							<Article>
								{(a) => (
									<Dropdown.Item onClick={() => setArticle(a)}>
										{a.description}
									</Dropdown.Item>
								)}
							</Article>
						</Articles>
					</Dropdown.Menu>
				</Dropdown>
				{/* <DropdownSelector
          options={cache_vatpc}
          initial={vatpc}
          onSelect={setVatpc}
          toId={(o) => o.vatPc}
          toLabel={(o) => o.descriptionEn}
          variant="outline-primary"
        /> */}
				<DropdownSelector
					options={currencies}
					initial={currency}
					onSelect={setCurrency}
					variant="outline-primary"
				/>
				{/* <DropdownSelector
          options={cache_clients}
          initial={client.name}
          onSelect={setClient}
          variant="outline-primary"
        /> */}
				<ButtonGroup className="btn-group-toggle">
					<Button
						href="#"
						variant="outline-primary button button-icon"
						onClick={sendInvoice}
					>
						Send
					</Button>
				</ButtonGroup>
				<ButtonGroup className="btn-group-toggle">
					<Button
						href="#"
						variant="outline-primary button button-icon"
						onClick={() => setShow(false)}
					>
						Close
					</Button>
				</ButtonGroup>
			</Modal.Footer>
		</Modal>
	)
}

export default InvoiceSender
