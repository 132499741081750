import React, { useEffect, useRef, useState } from "react"
import classnames from "classnames"
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	ListGroup,
	OverlayTrigger,
	Tooltip,
} from "react-bootstrap"
import { Typeahead } from "react-bootstrap-typeahead"
import Datepickers from "../../../components/Datepicker"

import Card from "../../../components/Card"
import { Link, useHistory } from "react-router-dom"
import { db, storage } from "../../../config"

// img
import product1 from "../../../assets/images/products/1.jpg"
import product12 from "../../../assets/images/products/12.jpg"
import Product from "./Product"

const OrderAdd = () => {
	const [customer, setCustomer] = useState("")
	const [name, setName] = useState("")
	const [company, setCompany] = useState("")
	const [products, setProducts] = useState([])
	const [selectedProducts, setSelectedProducts] = useState([])
	const [customProducts, setCustomProducts] = useState([])
	const [phone, setPhone] = useState("")
	const [email, setEmail] = useState("")
	const [orderPrice, setOrderPrice] = useState(0)
	const [address, setAddress] = useState("")
	const [comment, setComment] = useState("")
	const [paymentMethod, setPaymentMethod] = useState("")
	const [delivery, setDelivery] = useState("")
	const [customerSearchResult, setCustomerSearchResult] = useState([])
	const [productSearchResult, setProductsSearchResult] = useState([])

	const history = useHistory()

	const searchClient = async (value) => {
		if (value.length > 2) {
			const response = db.collection("customers")
			const data = await response.get()
			const customersArray = []
			const searchQuery = value.toLowerCase()
			data.docs.forEach((item) => {
				const data = item.data()
				const customerName = data.fullName.toLowerCase()
				if (
					customerName.slice(0, searchQuery.length).indexOf(searchQuery) !== -1
				) {
					data.id = item.id
					data.label = data.fullName
					customersArray.push(data)
				}
			})
			setCustomerSearchResult(customersArray)
		}
	}

	const handleSetCustomerData = (data) => {
		if (data && data.length !== 0) {
			setCustomer(data)
			setName(data.fullName)
			setCompany(data?.companyName)
			setPhone(data.phone)
			setEmail(data.email)
			setAddress(data.address)
		}
	}

	const searchProduct = async (value) => {
		if (value.length > 2) {
			const response = db.collection("products")
			const data = await response.get()
			const productsArray = []
			const searchQuery = value.toLowerCase()
			data.docs.forEach((item) => {
				const data = item.data()
				const customerName = data.name.toLowerCase()
				if (
					customerName.slice(0, searchQuery.length).indexOf(searchQuery) !== -1
				) {
					data.id = item.id
					data.label = data.name
					productsArray.push(data)
				}
			})
			setProductsSearchResult(productsArray)
		}
	}

	const handleDeleteProduct = (e, value) => {
		e.preventDefault()
		let newArr = [...products]
		// console.log(value, index, products)
		// newArr = newArr.filter(
		// 	(product, productIndex) => product.id !== value && productIndex !== index,
		// )
		var index = newArr.findIndex(function (o) {
			return o.id === value
		})
		if (index !== -1) newArr.splice(index, 1)
		setProducts(newArr)
	}

	const handleChangePrice = (value, id) => {
		let changePrice = [...products]
		let changeSelectedPrice = [...selectedProducts]
		changePrice.forEach((product) =>
			product.id === id ? (product.price = value) : null,
		)
		changeSelectedPrice.forEach((product) =>
			product.id === id ? (product.price = value) : null,
		)
		setProducts(changePrice)
		setSelectedProducts(changeSelectedPrice)
	}

	const handleSetProducts = (value) => {
		if (value && value.length !== 0) {
			const newArr = [...products]
			if (products.length === 0) {
				setProducts([value])
				setSelectedProducts([
					{
						id: value.id,
						quantity: 1,
						maxQuantity: value.quantity,
						price: value.price,
					},
				])
			}
			if (newArr.some((product) => product.id !== value.id)) {
				setProducts([...products, value])
				setSelectedProducts([
					...selectedProducts,
					{
						id: value.id,
						quantity: 1,
						maxQuantity: value.quantity,
						price: value.price,
					},
				])
			}
		}
	}

	const handleAddCustomProduct = (value) => {
		setCustomProducts([...customProducts, {}])
	}

	const handleChangeProductQuantity = (action, id) => {
		let newSelectedProducts = [...selectedProducts]
		let newCustomProducts = [...customProducts]
		newSelectedProducts.forEach((product) =>
			action === "increase"
				? product.id === id && product.quantity < product.maxQuantity
					? (product.quantity = product.quantity + 1)
					: null
				: product.id === id
				? (product.quantity =
						product.quantity > 1 ? product.quantity - 1 : product.quantity)
				: null,
		)
		newCustomProducts.forEach((product) =>
			action === "increase"
				? product.id === id && product.quantity < product.maxQuantity
					? (product.quantity = product.quantity + 1)
					: null
				: product.id === id
				? (product.quantity =
						product.quantity > 1 ? product.quantity - 1 : product.quantity)
				: null,
		)
		setSelectedProducts(newSelectedProducts)
		setCustomProducts(newCustomProducts)
		console.log(newCustomProducts)
	}

	const handleAddOrder = async (e) => {
		e.preventDefault()
		let filteredProducts = [...selectedProducts]
		filteredProducts.forEach((product) => delete product.maxQuantity)
		let todoArray = {}
		let newOrder = ""
		await db
			.collection("orders")
			.add({
				name: name,
				products: filteredProducts,
				phone: phone,
				email: email,
				address: address,
				price: orderPrice,
				comment: comment,
				paymentMethod: paymentMethod,
				delivery: delivery,
				created: Date.now(),
			})
			.then(async (docRef) => {
				newOrder = docRef.id
				const response = db.collection("todo").doc("zCi0bawHZmlwPkUY8s8W")
				await response.get().then((item) => (todoArray = item.data()))
				todoArray.lanes[0].cards.push({
					description: comment,
					id: newOrder,
					label: `${new Date().getDate()}.${
						new Date().getMonth() + 1
					}.${new Date().getFullYear()}`,
					laneId: "MinerCreate",
					metadata: {
						completedAt: new Date(),
						shortCode: "ga2",
					},
					title: `Miner for ${name}`,
				})
			})
		await db
			.collection("todo")
			.doc("zCi0bawHZmlwPkUY8s8W")
			.set(todoArray)
			.then(history.push("/todo"))
	}

	useEffect(() => {
		let price = 0
		selectedProducts.forEach(
			(product) => (price = price + product.price * product.quantity),
		)
		setOrderPrice(price)
	}, [selectedProducts])

	return (
		<>
			<Container fluid>
				<Row>
					<Col lg="12">
						<div className="d-flex flex-wrap align-items-center justify-content-between">
							<div className="d-flex align-items-center justify-content-between">
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb p-0 mb-0">
										<li className="breadcrumb-item">
											<Link to="/order">Orders</Link>
										</li>
										<li className="breadcrumb-item active" aria-current="page">
											Add Order
										</li>
									</ol>
								</nav>
							</div>
							<Link
								to="/order"
								className="btn btn-primary btn-sm d-flex align-items-center justify-content-between"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="20"
									viewBox="0 0 20 20"
									fill="currentColor"
								>
									<path
										fillRule="evenodd"
										d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
										clipRule="evenodd"
									/>
								</svg>
								<span className="ml-2">Back</span>
							</Link>
						</div>
					</Col>
					<Col lg="12" className="mb-3 d-flex justify-content-between">
						<h4 className="font-weight-bold d-flex align-items-center">
							New Order
						</h4>
					</Col>
				</Row>
				<Card>
					<Card.Body>
						<Row>
							<Col lg="6">
								<h5 className="font-weight-bold pb-3">Customer Details</h5>
								<Form className="row g-3">
									<div className="col-md-12 mb-3">
										<Form.Label
											htmlFor="Text1"
											className="form-label font-weight-bold text-muted text-uppercase"
										>
											Customer
										</Form.Label>
										<Form.Group>
											<Typeahead
												placeholder="Enter Customer"
												onInputChange={(e) => {
													searchClient(e)
												}}
												onChange={(value) => handleSetCustomerData(value[0])}
												options={customerSearchResult}
											/>
										</Form.Group>
									</div>
									{company ? (
										<div className="col-md-12 mb-3">
											<Form.Label
												htmlFor="Text2"
												className="form-label font-weight-bold text-muted text-uppercase"
											>
												Company
											</Form.Label>
											<Form.Control
												type="text"
												className="form-control"
												id="Text2"
												placeholder="Enter Company"
												value={company}
												onChange={(e) => setCompany(e.target.value)}
											/>
										</div>
									) : null}
									<div className="col-md-12 mb-3">
										<Form.Label
											htmlFor="Text2"
											className="form-label font-weight-bold text-muted text-uppercase"
										>
											Phone
										</Form.Label>
										<Form.Control
											type="text"
											className="form-control"
											id="Text2"
											placeholder="Enter Phone"
											value={phone}
											onChange={(e) => setPhone(e.target.value)}
										/>
									</div>
									<div className="col-md-12 mb-3">
										<Form.Label
											htmlFor="Text3"
											className="form-label font-weight-bold text-muted text-uppercase"
										>
											Email
										</Form.Label>
										<Form.Control
											type="text"
											className="form-control"
											id="Text3"
											placeholder="Enter Email"
											value={email}
											onChange={(e) => setEmail(e.target.value)}
										/>
									</div>
									<div className="col-md-12 mb-3">
										<Form.Label
											htmlFor="Text4"
											className="form-label font-weight-bold text-muted text-uppercase"
										>
											Address
										</Form.Label>
										<Form.Control
											type="text"
											className="form-control"
											id="Text4"
											placeholder="Enter Address"
											value={address}
											onChange={(e) => setAddress(e.target.value)}
										/>
									</div>
								</Form>
							</Col>
							<Col lg="6">
								<h5 className="font-weight-bold pb-3">Order Details</h5>
								<Form className="row g-3 date-icon-set-modal">
									{/* <div className="col-md-12 mb-3 position-relative">
										<Form.Label
											htmlFor="Text2"
											className="font-weight-bold text-muted text-uppercase"
										>
											Birth Day
										</Form.Label>
										<Form.Group>
											<Datepickers
												type="text"
												className="vanila-datepicker"
												id="Text2"
												name="event_date"
												placeholder="Enter Birth Day"
												autoComplete="off"
												onChanges={(e) => console.log(e.target.value)}
											/>
											<div className="search-link">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													className=""
													width="20"
													fill="none"
													viewBox="0 0 24 24"
													stroke="currentColor"
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth="2"
														d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
													/>
												</svg>
											</div>
										</Form.Group>
									</div> */}
									<div className="col-md-12 mb-3">
										<Form.Label className="form-label font-weight-bold text-muted text-uppercase">
											Payment Method
										</Form.Label>
										<br />
										<div
											className="btn-group"
											role="group"
											aria-label="Basic outlined example"
										>
											<Button
												id="cash"
												type="button"
												variant={classnames(
													"btn",
													paymentMethod === "cash"
														? "btn-primary"
														: "btn-outline-primary",
												)}
												onClick={(e) => setPaymentMethod(e.target.id)}
											>
												Cash
											</Button>
											<Button
												id="bank-transfer"
												type="button"
												variant={classnames(
													"btn",
													paymentMethod === "bank-transfer"
														? "btn-primary"
														: "btn-outline-primary",
												)}
												onClick={(e) => setPaymentMethod(e.target.id)}
											>
												Bank Transfer
											</Button>
											<Button
												id="credit-card"
												type="button"
												variant={classnames(
													"btn",
													paymentMethod === "credit-card"
														? "btn-primary"
														: "btn-outline-primary",
												)}
												onClick={(e) => setPaymentMethod(e.target.id)}
											>
												Credit Card
											</Button>
										</div>
									</div>
									<div className="col-md-12 mb-3">
										<Form.Label className="form-label font-weight-bold text-muted text-uppercase">
											Destination
										</Form.Label>
										<br />
										<div
											className="btn-group"
											role="group"
											aria-label="Basic outlined example"
										>
											<Button
												id="delivery"
												type="button"
												variant={classnames(
													"btn",
													delivery === "delivery"
														? "btn-primary"
														: "btn-outline-primary",
												)}
												onClick={(e) => setDelivery(e.target.id)}
											>
												Delivery
											</Button>
											<Button
												id="hotel"
												type="button"
												variant={classnames(
													"btn",
													delivery === "hotel"
														? "btn-primary"
														: "btn-outline-primary",
												)}
												onClick={(e) => setDelivery(e.target.id)}
											>
												Hotel
											</Button>
											<Button
												id="take-away"
												type="button"
												variant={classnames(
													"btn",
													delivery === "take-away"
														? "btn-primary"
														: "btn-outline-primary",
												)}
												onClick={(e) => setDelivery(e.target.id)}
											>
												Take Away
											</Button>
										</div>
									</div>
									<div className="col-md-12 mb-3">
										<Form.Label
											htmlFor="Text8"
											className="form-label font-weight-bold text-muted text-uppercase"
										>
											Comment
										</Form.Label>
										<Form.Control
											as="textarea"
											id="Text8"
											rows="3"
											placeholder="Enter your comment"
											onChange={(e) => setComment(e.target.value)}
										/>
									</div>
								</Form>
							</Col>
						</Row>
					</Card.Body>
				</Card>
				<Card>
					<Card.Body className="p-0">
						<ListGroup as="ul" className="list-group-flush">
							<ListGroup.Item as="li" className="p-3">
								<h5 className="font-weight-bold mb-3">Order Items</h5>
								<div className="modal-product-search d-flex">
									<Form className="mr-3 position-relative">
										<Form.Group className="mb-0">
											<Typeahead
												onInputChange={(e) => {
													searchProduct(e)
												}}
												onChange={(value) => handleSetProducts(value[0])}
												options={productSearchResult}
												// selected={product}
												placeholder="Enter Product"
											/>

											<div className="search-link">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													className=""
													width="20"
													fill="none"
													viewBox="0 0 24 24"
													stroke="currentColor"
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth="2"
														d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
													/>
												</svg>
											</div>
										</Form.Group>
									</Form>
								</div>
							</ListGroup.Item>
							<ListGroup.Item as="li" className="p-0">
								<div className="table-responsive">
									<table className="table mb-0">
										<thead>
											<tr className="text-muted">
												<th scope="col">Product</th>
												<th scope="col" className="text-center">
													Quantity
												</th>
												<th scope="col" className="text-center">
													Price
												</th>
												<th scope="col" className="text-center">
													Tax
												</th>
												<th scope="col" className="text-center">
													Total
												</th>
												<th scope="col" className="text-center"></th>
											</tr>
										</thead>
										<tbody>
											{products &&
												products.map((product, index) => (
													<tr>
														<td>
															<div>
																<span className="font-weight-bold">
																	{product.name}
																</span>
															</div>
															<p className="m-0 mt-1">{product.description}</p>
														</td>
														<td className="text-center">
															<div className="d-flex align-items-center justify-content-center">
																<button
																	className="btn mr-2 p-0 bg-transparent border-0"
																	onClick={() =>
																		handleChangeProductQuantity(
																			"decrease",
																			product.id,
																		)
																	}
																>
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		width="20"
																		fill="none"
																		viewBox="0 0 24 24"
																		stroke="currentColor"
																	>
																		<path
																			strokeLinecap="round"
																			strokeLinejoin="round"
																			strokeWidth="2"
																			d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
																		/>
																	</svg>
																</button>
																{selectedProducts.map((selectedProduct) =>
																	selectedProduct.id === product.id
																		? selectedProduct.quantity
																		: null,
																)}
																<button
																	className="btn ml-2 p-0 bg-transparent border-0"
																	onClick={() =>
																		handleChangeProductQuantity(
																			"increase",
																			product.id,
																		)
																	}
																>
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		width="20"
																		fill="none"
																		viewBox="0 0 24 24"
																		stroke="currentColor"
																	>
																		<path
																			strokeLinecap="round"
																			strokeLinejoin="round"
																			strokeWidth="2"
																			d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
																		/>
																	</svg>
																</button>
															</div>
														</td>
														<td className="text-center">
															{(
																(product.price / (product.tax / 100 + 1)) *
																selectedProducts.map((selectedProduct) =>
																	selectedProduct.id === product.id
																		? selectedProduct.quantity
																		: null,
																)
															).toFixed(2)}
															$
														</td>
														<td className="text-center">{product.tax}%</td>
														<td className="text-center">
															<div className="col-md-12">
																<Form.Control
																	type="text"
																	className="form-control"
																	id="Text4"
																	placeholder="Enter Price"
																	value={product.price}
																	onChange={(e) =>
																		handleChangePrice(
																			e.target.value,
																			product.id,
																		)
																	}
																/>
															</div>
														</td>
														<td className="text-center">
															<OverlayTrigger
																placement="top"
																overlay={<Tooltip>Delete</Tooltip>}
															>
																<Link
																	className="badge bg-danger"
																	onClick={(e) =>
																		handleDeleteProduct(e, product.id, index)
																	}
																>
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		width="20"
																		fill="none"
																		viewBox="0 0 24 24"
																		stroke="currentColor"
																	>
																		<path
																			strokeLinecap="round"
																			strokeLinejoin="round"
																			strokeWidth="2"
																			d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
																		/>
																	</svg>
																</Link>
															</OverlayTrigger>
														</td>
													</tr>
												))}
										</tbody>
										{/* <tbody>
											{customProducts &&
												customProducts.map((product, index) => (
													<tr>
														<td>
															<div className="active-project-1 d-flex align-items-center mt-0 ">
																<div className="data-content">
																	<div>
																		<span className="font-weight-bold">
																			<Form.Control
																				type="text"
																				className="form-control"
																				id="Text4"
																				placeholder="Enter Price"
																				value={product.name}
																				// onChange={() => setCustomProductName()}
																			/>
																		</span>
																	</div>
																</div>
															</div>
														</td>
														<td className="text-center">
															<div className="d-flex align-items-center justify-content-center">
																<button
																	className="btn mr-2 p-0 bg-transparent border-0"
																	onClick={() =>
																		handleChangeProductQuantity(
																			"decrease",
																			product.id,
																		)
																	}
																>
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		width="20"
																		fill="none"
																		viewBox="0 0 24 24"
																		stroke="currentColor"
																	>
																		<path
																			strokeLinecap="round"
																			strokeLinejoin="round"
																			strokeWidth="2"
																			d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
																		/>
																	</svg>
																</button>
																{selectedProducts.map((selectedProduct) =>
																	selectedProduct.id === product.id
																		? selectedProduct.quantity
																		: null,
																)}
																1
																<button
																	className="btn ml-2 p-0 bg-transparent border-0"
																	onClick={() =>
																		handleChangeProductQuantity(
																			"increase",
																			product.id,
																		)
																	}
																>
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		width="20"
																		fill="none"
																		viewBox="0 0 24 24"
																		stroke="currentColor"
																	>
																		<path
																			strokeLinecap="round"
																			strokeLinejoin="round"
																			strokeWidth="2"
																			d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
																		/>
																	</svg>
																</button>
															</div>
														</td>
														<td className="text-center">
															{(
																(product.price / (product.tax / 100 + 1)) *
																selectedProducts.map((selectedProduct) =>
																	selectedProduct.id === product.id
																		? selectedProduct.quantity
																		: null,
																)
															).toFixed(2)}
															$
														</td>
														<td className="text-center">20%</td>
														<td className="text-center">
															<div className="col-md-12">
																<Form.Control
																	type="text"
																	className="form-control"
																	id="Text4"
																	placeholder="Enter Price"
																	value={product.price}
																	onChange={(e) =>
																		handleChangePrice(
																			e.target.value,
																			product.id,
																		)
																	}
																/>
															</div>
														</td>
														<td className="text-center">
															<OverlayTrigger
																placement="top"
																overlay={<Tooltip>Delete</Tooltip>}
															>
																<Link
																	className="badge bg-danger"
																	// onClick={(e) =>
																	// 	handleDeleteProduct(e, product.id, index)
																	// }
																>
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		width="20"
																		fill="none"
																		viewBox="0 0 24 24"
																		stroke="currentColor"
																	>
																		<path
																			strokeLinecap="round"
																			strokeLinejoin="round"
																			strokeWidth="2"
																			d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
																		/>
																	</svg>
																</Link>
															</OverlayTrigger>
														</td>
													</tr>
												))}
										</tbody> */}
									</table>
								</div>
								{/* <div className="col-md-12 mx-1">
									<Button
										variant="btn btn-secondary btn-sm"
										onClick={() => handleAddCustomProduct()}
									>
										+ Add Custom Product
									</Button>
								</div> */}
							</ListGroup.Item>
							<ListGroup.Item as="li" className="p-3">
								<div className="d-flex justify-content-end align-items-center">
									Total:
									<p className="ml-2 mb-0 font-weight-bold">{orderPrice}$</p>
									<Button
										variant="btn btn-primary ml-5 btn-sm"
										onClick={(e) => handleAddOrder(e)}
									>
										Create Order
									</Button>
								</div>
							</ListGroup.Item>
						</ListGroup>
					</Card.Body>
				</Card>
			</Container>
		</>
	)
}

export default OrderAdd
