/**
 * Compunes an total value for all inputs of a matrix
 *
 * @param {number[]|number[][]|number[][][]} inputs
 * @param {((value: number) => number)|undefined} [modifier=undefined]
 *
 * @returns {number} `number` total sum
 */
export default function sum_up_entries(inputs, modifier = undefined) {
  let sum = 0

  const values = Object.values(inputs)

  for (const value of values) {
    if (value === null || value === undefined) continue
    else if (typeof value === "number")
      sum += modifier !== undefined ? modifier(value) : value
    else if (typeof value === "object") sum += sum_up_entries(value, modifier)
    else
      throw new Error(
        `Invalid input value, expected number or object, but got: ${typeof value}`
      )
  }

  return sum
}
