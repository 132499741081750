import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import firebase from "firebase"
import { googleProvider } from "../../config"
import { db } from "../../config"

const initialState = {
	displayName: undefined,
	email: undefined,
	authenticated: undefined,
	error: undefined,
	role: undefined,
}

export const login = createAsyncThunk("login", async (req, thunkAPI) => {
	try {
		if (req.displayName === null) {
			const response = await firebase.auth().signInWithPopup(googleProvider)
			const user = response?.user
			const queryRes = await db
				.collection("users")
				.where("uid", "==", user.uid)
				.get()

			if (queryRes.docs.length !== 0) {
				let userData = null
				queryRes.forEach((doc) => {
					userData = doc.data()
				})
				if (userData.approved === true) {
					const role = userData.role
					const displayName = user.displayName
					const email = user.email
					return { displayName, email, role }
				} else {
					return thunkAPI.rejectWithValue({ error: "User not approved" })
				}
			} else {
				return thunkAPI.rejectWithValue({ error: "User not exist" })
			}
		} else {
			const displayName = req.displayName
			const email = req.email
			return { displayName, email }
		}
	} catch (error) {
		return thunkAPI.rejectWithValue({ error: error.message })
	}
})

export const register = createAsyncThunk("register", async (req, thunkAPI) => {
	try {
		if (req.displayName === null) {
			const response = await firebase.auth().signInWithPopup(googleProvider)
			const user = response?.user
			const query = await db
				.collection("users")
				.where("uid", "==", user.uid)
				.get()
			if (query.docs.length === 0) {
				await db.collection("users").add({
					uid: user.uid,
					name: user.displayName,
					authProvider: "google",
					email: user.email,
					approved: false,
					role: "empty",
				})
			} else {
				return thunkAPI.rejectWithValue({ error: "User already exists" })
			}
		} else {
			const displayName = req.displayName
			const email = req.email
			return { displayName, email }
		}
	} catch (error) {
		return thunkAPI.rejectWithValue({ error: error.message })
	}
})

export const logout = createAsyncThunk("logout", async (_, thunkAPI) => {
	try {
		await firebase.auth().signOut()
	} catch (error) {
		return thunkAPI.rejectWithValue({ error: error.message })
	}
})

export const userAuth = createSlice({
	name: "auth",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(login.fulfilled, (state, action) => {
			state.displayName = action.payload.displayName
			state.email = action.payload.email
			state.role = action.payload.role
			state.authenticated = true
		})
		builder.addCase(login.rejected, (state, action) => {
			state.error = action.error
		})
		builder.addCase(logout.fulfilled, (state) => {
			state.authenticated = false
			state.displayName = initialState.displayName
			state.email = initialState.email
			state.role = initialState.role
		})
		builder.addCase(logout.rejected, (state, action) => {
			state.error = action.error
		})
	},
})

export default userAuth.reducer
