import React, { useState, useRef, useEffect } from "react"
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	InputGroup,
	Nav,
	Tab,
} from "react-bootstrap"
import Card from "../../../components/Card"
import { Link, useHistory } from "react-router-dom"
//datepicker
import classnames from "classnames"
import Datepickers from "../../../components/Datepicker"
import { Document, Page } from "react-pdf/dist/esm/entry.webpack"
// import {
// 	Page,
// 	Text,
// 	View,
// 	Document,
// 	Image,
// 	Link as PdfLink,
// } from "@react-pdf/renderer"

import { db, storage } from "../../../config"
import sample from "./sample.pdf"

const CustomerAdd = () => {
	const [invoice, setInvoice] = useState({ imgName: "", url: "" })
	const [file, setFile] = useState({ file: null, extension: "" })
	const [numPages, setNumPages] = useState(null)
	const [pageNumber, setPageNumber] = useState(1)

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages)
	}

	const history = useHistory()

	const handleAddInvoice = (inv) => {
		console.log(inv)
		setFile({ file: inv, extension: inv.type.split("/").pop() })
		setInvoice((invoice.imgName = inv.name))
		const ref = storage.ref(`/invoice/${inv.name}`)
		const uploadTask = ref.put(inv)
		uploadTask.on("state_changed", console.log, console.error, () => {
			ref.getDownloadURL().then((url) => {
				setInvoice({ ...invoice, url: url })
			})
		})
	}

	const handleDeleteInvoice = () => {
		const ref = storage.ref(`invoice/${invoice.imgName}`)
		ref
			.delete()
			.then(function () {
				setInvoice({ imgName: "", url: "" })
			})
			.catch(function (error) {
				console.log(error)
			})
	}

	const handleSubmitInvoice = async (e) => {
		e.preventDefault()
		await db
			.collection("invoices")
			.add({
				url: invoice.url,
				imgName: invoice.imgName,
				status: "pending",
				created: Date.now(),
			})
			.then(history.push("/invoice"))
	}

	return (
		<>
			<Container fluid>
				<Row>
					<Col lg="12">
						<div className="d-flex flex-wrap align-items-center justify-content-between">
							<div className="d-flex align-items-center justify-content-between">
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb p-0 mb-0">
										<li className="breadcrumb-item">
											<Link to="/invoices">Invoices</Link>
										</li>
										<li className="breadcrumb-item active" aria-current="page">
											Add Invoice
										</li>
									</ol>
								</nav>
							</div>
							<Link
								to="/invoices"
								className="btn btn-primary btn-sm d-flex align-items-center justify-content-between"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="20"
									viewBox="0 0 20 20"
									fill="currentColor"
								>
									<path
										fillRule="evenodd"
										d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
										clipRule="evenodd"
									/>
								</svg>
								<span className="ml-2">Back</span>
							</Link>
						</div>
					</Col>
					<Col lg="12" className="mb-3 d-flex justify-content-between">
						<h4 className="font-weight-bold0 d-flex align-items-center">
							Add New Invoice
						</h4>
					</Col>
					<Col lg="12">
						<Card>
							<Card.Body>
								<Row>
									<Col>
										<Form.Label
											htmlFor="Text1"
											className="font-weight-bold text-muted text-uppercase"
										>
											Upload Invoice
										</Form.Label>
										<InputGroup>
											<InputGroup.Prepend>
												<InputGroup.Text>Upload</InputGroup.Text>
											</InputGroup.Prepend>
											<div className="custom-file">
												<input
													type="file"
													className="custom-file-input"
													id="invoice"
													onChange={(e) => handleAddInvoice(e.target.files[0])}
												/>
												<label className="custom-file-label" htmlFor="invoice">
													Choose file
												</label>
											</div>
										</InputGroup>
									</Col>
								</Row>
								{invoice.url ? (
									<div className="d-flex justify-content-end mt-3">
										<Button
											variant="btn btn-danger mr-2"
											onClick={() => {
												handleDeleteInvoice()
											}}
										>
											Remove
										</Button>
										<Button
											variant="btn btn-primary"
											onClick={(e) => {
												handleSubmitInvoice(e)
											}}
										>
											Submit
										</Button>
									</div>
								) : null}
							</Card.Body>
						</Card>
					</Col>

					<Col lg="12">
						<Card>
							<Card.Body className="p-0">
								{invoice.url ? (
									file.extension === "pdf" ? (
										<Document
											file={file.file}
											onLoadSuccess={onDocumentLoadSuccess}
											width="100%"
										>
											{Array.from(new Array(numPages), (el, index) => (
												<Page
													key={`page_${index + 1}`}
													pageNumber={index + 1}
												/>
											))}
										</Document>
									) : (
										<img
											style={{ width: "100%", objectFit: "contain" }}
											src={invoice.url}
											alt="profileImg"
										/>
									)
								) : null}
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	)
}

export default CustomerAdd
