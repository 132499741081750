import { useContext } from "react"
import { ClientContext } from "../contexts"

const Client = ({ children: cb }) => {
  const context = useContext(ClientContext)

  return <>{cb(context)}</>
}

Client.defaultProps = {
  children: () => {},
}

export default Client
