import { createSlice } from "@reduxjs/toolkit"

export const darkModeSlice = createSlice({
	name: "darkMode",
	initialState: {
		darkMode: false,
	},
	reducers: {
		toggleSwitch: (state) => {
			state.darkMode = !state.darkMode
		},
	},
})

export const { toggleSwitch, darkModeAction } = darkModeSlice.actions

export default darkModeSlice.reducer
