import { useEffect } from "react"
import date from "date-and-time"
import useStateRealtimeReference from "../Utils/useStateRealtimeReference"
import { DropdownSelector } from "../Components"
import { database, SmartAccountsAPI } from "../../../../config"
import { ButtonGroup, Button, Table, Modal } from "react-bootstrap"

const currencies = ["EUR", "USD", "RUB"]

const defaults = {
  client: {
    id: "b23b37aa-1cd3-48fc-aeaa-98ddd32fa427",
    name: "TEST CLIENT 0xFF",
  },
  vatpc: {
    vatPc: "20",
    pc: 20,
    descriptionEt: "20%",
    descriptionEn: "20%",
    descriptionRu: "20%",
    activePurchase: true,
    activeSales: true,
    order: 3,
    custom: false,
  },
  currency: "EUR",
}

let cache_clients = undefined
let cache_vatpc = undefined

const update = () => {
  database
    .ref("/cache/sa/clients")
    .on("value", (snapshot) => (cache_clients = snapshot.val() || {}))

  database
    .ref("/cache/sa/vatpc")
    .on("value", (snapshot) => (cache_vatpc = snapshot.val() || {}))
}

update()

const InvoiceSender = ({ show, setShow, rows, farm }) => {
  const root = `/settings/farm/${farm}`

  const [client, setClient] = useStateRealtimeReference(
    `${root}/smart_accounts_client_id`,
    defaults.client
  )
  const [currency, setCurrency] = useStateRealtimeReference(
    `${root}/currency`,
    defaults.currency
  )
  const [vatpc, setVatpc] = useStateRealtimeReference(
    `${root}/vatpc`,
    defaults.vatpc
  )

  const pt = date.format(new Date(), "DD.MM.YYYY")

  const invoice = {
    clientId: client.id,
    currency,
    rows,
    date: pt,
  }

  // Update each row's vatpc if vatpc changes
  useEffect(() => {
    invoice.rows = invoice.rows.map((i) => {
      i.vatPc = vatpc.vatPc

      return i
    })
  }, [vatpc, invoice])

  const sendInvoice = () =>
    SmartAccountsAPI.invoices
      .create(invoice)
      .then((res) => {
        alert("Sent!")
        console.log(JSON.stringify(res))
      })
      .catch((e) => alert("Error: " + e.message))

  let total = 0

  return (
    <Modal size="lg" onHide={() => setShow(false)} show={show}>
      <Modal.Header>
        <Modal.Title>Invoice for: Electricity consumption</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Invoice date: <b>{pt}</b>
        </p>
        <Table hover>
          <thead>
            <tr>
              <th scope="col">Position</th>
              <th scope="col">Description</th>
              <th scope="col">Quantity</th>
              <th scope="col">Price</th>
              <th scope="col">Discount</th>
              <th scope="col">Total</th>
            </tr>
          </thead>
          <tbody style={{ fontFamily: "sans-serif", fontSize: "12px" }}>
            {rows.map((row, index) => {
              const price =
                (Math.floor(row.quantity * row.price * 100) / 100) *
                (row.discount ? 1 - row.discount / 100 : 1)

              total += price

              return (
                <tr key={index}>
                  <th scope="row">#{index + 1}</th>
                  <td>{row.description}</td>
                  <td>{row.quantity}</td>
                  <td>
                    {row.price}&nbsp;{currency}
                  </td>
                  <td>{row.discount && row.discount + " %"}</td>
                  <td>
                    {price.toFixed(2)}&nbsp;{currency}
                  </td>
                </tr>
              )
            })}
            <tr key="total">
              <th scope="row"></th>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <b>Total</b>
              </td>
              <td>
                {(total - (total * vatpc.pc) / 100).toFixed(2)}&nbsp;{currency}
              </td>
            </tr>
            <tr key="vat">
              <th scope="row"></th>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <b>VAT</b>
              </td>
              <td>
                {((total * vatpc.pc) / 100).toFixed(2)}&nbsp;{currency}
              </td>
            </tr>
            <tr key="combined">
              <th></th>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <b>Combined</b>
              </td>
              <td>
                {total.toFixed(2)}&nbsp;{currency}
              </td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <DropdownSelector
          options={cache_vatpc}
          initial={vatpc}
          onSelect={setVatpc}
          toId={(o) => o.vatPc}
          toLabel={(o) => o.descriptionEn}
          variant="outline-primary"
        />
        <DropdownSelector
          options={currencies}
          initial={currency}
          onSelect={setCurrency}
          variant="outline-primary"
        />
        <DropdownSelector
          options={cache_clients}
          initial={client.name}
          onSelect={setClient}
          variant="outline-primary"
        />
        <ButtonGroup className="btn-group-toggle">
          <Button
            variant="outline-primary button button-icon"
            onClick={sendInvoice}
          >
            Send
          </Button>
        </ButtonGroup>
        <ButtonGroup className="btn-group-toggle">
          <Button
            variant="outline-primary button button-icon"
            onClick={() => setShow(false)}
          >
            Close
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  )
}

export default InvoiceSender
