import { Fragment, useEffect, useRef, useState } from "react"
import {
	Button,
	ButtonGroup,
	Dropdown,
	OverlayTrigger,
	Tooltip,
} from "react-bootstrap"
import { database } from "../../../../config"

import DatePicker from "react-datepicker"

import "react-datepicker/dist/react-datepicker.css"

import datetime from "date-and-time"
import { generateUUIDv4, isValidUUIDv4, StringTemplate } from "./utils"
import {
	ArrowDownShort,
	ArrowUpShort,
	CodeSlash,
	Link45deg,
	PencilSquare,
	Trash,
} from "react-bootstrap-icons"

const ItemStyle = {
	padding: "7px 12px",
	border: "1px solid #e2e2e2",
	margin: "5px 0",
	borderRadius: "5px",
}

const IndexSelector = ({ type, value, setValue, disabled }) => {
	const [index, setIndex] = useState({})
	const [selectedIndexValue, setSelectedIndexValue] = useState("Please select")

	useEffect(() => {
		if (type === undefined) return

		let isMounted = true

		database
			.ref("/database/index/" + type)
			.on("value", (snapshot) => isMounted && type && setIndex(snapshot.val()))

		return () => {
			isMounted = false
		}
	}, [type])

	useEffect(() => {
		if (value === undefined) return
		if (index === null) return

		Object.keys(index).map((uuid) => {
			if (value === uuid) setSelectedIndexValue(index[uuid])
		})
	}, [index, value])

	if (index === null) return <p>No items in the index, please re-index.</p>

	return (
		<Dropdown>
			<Dropdown.Toggle
				variant="outline-dark"
				id="dropdown-basic"
				disabled={disabled}
			>
				{selectedIndexValue || "Please select"}
			</Dropdown.Toggle>

			<Dropdown.Menu>
				{Object.keys(index).map((uuid) => {
					const value = index[uuid]

					return (
						<Dropdown.Item
							onClick={() => {
								setValue(uuid)
							}}
							key={uuid}
							disabled={uuid === value}
						>
							{value}
						</Dropdown.Item>
					)
				})}
			</Dropdown.Menu>
		</Dropdown>
	)
}

IndexSelector.defaultProps = {
	disabled: false,
}

const BuilderDate = ({ value, setValue, initial, disabled }) => {
	const [date, setDate] = useState(new Date())

	useEffect(() => {
		if (value === undefined) return

		console.log("value", value)

		setDate(datetime.parse(value, "DD/MM/YYYY"))
	}, [value])

	useEffect(() => {
		if (date === undefined) return

		console.log("date", date)

		setValue(datetime.format(date, "DD/MM/YYYY"))
	}, [date])

	// does not update on item change
	return (
		<DatePicker
			dateFormat="dd/MM/yyyy"
			selected={date}
			onChange={(change) => setDate(change)}
			disabled={disabled}
		/>
	)
}

const BuilderInput = ({ name, label, type, value, setValue, editing }) => {
	const [isReference, setIsReference] = useState(false)

	useEffect(() => {
		setIsReference(isValidUUIDv4(type) === true)
	}, [type])

	return (
		<div style={ItemStyle}>
			{label || name}&nbsp;
			{isReference === true ? (
				<IndexSelector
					type={type}
					value={value}
					setValue={setValue}
					disabled={!editing}
				/>
			) : type === "datetime" ? (
				<BuilderDate value={value} setValue={setValue} disabled={!editing} />
			) : type === "string" ? (
				<input
					value={value}
					onChange={(e) => setValue(e.target.value)}
					disabled={!editing}
				/>
			) : type === "number" ? (
				<input
					value={value}
					type="number"
					onChange={(e) => setValue(e.target.value)}
					disabled={!editing}
				/>
			) : (
				<>Unkown type</>
			)}
			<br />
		</div>
	)
}

const Builder = ({ properties, data, setData, editing }) => {
	const [states, setStates] = useState({})

	useEffect(() => {
		const result = {}

		Object.keys(properties).map((key) => {
			result[key] = {
				get value() {
					return data ? data[key] : undefined
				},
				setValue(v) {
					const result = Object.assign({}, data || {})

					result[key] = v

					setData(result)
				},
			}
		})

		setStates(result)
	}, [properties])

	if (Object.keys(properties).length === 0)
		return <div style={ItemStyle}>No properties</div>

	return (
		<div style={ItemStyle}>
			{Object.keys(properties).map((key) => {
				if (states[key] === undefined) return <Fragment key={key} />

				const property = properties[key]
				const { value, setValue } = states[key]

				return (
					<BuilderInput
						key={key}
						name={key}
						value={value}
						setValue={setValue}
						editing={editing}
						{...property}
					/>
				)
			})}
		</div>
	)
}

Builder.defaultProps = {
	properties: {},
}

const Editor = ({
	uuid,
	setUuid,
	folded: foldedInitial,
	editing: editMode,
}) => {
	const [data, setData] = useState(undefined)
	const [types, setTypes] = useState({})
	const [selectedType, setSelectedType] = useState({})
	const [folded, setFolded] = useState(foldedInitial)
	const [editing, setEditing] = useState(false)
	const [raw, setRaw] = useState(false)

	useEffect(() => {
		let isMounted = true

		database
			.ref("/database/type/")
			.on("value", (snapshot) => isMounted && setTypes(snapshot.val() || {}))

		return () => {
			isMounted = false
		}
	}, [])

	useEffect(() => {
		setEditing(false)

		let isMounted = true

		database
			.ref("/database/items/" + uuid)
			.on("value", (snapshot) => isMounted && setData(snapshot.val() || {}))

		return () => {
			isMounted = false
		}
	}, [uuid])

	useEffect(() => {
		if (data === undefined) return

		database.ref("/database/items/" + uuid).set(data)

		if (data !== null && data.type !== undefined)
			setSelectedType({
				uuid: data.type,
				type: types[data.type],
			})
	}, [data])

	useEffect(() => {
		// console.log("selected type changed", selectedType)

		if (data !== undefined && data.type !== selectedType.uuid) {
			const result = Object.assign({}, data || {})
			result.type = selectedType.uuid
			setData(result)
			//   console.log("setting data to", JSON.stringify(data, null, 4))
		}
	}, [selectedType])

	useEffect(() => {
		if (selectedType.uuid === undefined) return

		Object.keys(types).map((uuid) => {
			if (selectedType.uuid === uuid)
				setSelectedType({ uuid, type: types[uuid] })
		})
	}, [types])

	const createNew = () => {
		const n = generateUUIDv4()

		setUuid(n)
		setEditing(true)
	}

	if (uuid === undefined) {
		return (
			<div style={ItemStyle}>
				<Button variant="outline-primary" onClick={createNew}>
					Create new
				</Button>
			</div>
		)
	}

	return (
		<div style={ItemStyle}>
			<h5>
				<ButtonGroup>
					<OverlayTrigger
						placement="top"
						overlay={<Tooltip>Expand view</Tooltip>}
					>
						<Button
							size="sm"
							variant="outline-secondary"
							onClick={() => setFolded(!folded)}
						>
							{folded ? <ArrowDownShort /> : <ArrowUpShort />}
						</Button>
					</OverlayTrigger>
					<OverlayTrigger
						placement="top"
						overlay={<Tooltip>Edit this item</Tooltip>}
					>
						<Button
							size="sm"
							variant={editMode || editing ? "secondary" : "outline-secondary"}
							disabled={raw}
							onClick={() => !editMode && setEditing(!editing)}
						>
							<PencilSquare />
						</Button>
					</OverlayTrigger>
					<OverlayTrigger
						placement="top"
						overlay={<Tooltip>Show raw data</Tooltip>}
					>
						<Button
							size="sm"
							variant={raw ? "secondary" : "outline-secondary"}
							disabled={editMode || editing}
							onClick={() => !editMode && !editing && setRaw(!raw)}
						>
							<CodeSlash />
						</Button>
					</OverlayTrigger>
					<OverlayTrigger
						placement="top"
						overlay={<Tooltip>Copy link to this item</Tooltip>}
					>
						<Button
							size="sm"
							variant="outline-secondary"
							onClick={() =>
								navigator.clipboard.writeText("/commander-database#" + uuid)
							}
						>
							<Link45deg />
						</Button>
					</OverlayTrigger>
					{editMode && (
						<OverlayTrigger
							placement="top"
							overlay={<Tooltip>Remove this item</Tooltip>}
						>
							<Button size="sm" variant="outline-secondary" onClick={() => {}}>
								<Trash />
							</Button>
						</OverlayTrigger>
					)}
				</ButtonGroup>
				&nbsp;
				<Dropdown>
					<Dropdown.Toggle
						variant="outline-dark"
						id="dropdown-basic"
						disabled={!editing}
					>
						{selectedType.type?.name || "Please select"}
					</Dropdown.Toggle>
					<Dropdown.Menu>
						{Object.keys(types).map((uuid) => {
							const type = types[uuid]

							return (
								<Dropdown.Item
									onClick={() => {
										setSelectedType({ uuid, type })
									}}
									key={uuid}
									disabled={selectedType.uuid === uuid}
								>
									{type.name}
								</Dropdown.Item>
							)
						})}
					</Dropdown.Menu>
				</Dropdown>
				&nbsp;{StringTemplate(selectedType.type?.label, data || {})}
			</h5>
			{!folded &&
				(raw ? (
					<div style={ItemStyle}>
						<pre>{JSON.stringify(data, null, 4)}</pre>
					</div>
				) : (
					<Builder
						properties={selectedType.type?.properties}
						data={data}
						setData={setData}
						editing={editing}
					/>
				))}
			<p style={{ color: "gray" }}>UUID: {uuid}</p>
		</div>
	)
}

Editor.defaultProps = {
	folded: true,
}

export { Editor }
