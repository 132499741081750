import React, { useState, useRef, useEffect } from "react"
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	InputGroup,
	Nav,
	Tab,
} from "react-bootstrap"
import Card from "../../../components/Card"
import { Link, useHistory, useParams } from "react-router-dom"
//datepicker
import classnames from "classnames"
import Datepickers from "../../../components/Datepicker"
import { db, storage } from "../../../config"

const CustomerAdd = () => {
	const [entities, setEntities] = useState([
		{ id: 1, flag: "Entity 1", product: "", slug: "", fields: [{ value: "" }] },
	])
	const [activeTab, setActiveTab] = useState("Entity 1")
	const [invoice, setInvoice] = useState({})
	const [stock, setStock] = useState({})
	const [settings, setSettings] = useState({})
	const { id } = useParams()

	const history = useHistory()

	const fetchInvoice = async () => {
		const response = db.collection("invoices").doc(id)
		await response.get().then((item) => setInvoice(item.data()))
	}
	const fetchSettings = async () => {
		const response = db.collection("settings").doc("HocJ7wjbaW80iJMvSzcu")
		await response.get().then((item) => setSettings(item.data()))
	}
	const fetchStock = async () => {
		const response = db.collection("stock").doc("17MWR3iYsrBYLXcff0LY")
		await response.get().then((item) => setStock(item.data()))
	}

	const addGpuField = (value, id) => {
		const newArr = [...entities]
		newArr.forEach((entity) =>
			value !== "" && entity.id === id
				? entity.fields.push({ value: "" })
				: null,
		)
		setEntities(newArr)
	}
	const inputRef = useRef(null)

	const entityAddProduct = (id, value) => {
		const newArr = [...entities]
		let gpuSlug = ""
		settings.availableGpu.forEach((product) =>
			product.name === value ? (gpuSlug = product.slug) : null,
		)

		newArr.forEach((entity) =>
			entity.id === id
				? ((entity.product = value), (entity.slug = gpuSlug))
				: null,
		)

		setEntities(newArr)
	}

	const handleAddEntity = () => {
		const entitiesAmount = entities.length
		let newArr = []
		newArr = [
			...entities,
			{
				id: entitiesAmount + 1,
				flag: `Entity ${entitiesAmount + 1}`,
				product: "",
				slug: "",
				fields: [{ value: "" }],
			},
		]
		setActiveTab(newArr[entitiesAmount].flag)
		setEntities(newArr)
	}

	const handleCompleteInvoice = async (e) => {
		e.preventDefault()
		const filterEntities = entities
		filterEntities.forEach((entity) => {
			entity.fields = entity.fields.filter(
				(element) => element.value !== undefined && element.value !== "",
			)
		})

		const stockFilter = []
		filterEntities.forEach(async (entity) => {
			stockFilter.push({
				product: entity.product,
				slug: entity.slug,
				quantity: entity.fields.length,
			})
		})
		// console.log(stockFilter)

		const updateStock = stock.stock

		// updateStock &&
		// updateStock.forEach((currentStock) => {
		// updateStock.forEach((oldStock) => console.log(oldStock))
		updateStock.forEach((currentStock) => {
			stockFilter.forEach((newStock) =>
				currentStock.slug === newStock.slug
					? (currentStock.quantity = newStock.quantity + currentStock.quantity)
					: null,
			)
			stockFilter.some((newStock) =>
				newStock.slug !== currentStock.slug ? updateStock.push(newStock) : null,
			)
		})

		console.log(updateStock)
		// stockFilter.forEach((o1) => {
		// 	// updateStock.some((o2) => o1.slug === o2.slug)
		// 	console.log(o1)
		// })

		// const publishStock = [...stockFilter, ...updateStock]

		// console.log({ stock: publishStock })
		// console.log({ stocks: updateStock })

		// console.log(publishStock)
		// const responseStock = db.collection("stock").doc("17MWR3iYsrBYLXcff0LY")
		// await responseStock.set({ stock: Object.assign(stockFilter, updateStock) })
		// console.log(result)
	}

	useEffect(() => {
		fetchInvoice()
		fetchSettings()
		fetchStock()
	}, [])
	useEffect(() => inputRef.current && inputRef.current.focus(), [entities])

	return (
		<>
			<Container fluid>
				<Row>
					<Col lg="12">
						<div className="d-flex flex-wrap align-items-center justify-content-between">
							<div className="d-flex align-items-center justify-content-between">
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb p-0 mb-0">
										<li className="breadcrumb-item">
											<Link to="/invoice">Invoices</Link>
										</li>
										<li className="breadcrumb-item active" aria-current="page">
											Complete Invoice
										</li>
									</ol>
								</nav>
							</div>
							<Link
								to="/invoice"
								className="btn btn-primary btn-sm d-flex align-items-center justify-content-between"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="20"
									viewBox="0 0 20 20"
									fill="currentColor"
								>
									<path
										fillRule="evenodd"
										d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
										clipRule="evenodd"
									/>
								</svg>
								<span className="ml-2">Back</span>
							</Link>
						</div>
					</Col>
					<Col lg="12" className="mb-3 d-flex justify-content-between">
						<h4 className="font-weight-bold0 d-flex align-items-center">
							Complete Invoice
						</h4>
					</Col>
					<Col lg="12">
						<Card>
							<Card.Body>
								<Row>
									<Col>
										<p className="text-capitalize">
											Name:{" "}
											<span className="text-lowercase">{invoice.imgName}</span>
										</p>
										{/* <p class="text-capitalize">Date: {invoice.date}</p> */}
									</Col>
								</Row>
							</Card.Body>
						</Card>
					</Col>

					<Col lg="12">
						<Card>
							<Card.Body className="p-0">
								<Tab.Container defaultActiveKey={entities[0].flag}>
									<Nav
										variant="tabs"
										activeKey={activeTab}
										onChange={(e) => console.log(e)}
									>
										{entities.map((entity, index) => (
											<Nav.Item className="p-1">
												<Nav.Link
													data-toggle="tab"
													eventKey={entity.flag}
													bsPrefix="font-weight-bold text-uppercase px-5 py-2"
													onClick={(e) => setActiveTab(e.target.id)}
													id={`Entity ${index + 1}`}
												>
													Entity {index + 1}
												</Nav.Link>
											</Nav.Item>
										))}
										<Nav.Item className="p-1">
											<Nav.Link
												data-toggle="tab"
												bsPrefix="font-weight-bold text-uppercase px-5 py-2"
												onClick={() => handleAddEntity()}
											>
												+
											</Nav.Link>
										</Nav.Item>
									</Nav>

									<Tab.Content className="p-3">
										{entities.map((entity) => (
											<Tab.Pane eventKey={entity.flag} className="fade show">
												<Row>
													<Col md="12">
														<Form
															className="row g-3 date-icon-set-modal"
															onSubmit={(e) => e.preventDefault()}
														>
															<div className="col-md-6 mb-3">
																<InputGroup className="mb-4">
																	<select
																		className="custom-select"
																		id="inputGroupSelect03"
																		onChange={(e) =>
																			entityAddProduct(
																				entity.id,
																				e.target.value,
																			)
																		}
																	>
																		<option defaultValue></option>
																		{settings.availableGpu
																			? settings.availableGpu.map((product) => (
																					<option id={product.slug}>
																						{product.name}
																					</option>
																			  ))
																			: null}
																	</select>
																</InputGroup>
																<Form.Label
																	htmlFor="Text1"
																	className="font-weight-bold text-muted text-uppercase"
																>
																	GPU Serial
																</Form.Label>
																<table className="w-100">
																	<tbody>
																		{entity.fields &&
																			entity.fields.map((field, index) => (
																				<tr>
																					<td>
																						<div className="mx-2">
																							#{index + 1}
																						</div>
																					</td>
																					<td className="w-100">
																						<Form.Control
																							type="text"
																							id="Text1"
																							placeholder="Enter Full Name"
																							className={classnames(
																								"mb-1",
																								entity.fields.map(
																									(mapField, mapIndex) =>
																										mapField.value ===
																											field.value &&
																										mapIndex !== index
																											? "error"
																											: null,
																								),
																							)}
																							onChange={(e) =>
																								(entity.fields[index].value =
																									e.target.value)
																							}
																							onKeyUp={(e) =>
																								e.key === "Enter"
																									? addGpuField(
																											e.target.value,
																											entity.id,
																									  )
																									: null
																							}
																							tabIndex={index}
																							ref={inputRef}
																						/>
																					</td>
																				</tr>
																			))}
																	</tbody>
																</table>
															</div>
														</Form>
														<div className="d-flex justify-content-end mt-3">
															<Button
																variant="btn btn-primary"
																onClick={(e) => {
																	handleCompleteInvoice(e)
																}}
															>
																Submit
															</Button>
														</div>
													</Col>
												</Row>
											</Tab.Pane>
										))}
									</Tab.Content>
								</Tab.Container>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	)
}

export default CustomerAdd
