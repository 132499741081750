import firebase from "firebase"
import HiveAPI from "cryptotech-hiveapi"

import SmartAccounts from "./views/backend/Commander/Utils/SmartAccounts"

const secrets = {
	public: process.env.REACT_APP_SMARTACCOUNTS_PUBLIC_KEY,
	private: process.env.REACT_APP_SMARTACCOUNTS_PRIVATE_KEY,
}

// const proxy = "http://localhost:4000/"
const proxy = "https://proxy.cryptotech.ee/"

const SmartAccountsAPI = new SmartAccounts(secrets, proxy)

const hiveapi = new HiveAPI(
	{
		username: process.env.REACT_APP_HIVEAPI_USERNAME,
		password: process.env.REACT_APP_HIVEAPI_PASSWORD,
		secret: process.env.REACT_APP_HIVEAPI_SECRET,
		remember: process.env.REACT_APP_HIVEAPI_REMEMBER,
	},
	proxy,
)

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

const app = firebase.initializeApp(firebaseConfig)
const auth = firebase.auth(app)
const db = firebase.firestore(app)
const database = firebase.database(app)
const storage = firebase.storage(app)
const googleProvider = new firebase.auth.GoogleAuthProvider()

export {
	firebaseConfig,
	app,
	auth,
	db,
	database,
	storage,
	googleProvider,
	hiveapi,
	SmartAccountsAPI,
}
