/**
 *
 * @param {{[worker: string]: any}} data Consumption history
 * @param {number} year Year of interest
 * @param {number} month Month of interest
 * @param {number} [max_days=30] Total days in a month, default `30`
 * @param {number} [max_hours=24] Total hours in a day, default `24`
 *
 * @returns {{[worker: number]: number}} Worker to uptime map, `0 < uptime <= 1`
 */
export default function compute_uptime(
  data,
  year,
  month,
  max_days = 30,
  max_hours = 24
) {
  const uptimes = {}
  const workers = Object.keys(data || {})

  for (const worker of workers) {
    if (
      data === undefined ||
      data[worker] === undefined ||
      data[worker][year] === undefined ||
      data[worker][year][month] === undefined
    )
      continue

    const consumption = data[worker][year][month]
    const uptime = 0

    for (let day = 0; day < max_days; day++) {
      if (consumption[day] === undefined) continue

      for (let hour = 0; hour < max_hours; hour++) {
        if (consumption[day][hour] === undefined) continue

        if (consumption[day][hour] > 0) uptime++
      }
    }

    if (uptime > 0) uptimes[worker] = uptime / max_days / max_hours
  }

  return uptimes
}
