import { Fragment, useEffect, useState } from "react"
import { Button, Nav, Tab } from "react-bootstrap"
import { Item } from "."
import { database } from "../../../../config"

import { Editor } from "./Editor"

const IndexBrowser = ({ type }) => {
  const [data, setData] = useState(undefined)

  useEffect(() => {
    let isMounted = true

    database.ref("/database/index/" + type).on("value", (snapshot) => {
      isMounted && setData(snapshot.val())
    })

    return () => {
      isMounted = false
    }
  }, [type])

  if (data === null)
    return (
      <>
        This type does not exist: <code>{JSON.stringify(type)}</code>
      </>
    )

  return (
    <>
      {data &&
        Object.keys(data).map((uuid) => (
          <Fragment key={uuid}>
            <Editor uuid={uuid} folded={true} foldable />
          </Fragment>
        ))}
    </>
  )
}

const Browser = ({}) => {
  const [types, setTypes] = useState([])

  const parse = (data) =>
    Object.keys(data || {}).map((uuid) => [uuid, data[uuid]])

  useEffect(() => {
    let isMounted = true

    database
      .ref("/database/index-names/")
      .on("value", (snapshot) => isMounted && setTypes(parse(snapshot.val())))

    return () => {
      isMounted = false
    }
  }, [])

  return (
    <Tab.Container>
      <Nav variant="tabs">
        {types.map(([type, name]) => (
          <Nav.Item key={type}>
            <Nav.Link eventKey={type}>{name}</Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
      <Tab.Content>
        {types.map(([type]) => (
          <Tab.Pane key={type} eventKey={type}>
            <IndexBrowser type={type} />
          </Tab.Pane>
        ))}
      </Tab.Content>
    </Tab.Container>
  )
}

export { Browser }
