import React, { useState, useEffect } from "react"

import {
  Breadcrumb,
  Container,
  Row,
  Col,
  ButtonGroup,
  Button,
  Table,
  Modal,
  Dropdown,
  FormControl,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap"
import Card from "../../../components/Card"

import { database } from "../../../config"

import { useSelector } from "react-redux"

const CommanderBreadcrumb = ({ children }) => (
  <Breadcrumb>
    <Breadcrumb.Item href="/">
      <i className="ri-home-4-line pt-1 mr-2 float-left"></i>Cryptotech
    </Breadcrumb.Item>
    <Breadcrumb.Item>Commander</Breadcrumb.Item>
    <Breadcrumb.Item active>{children}</Breadcrumb.Item>
  </Breadcrumb>
)

const CommanderCard = ({ children }) => (
  <Container fluid style={{ minHeight: 500 }}>
    <Row>
      <Col lg="12" sm="12">
        <Card>{children}</Card>
      </Col>
    </Row>
  </Container>
)

const ProfileRow = ({ id, data }) => {
  const darkMode = useSelector((state) => state.darkMode.darkMode)
  const [editing, setEditing] = useState(false)
  const [minerConfig, setMinerConfig] = useState(
    data?.miner?.split(" --").join("\n--")
  )

  const saveProfile = async () => {
    await database
      .ref(`/profile/${id}`)
      .set({ miner: minerConfig.split("\n--").join(" --") })

    setEditing(false)
  }

  const deleteProfile = async () => {
    await database.ref(`/profile/${id}`).remove()
  }

  return (
    <tr>
      <th scope="row">{id}</th>
      <td>
        <textarea
          style={{
            minHeight: 94,
            backgroundColor: "transparent",
            color: darkMode ? "white" : "black",
            border: "solid 1px " + (editing ? "#3378FF" : "transparent"),
            width: "100%",
            borderRadius: 8,
            padding: "7px 11px",
          }}
          disabled={!editing}
          onChange={(e) => setMinerConfig(e.target.value)}
          value={minerConfig}
        ></textarea>
      </td>
      <td>
        <ButtonGroup className="btn-group-toggle">
          <Button
            variant="outline-primary button button-icon"
            disabled={editing}
            onClick={() => setEditing(true)}
          >
            Edit
          </Button>
          <Button
            variant="outline-primary button button-icon"
            disabled={!editing}
            onClick={() => saveProfile()}
          >
            Save
          </Button>
          <Button
            variant="outline-primary button button-icon"
            onClick={() => deleteProfile()}
          >
            Delete
          </Button>
        </ButtonGroup>
      </td>
    </tr>
  )
}

const ProfilesTable = ({ profiles }) => {
  return (
    <Table>
      <thead>
        <tr>
          <th scope="col">Profile</th>
          <th scope="col">Miner script</th>
          <th scope="col">Controls</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(profiles).map((id) => (
          <ProfileRow key={id} id={id} data={profiles[id]} />
        ))}
      </tbody>
    </Table>
  )
}

const ProfileAdd = ({ show, showSet }) => {
  const [name, setName] = useState("")

  const profileAdd = () =>
    database.ref(`/profile/${name}`).set({ miner: "" }, () => {
      setName("")
      showSet(false)
    })

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>Add profile</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Enter profile name:
        <br />
        <input
          type="text"
          onChange={(e) => setName(e.target.value)}
          placeholder="name ..."
        />
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup className="btn-group-toggle">
          <Button
            variant="outline-primary button button-icon"
            onClick={() => profileAdd()}
          >
            Add
          </Button>
          <Button
            variant="outline-primary button button-icon"
            onClick={() => showSet(false)}
          >
            Cancel
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  )
}

const ProfilesCommander = () => {
  const [profiles, setProfiles] = useState({})
  const [showProfileAdd, setShowProfileAdd] = useState(false)

  useEffect(() => {
    database
      .ref("/profile")
      .on("value", (snapshot) => setProfiles(snapshot.val() || {}))
  }, [])

  return (
    <>
      <ProfileAdd show={showProfileAdd} showSet={setShowProfileAdd} />
      <CommanderCard>
        <Card.Header>
          <CommanderBreadcrumb>Profiles</CommanderBreadcrumb>
          <Card.Header.Action>
            <ButtonGroup className="btn-group-toggle">
              <Button
                variant="outline-primary button button-icon"
                onClick={() => setShowProfileAdd(true)}
              >
                Add
              </Button>
            </ButtonGroup>
          </Card.Header.Action>
        </Card.Header>
        <Card.Body>
          <ProfilesTable profiles={profiles} />
        </Card.Body>
      </CommanderCard>
    </>
  )
}

export default ProfilesCommander
