import { ButtonGroup, Button } from "react-bootstrap"

import { ArrowLeft, ArrowRight } from "react-bootstrap-icons"

export default ({ children, value, setValue, min, max, step }) => {
	step = step || 1

	const set = (v) => {
		if (min !== undefined) v = Math.max(v, min)

		if (max !== undefined) v = Math.min(v, max)

		setValue(v)
	}

	const dec = () => set(value - step)
	const inc = () => set(value + step)

	return (
		<ButtonGroup className="btn-group-toggle">
			<Button onClick={dec} variant="outline-primary button button-icon">
				<ArrowLeft />
			</Button>
			<Button variant="outline-primary button button-icon">{children}</Button>
			<Button onClick={inc} variant="outline-primary button button-icon">
				<ArrowRight />
			</Button>
		</ButtonGroup>
	)
}
