import HashratesBar from "./HashratesBar"
import PowerDraw from "./PowerDraw"
import ProfileSelector from "./ProfileSelector"
import WorkersStatusBar from "./WorkersStatusBar"

import { FarmContext, HQContext } from "../contexts"
import { useContext } from "react"
import { WorkersCount } from "."
import { Star } from "react-bootstrap-icons"

const FarmPreview = () => {
	const { farm } = useContext(FarmContext)
	const { filter } = useContext(HQContext)

	return (
		<div
			className="item"
			style={{ display: filter.filter(farm) !== true ? "none" : "block" }}
		>
			<div className="content">
				<WorkersStatusBar />
				<div
					style={{
						padding: "5px",
						height: "100%",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<div style={{ display: "flex", alignItems: "center" }}>
						<Star />
						&nbsp;
						<a
							href={`https://the.hiveos.farm/farms/${farm.id}`}
							target="_blank"
							rel="noreferrer"
							style={{ fontSize: "15px", margin: "0", padding: "0" }}
							className="btn"
						>
							{farm.name}
						</a>
					</div>
					<br />
					<div className="properties">
						<ProfileSelector />
						<PowerDraw />
						<WorkersCount />
					</div>
					<HashratesBar />
				</div>
			</div>
		</div>
	)
}

export default FarmPreview
