import {
	Button,
	ButtonGroup,
	Dropdown,
	Form,
	FormControl,
	InputGroup,
} from "react-bootstrap"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { database } from "../../../../config"
import { generateUUIDv4, isValidUUIDv4 } from "./utils"
import DatePicker from "react-datepicker"
import datetime from "date-and-time"

import "react-datepicker/dist/react-datepicker.css"
import { CodeSlash, Plus, PlusSquare, Search } from "react-bootstrap-icons"

const ItemStyle = {
	padding: "7px 12px",
	border: "1px solid #e2e2e2",
	margin: "5px 0",
	borderRadius: "5px",
	display: "block",
}

// Returns value from firebase as a state
const useFirebase = (path) => {
	// Actual state that we store
	const [data, setData] = useState(null)

	// Callback when we receive data snapshot from direbase
	const callback = (snapshot) => isMounted && setData(snapshot.val())

	// Path validation
	const isInvalidPath = (path) =>
		path === undefined ||
		path === null ||
		path === false ||
		typeof path !== "string" ||
		path.length === 0

	// Keep track of mount state to avoid updating state when unmounted
	let isMounted = false

	useEffect(() => {
		// If path is invalid, nullify data
		if (isInvalidPath(path) === true) {
			setData(null)
			return
		}

		// Update mount state
		isMounted = true

		// Create reference for ease of use
		let reference = database.ref("/database" + path)

		// Attach listener
		reference.on("value", callback)

		// Return unmounting callback
		return () => {
			// Update mount state
			isMounted = false

			// And remove listener
			reference.off("value", callback)
		}
	}, [path]) // Update everytime a path changes

	const update = (newData, onComplete = undefined) => {
		// Create reference for ease of use
		let reference = database.ref("/database" + path)
		let merge = Object.assign({}, data || {}, newData || {})

		reference.set(merge, onComplete)
	}

	// Return the state
	return [data, update]
}

const TypeSelector = ({ onSelect }) => {
	const [types] = useFirebase("/type")

	return (
		<Dropdown>
			<Dropdown.Toggle variant="outline-dark" id="dropdown-basic">
				{types === null ? <>Types are loading</> : <>Please select</>}
			</Dropdown.Toggle>
			<Dropdown.Menu>
				{types === null ? (
					<>Types are loading</>
				) : (
					Object.keys(types).map((uuid) => {
						const type = types[uuid]

						return (
							<Dropdown.Item
								onClick={() => onSelect(uuid)}
								key={uuid}
								disabled={false}
							>
								{type.name}
							</Dropdown.Item>
						)
					})
				)}
			</Dropdown.Menu>
		</Dropdown>
	)
}

const useFirebaseItem = (uuid) => useFirebase(uuid && "/items/" + uuid)
const useFirebaseType = (item) =>
	useFirebase(item?.type && "/type/" + item.type)

const IndexSelector = ({ type, value }) => {
	const [index] = useFirebase(type && "/index/" + type)

	return (
		<Dropdown>
			<Dropdown.Toggle variant="outline-primary">
				{index !== null ? index[value] : "Loading index"}
			</Dropdown.Toggle>

			<Dropdown.Menu>
				<InputGroup>
					<InputGroup.Prepend>
						<InputGroup.Text>
							<Search />
						</InputGroup.Text>
					</InputGroup.Prepend>
					<Form.Control />
				</InputGroup>
				<Dropdown.Divider />
				{index !== null ? (
					Object.keys(index).map((uuid) => (
						<Dropdown.Item
							key={uuid}
							style={{
								backgroundColor: value === uuid ? "rgba(0, 0, 0, 0.1)" : "",
							}}
							disabled={value === uuid}
						>
							{value === uuid ? <s>{index[uuid]}</s> : <>{index[uuid]}</>}
						</Dropdown.Item>
					))
				) : (
					<Dropdown.Item>Loading</Dropdown.Item>
				)}
				<Dropdown.Divider />
				<Dropdown.Item>
					<PlusSquare />
					Create new
				</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	)
}

const StringInput = ({ value, setValue }) => (
	<FormControl
		value={value || ""}
		onChange={(event) => setValue(event.target.value)}
	></FormControl>
)

const DatetimeInput = ({ value, setValue }) => (
	<DatePicker
		dateFormat="dd/MM/yyyy"
		value={value || "Please select"}
		onChange={(change) => setValue(datetime.format(change, "DD/MM/YYYY"))}
	/>
)

const Property = ({ settings, name, value, setValue }) => {
	const label = settings.label || name
	const type = settings.type

	return (
		<div style={ItemStyle}>
			<p>{label}</p>
			<>
				{isValidUUIDv4(type) === true ? (
					<IndexSelector type={type} value={value} />
				) : type === "string" ? (
					<StringInput value={value} setValue={setValue} />
				) : type === "datetime" ? (
					<DatetimeInput value={value} setValue={setValue} />
				) : (
					<>
						Something else {JSON.stringify(type)}
						<pre>{value}</pre>
					</>
				)}
			</>
		</div>
	)
}

const PrototypeProperties = ({ item, prototype, updateItem }) => {
	if (item === null || prototype === null) return <></>

	return (
		<>
			{Object.keys(prototype.properties).map((name) => {
				const settings = prototype.properties[name]
				const value = item[name]
				const setValue = (value) => updateItem({ [name]: value })

				return (
					<Property
						key={name}
						settings={settings}
						name={name}
						value={value}
						setValue={setValue}
					/>
				)
			})}
		</>
	)
}

const Prototype = ({ uuid, setUuid }) => {
	const [item, updateItem] = useFirebaseItem(uuid)
	const [type] = useFirebaseType(item)

	const [showRaw, setShowRaw] = useState(false)

	if (uuid === undefined)
		return (
			<div style={ItemStyle}>
				<Button
					onClick={() => setUuid(generateUUIDv4())}
					disabled={false}
					variant={"outline-primary"}
				>
					Create new
				</Button>
			</div>
		)

	return (
		<div style={ItemStyle}>
			<h4>
				{type !== null ? (
					type.name || "No name type"
				) : (
					<TypeSelector
						onSelect={(selected) => updateItem({ type: selected })}
					/>
				)}
				&nbsp;
				<ButtonGroup>
					<Button
						variant={showRaw ? "dark" : "outline-dark"}
						size="sm"
						onClick={() => setShowRaw(!showRaw)}
					>
						<CodeSlash />
					</Button>
				</ButtonGroup>
			</h4>
			{showRaw ? (
				<pre>{JSON.stringify(item, null, 4)}</pre>
			) : (
				<PrototypeProperties
					item={item}
					prototype={type}
					updateItem={updateItem}
				/>
			)}

			<p style={{ color: "gray" }}>
				UUID:
				<Link
					to={{
						pathname: `${process.env.REACT_APP_FIREBASE_DATABASE_URL}/database/items/${uuid}.json`,
					}}
					target="_blank"
				>
					{uuid}
				</Link>
			</p>
		</div>
	)
}

export { Prototype }
