import React, { useState, useEffect } from "react"

import {
  Breadcrumb,
  Container,
  Row,
  Col,
  ButtonGroup,
  Button,
  Table,
  Modal,
  Dropdown,
  FormControl,
} from "react-bootstrap"

import { Link } from "react-router-dom"

import Card from "../../../components/Card"

import { database, hiveapi } from "../../../config"
import { BasicTooltip, WorkersManager } from "./Components"

import ConsumptionCommander from "./Consumption"

// '/cache/farms/search'
// const FarmsSearchIndex = farmsCache.map(farm => {
// 	const { name, id } = farm
// 	const rules = []
// 	const lowered = name.toLowerCase().trim()
// 	const serialized = lowered.replace('farm', '').trim()
// 	const [local, rest] = serialized.split(' ')

// 	rules.push(lowered)
// 	rules.push(id.toString())

// 	if (serialized.startsWith('#') === true) {
// 		rules.push(rest)

// 		rules.push(local)
// 		rules.push(local.slice(1))
// 		rules.push(local.slice(2))
// 		rules.push(parseInt(local.slice(2)).toString())
// 	}

// 	return [rules.reverse(), name, id]
// })

// database.ref('/cache/farms/search').set(FarmsSearchIndex)

function FarmsFilter(farm, input) {
  const [rules, name] = farm
  input = input.toLowerCase()

  for (let rule of rules) if (rule.startsWith(input) === true) return true

  if (name.toLowerCase().includes(input) === true) return true

  return false
}

const CommanderFarmDelete = (farm) => database.ref(`/farm/${farm}`).remove()

const CommanderFarmNameByID = (query, searchIndex) => {
  const search = searchIndex.filter(([_, name, id]) => id === query)

  if (search.length === 0) return undefined

  const data = search[0]

  return data[1].replaceAll("Farm", "").trim()
}

const ProfileSelector = ({ default: byDefault, profiles, farm }) => {
  const [loading, setLoading] = useState(false)

  const onSelect = (profile) => {
    setLoading(true)

    database.ref(`/farm/${farm}/default`).set(profile)

    hiveapi.farms.get(farm).then((f) =>
      f.workers
        .all()
        .then((ws) => Promise.all(ws.map((w) => w.command("miner restart"))))
        .then(() => setLoading(false))
    )
  }

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="outline-secondary"
        id="dropdown-basic"
        disabled={loading}
      >
        {loading ? "Restarting miners" : byDefault || "Select profile"}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {Object.keys(profiles).map((name) => (
          <Dropdown.Item
            key={name}
            onClick={() => onSelect(name)}
            disabled={byDefault === name}
          >
            {name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

const FarmSelector = ({ selected, farms, onSelect, exclude, searchIndex }) => {
  const [search, setSearch] = useState("")

  farms = farms.filter(([_, name, id]) => exclude[id] === undefined)

  return (
    <Dropdown>
      <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
        {selected
          ? CommanderFarmNameByID(selected, searchIndex)
          : "Select farm"}
      </Dropdown.Toggle>

      <Dropdown.Menu style={{ overflow: "scroll", maxHeight: 460 }}>
        <FormControl
          autoFocus
          placeholder="Start typing..."
          onChange={(e) => setSearch(e.target.value)}
          value={search}
        />
        {farms
          .filter((farm) => FarmsFilter(farm, search))
          .map(([_, name, id]) => (
            <Dropdown.Item key={id} onClick={() => onSelect(id)}>
              {name}
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}



const FarmRow = ({
  id,
  data,
  profiles,
  showConsumption,
  searchIndex,
  setSelectedFarm,
}) => {
  return (
    <tr>
      <th scope="row">#{id}</th>
      <td>{CommanderFarmNameByID(id, searchIndex) || "Unavailable"}</td>
      <td>
        <ProfileSelector farm={id} default={data.default} profiles={profiles} />
      </td>
      <td>
        <ButtonGroup className="btn-group-toggle">
          {/* <Button
            href="#"
            variant="outline-primary button button-icon"
            target="_blank"
            onClick={() => showConsumption(id)}
          >
            Consumption
          </Button> */}
          <Button
            variant="outline-primary button button-icon"
            onClick={() => setSelectedFarm(id)}
          >
            Workers
          </Button>
          <Button
            variant="outline-primary button button-icon"
            onClick={() => CommanderFarmDelete(id)}
          >
            Delete
          </Button>
        </ButtonGroup>
      </td>
    </tr>
  )
}

const FarmsTable = ({ farms, profiles, searchIndex, setSelectedFarm }) => {
  const showConsumption = (id) => {
    setConsumptionShow(true)
    setConsumptionFarm(id)
  }

  const [consumptionShow, setConsumptionShow] = useState(false)
  const [consumptionFarm, setConsumptionFarm] = useState(0)

  return (
    <>
      <ConsumptionCommander
        show={consumptionShow}
        setShow={setConsumptionShow}
        farm={consumptionFarm}
      />
      <Table style={{ minHeight: "499px" }}>
        <thead>
          <tr>
            <th scope="col">Hive ID</th>
            <th scope="col">Farm name</th>
            <th scope="col">Default profile</th>
            <th scope="col">Controls</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(farms).map((id) => (
            <FarmRow
              setSelectedFarm={setSelectedFarm}
              key={id}
              id={parseInt(id)}
              data={farms[id]}
              searchIndex={searchIndex}
              profiles={profiles}
              showConsumption={showConsumption}
            />
          ))}
        </tbody>
      </Table>
    </>
  )
}

const FarmAdd = ({ show, showSet, exclude, searchIndex }) => {
  const [selectedFarm, setSelectedFarm] = useState()

  const addFarm = (id) =>
    database.ref(`/farm/${id}`).set({ default: "none" }, () => {
      setSelectedFarm(undefined)
      showSet(false)
    })

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>Add farm</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FarmSelector
          selected={selectedFarm}
          farms={searchIndex}
          exclude={exclude}
          onSelect={(id) => addFarm(id)}
        />
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup className="btn-group-toggle">
          <Button
            href="#"
            variant="outline-primary button button-icon"
            onClick={() => showSet(false)}
          >
            Cancel
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  )
}

const FarmsCommander = () => {
  const [showFarmAdd, setShowFarmAdd] = useState(false)
  const [farms, setFarms] = useState({})
  const [profiles, setProfiles] = useState({})
  const [searchIndex, setSearchIndex] = useState([])

  const [selectedFarm, setSelectedFarm] = useState(undefined)

  useEffect(() => {
    database
      .ref("/farm")
      .on("value", (snapshot) => setFarms(snapshot.val() || {}))
  }, [])

  useEffect(() => {
    database
      .ref("/profile")
      .on("value", (snapshot) => setProfiles(snapshot.val() || {}))
  }, [])

  useEffect(() => {
    database
      .ref("/cache/farms/search")
      .on("value", (snapshot) => setSearchIndex(snapshot.val() || {}))
  }, [])

  return (
    <>
      <WorkersManager farm={selectedFarm} setFarm={setSelectedFarm} />
      <FarmAdd
        show={showFarmAdd}
        showSet={setShowFarmAdd}
        searchIndex={searchIndex}
        exclude={farms}
      />

      <Container fluid style={{ minHeight: 500 }}>
        <Row>
          <Col lg="12" sm="12">
            <Card>
              <Card.Header>
                <Breadcrumb>
                  <Breadcrumb.Item href="/">
                    <i className="ri-home-4-line pt-1 mr-2 float-left"></i>
                    Cryptotech
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Commander</Breadcrumb.Item>
                  <Breadcrumb.Item active>Farms</Breadcrumb.Item>
                </Breadcrumb>
                <Card.Header.Action>
                  <ButtonGroup className="btn-group-toggle">
                    <Button
                      href="#"
                      variant="outline-primary button button-icon"
                      onClick={() => setShowFarmAdd(true)}
                    >
                      Add
                    </Button>
                  </ButtonGroup>
                </Card.Header.Action>
              </Card.Header>
              <Card.Body>
                <FarmsTable
                  farms={farms}
                  profiles={profiles}
                  searchIndex={searchIndex}
                  setSelectedFarm={setSelectedFarm}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default FarmsCommander
