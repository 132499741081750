import { createSelector } from "reselect"

export const authSelector = (state) => state.auth

export const displayNameSelector = createSelector(authSelector, (auth) => {
	return auth.displayName
})

export const emailSelector = createSelector(authSelector, (auth) => {
	return auth.email
})

export const isUserAuthenticatedSelector = createSelector(
	authSelector,
	(auth) => {
		return auth.authenticated
	},
)

export const errorSelector = createSelector(authSelector, (auth) => {
	return auth.error
})
