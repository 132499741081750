import { useEffect, useState } from "react"
import { Card, Form, InputGroup, ListGroup } from "react-bootstrap"
import datetime from "date-and-time"
import { DropdownSelector } from "../Components"

const format = "MMM D, YYYY"

const Batch = () => {
  const now = new Date()
  const [date, setDate] = useState(datetime.format(now, format))
  const [style, setStyle] = useState()
  const [source, setSrouce] = useState("")

  useEffect(() => {
    if (date === "") setStyle()
    else {
      const d = datetime.parse(date, format)

      if (d instanceof Date && !isNaN(d.valueOf())) setStyle("border-success")
      else setStyle("border-danger")
    }
  }, [date])

  return (
    <>
      <h4>New batch</h4>
      <br />
      <>
        Date:
        <Form.Control
          className={"border " + style}
          type="text"
          onChange={(e) => setDate(e.target.value)}
          value={date}
        />
      </>
      <br />
      <>
        Source:
        <Form.Control
          type="text"
          onChange={(e) => setSrouce(e.target.value)}
          value={source}
        />
      </>
    </>
  )
}

const models = [
  {
    name: "NVIDIA CORP PG132",
    id: "65b3f276-637a-42b3-8b34-48ae057b4142",
  },
  {
    name: "ZOTAC GAMING GEFORCE RTX 3060 Ti Twin Edge LHR",
    id: "918d8442-0791-4207-84ef-473b4fc0f37a",
  },
]

const owners = [
  {
    name: "Alessandro Delass",
    id: "a33efadd-cba2-4d03-aa2b-090605c32a05",
  },
]

const GPU = ({ variant, data, bulk }) => {
  return (
    <>
      <p>
        GPU Model:&nbsp;&nbsp;
        <DropdownSelector
          options={models}
          onSelect={(selected) => (data["model"] = selected.id)}
          variant={variant}
        />
      </p>
      <p>
        Owner:&nbsp;&nbsp;
        <DropdownSelector
          options={owners}
          onSelect={(selected) => (data["owner"] = selected.id)}
          variant={variant}
        />
      </p>
      <p>
        Serial number:
        <Form.Control
          placeholder="AA123"
          as="textarea"
          onChange={(e) => (bulk['serial'] = e.target.value)}
        />
      </p>
      <p>
        Warranty:{" "}
        <Form.Control
          placeholder="Sep 1, 2022"
          onChange={(e) => (data["warranty"] = e.target.value)}
        />
      </p>
    </>
  )
}

const GPUModel = ({ data }) => {
  data["memory"] = data["memory"] || {}

  return (
    <>
      <h4>GPU Model</h4>
      <br />
      <>
        Name:
        <Form.Control
          placeholder="GPU name"
          onChange={(e) => (data["name"] = e.target.value)}
        />
      </>
      <br />
      <>
        Model:
        <Form.Control
          placeholder="GPU Model"
          onChange={(e) => (data["model"] = e.target.value)}
        />
      </>
      <br />
      <>
        Manufacturer:
        <Form.Control
          placeholder="GPU Manufacturer"
          onChange={(e) => (data["manufacturer"] = e.target.value)}
        />
      </>
      <br />
      <Card>
        <ListGroup variant="flush">
          <ListGroup.Item>
            <h4>Memory</h4>
          </ListGroup.Item>
          <ListGroup.Item>
            <>
              Interface:
              <Form.Control
                placeholder="GDDR 5, 6, etc..."
                onChange={(e) => (data["memory"]["interface"] = e.target.value)}
              />
            </>
          </ListGroup.Item>
          <ListGroup.Item>
            <>
              Manufacturer:
              <Form.Control
                placeholder="Memory manufacturer, Samsung, PNY, etc..."
                onChange={(e) =>
                  (data["memory"]["manufacturer"] = e.target.value)
                }
              />
            </>
          </ListGroup.Item>
          <ListGroup.Item>
            <>
              Size (GB):
              <InputGroup>
                <Form.Control
                  placeholder="Size in gigabytes"
                  onChange={(e) => (data["memory"]["size"] = e.target.value)}
                />
                <InputGroup.Text>GB</InputGroup.Text>
              </InputGroup>
            </>
          </ListGroup.Item>
        </ListGroup>
      </Card>
    </>
  )
}

export { GPU, Batch, GPUModel }
