import crypto from "crypto"
import date from "date-and-time"
import fetch from "node-fetch"
import mergeDeep from "./mergeDeep.js"

/** @typedef {object} SmartAccountsClient
 * @property {string} id
 * @property {string} name
 * @property {string} regCode
 * @property {string} vatNumber
 * @property {boolean} ignoreOnVatDeclaration
 * @property {string} accountUnpaid
 * @property {string} accountPrepayment
 * @property {string} dateCreated
 * @property {string} createdBy
 */

/** @typedef {object} SmartAccountsClientCreate
 * @property {string} name
 * @property {string} vatPc
 */

export class SmartAccountsClients {
	/**
	 * @type {SmartAccounts}
	 */
	api

	/**
	 * @param {SmartAccounts} api
	 */
	constructor(api) {
		this.api = api
	}

	/**
	 * Returns all clients.
	 *
	 * @returns {Promise<SmartAccountsClient[]>}
	 */
	async all() {
		return this.api
			.request("purchasesales/clients", "get")
			.then((response) => response.clients)
	}

	/**
	 * Returns client data by it's id.
	 *
	 * @param {string} id
	 *
	 * @returns {Promise<SmartAccountsClient>}
	 */
	async get(id) {
		return this.api.request("purchasesales/clients", "get", { id })
	}

	/**
	 * @param {SmartAccountsClientCreate} data
	 *
	 * @returns {Promise<any>}
	 */
	async create(data) {
		return this.api.request("purchasesales/clients", "add", data)
	}
}

/** @typedef {object} InvoiceCreateResult
 * @property {string} clientId
 * @property {number} number
 * @property {string} invoiceNumber
 * @property {string} invoiceId
 * @property {number} amount
 * @property {number} vatAmount
 * @property {number} totalAmount
 * @property {number} roundAmount
 * @property {string} dueDate
 * @property {number} interest
 */

export class SmartAccountsInvoices {
	/**
	 * @type {SmartAccounts}
	 */
	api

	/**
	 * @param {SmartAccounts} api
	 */
	constructor(api) {
		this.api = api
	}

	/**
	 *
	 * @param {*} data
	 * @returns {Promise<InvoiceCreateResult>}
	 */
	async create(data) {
		return this.api.request("purchasesales/clientinvoices", "add", data)
	}
}

/** @typedef {object} VatPc
 * @property {string} vatPc
 * @property {number} pc
 * @property {string} descriptionEt
 * @property {string} descriptionEn
 * @property {string} descriptionRu
 * @property {boolean} activePurchase
 * @property {number} order
 * @property {boolean} custom
 */

export class SmartAccountsVatpc {
	/**
	 * @type {SmartAccounts}
	 */
	api

	/**
	 * @param {SmartAccounts} api
	 */
	constructor(api) {
		this.api = api
	}

	/**
	 *
	 * @returns {Promise<VatPc[]>}
	 */
	async get() {
		return this.api
			.request("settings/vatpcs", "get")
			.then((response) => response.vatPcs)
	}
}

export default class SmartAccounts {
  /*
   * @type {{public: string, private: string}}
   */
  secrets

  /**
   * @param {{public: string, private: string}} secrets
   * @param {string|undefined} proxy URL to CORS proxy, `undefined` by default
   */
  constructor(secrets, proxy = undefined) {
    this.PROTOCOL = "https://"
    this.HOSTNAME = "sa.smartaccounts.eu"
    this.BASEPATH = "/api"

    this.secrets = secrets
    this.clients = new SmartAccountsClients(this)
    this.invoices = new SmartAccountsInvoices(this)
    this.vatpc = new SmartAccountsVatpc(this)

    this.headers = {}
    this.target = this.PROTOCOL + this.HOSTNAME

    if (proxy !== undefined) {
      this.target = proxy + this.target
    }
  }

  /**
   *
   * @param {string} endpoint
   * @param {'get'|'add'|'edit'} method
   * @param {{}} [data={}]
   *
   * @returns {Promise<{}>} Promise<`{}`>
   */
  async request(endpoint, method, data = {}, page = 1) {
    const url = new URL(
      this.target + this.BASEPATH + "/" + endpoint + ":" + method
    )
    const params = url.searchParams

    const now = new Date()

    params.set("pageNumber", page)
    params.set("timestamp", date.format(now, "DDMMYYYYHHmmss"))
    params.set("apikey", this.secrets.public)

    const request = JSON.stringify(data)

    const signature = crypto
      .createHmac("sha256", this.secrets.private)
      .update(url.searchParams.toString() + request)
      .digest("hex")

    params.set("signature", signature)

    const response = await fetch(url, {
      method: "POST",
      body: request,
      headers: this.headers,
    }).then((response) => response.json())

    if (response.errors !== undefined) {
      throw new Error("SmartAccount errors:" + JSON.stringify(response.errors))
    }

    // Response is not paged
    if (response.hasMoreEntries === undefined) return response

    // If last page
    if (response.hasMoreEntries === false) return response

    // Not last page, increment page and join responses
    return mergeDeep(
      response,
      await this.request(endpoint, method, data, ++page)
    )
  }
}

// async function main() {
// 	// Set up api
// 	const secrets = {
// 		public: '123',
// 		private: '123'
// 	}

// 	const api = new SmartAccounts(secrets)

// 	// Fetch all clients and print their names
// 	// const clients = await api.clients.all()

// 	// console.log('Total clients: ', clients.length)

// 	// clients.forEach((client, index) =>
// 	// 	console.log(index, client.id, client.name))

// 	// const response = await api.vatpc.get()

// 	// Create dummy client
// 	// const response = await api.clients.create({
// 	// 	name: 'TEST CLIENT 0xFF',
// 	// 	vatPc: '20'
// 	// })
// 	// Reponse:
// 	// {
// 	// 	"clientId": "b23b37aa-1cd3-48fc-aeaa-98ddd32fa427"
// 	// }

// 	const dummyClientId = "b23b37aa-1cd3-48fc-aeaa-98ddd32fa427"

// 	const prices = {
// 		rent: 25,
// 		electricity: {
// 			day: 0.1309,
// 			night: 0.0478
// 		},
// 		currency: 'EUR'
// 	}

// 	const payPeriod = 'July 2022'
// 	const workers = 27

// 	const rows = [
// 		{ 'code': '00010', 'description': 'Rent - Riiuli rent hotelis', 'price': prices.rent, 'quantity': workers, 'vatPc': '20' }
// 	]

// 	for (let i = 0; i < workers; i++) {
// 		const description = `Elekter 8 - Worker No${i + 1} - (${prices.electricity.day} + ${prices.electricity.night} vorguteenus KW/h for ${payPeriod})`

// 		rows.push({
// 			code: '00010',
// 			description,
// 			price: prices.electricity.day + prices.electricity.night,
// 			quantity: Math.floor(Math.random() * 800 + 200),
// 			vatPc: '20'
// 		})
// 	}

// 	const result = await api.invoices.create({
// 		'clientId': dummyClientId,
// 		'date': date.format(new Date(), 'DD.MM.YYYY'),
// 		'currency': prices.currency,
// 		'rows': rows
// 	})

// 	// {
// 	// 	"clientId": "b23b37aa-1cd3-48fc-aeaa-98ddd32fa427",
// 	// 	"number": 667,
// 	// 	"invoiceNumber": "MA2200667",
// 	// 	"invoiceId": "fea54e87-5489-40bb-82bf-1e3f64576881",
// 	// 	"amount": 100,
// 	// 	"vatAmount": 20,
// 	// 	"totalAmount": 120,
// 	// 	"roundAmount": 0,
// 	// 	"dueDate": "17.07.2022",
// 	// 	"interest": 0.15
// 	// }
// 	// console.log(JSON.stringify(result, null, 4))
// }

// main()
// 	.then(() => console.log('Main done.'))
// 	.catch(e => console.error(e))
