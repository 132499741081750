import { Breadcrumb } from "react-bootstrap"
import { Link } from "react-router-dom"

const Navigator = () => {
  return (
    <Breadcrumb>
      <Breadcrumb.Item href="/">
        <i className="ri-home-4-line pt-1 mr-2 float-left"></i>
        <Link to="/">Cryptotech</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Link to="/hq">HQ</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item active>
        <Link to="/hq/farms">Farms</Link>
      </Breadcrumb.Item>
    </Breadcrumb>
  )
}

export default Navigator
