import { Switch, Route, useLocation } from "react-router-dom"
import { TransitionGroup, CSSTransition } from "react-transition-group"

//main
import Dashbord from "../views/backend/Main/Dashbord"

// Calendar
import Calendar from "../views/backend/Main/Calendar"

// Team
import Team from "../views/backend/Main/Team"

// Customer
import Customer from "../views/backend/Main/Customer"
import CustomerAdd from "../views/backend/Main/CustomerAdd"
import CustomerView from "../views/backend/Main/CustomerView"
import CustomerEdit from "../views/backend/Main/CustomerEdit"

// Miner
import Miner from "../views/backend/Main/Miner"
import MinerLabel from "../views/backend/Main/MinerLabel"
import MinerAdd from "../views/backend/Main/MinerAdd"
import MinerView from "../views/backend/Main/MinerView"
import MinerEdit from "../views/backend/Main/MinerEdit"

// Product
import Product from "../views/backend/Main/Product"
import ProductAdd from "../views/backend/Main/ProductAdd"
import ProductView from "../views/backend/Main/ProductView"

// order
import Order from "../views/backend/Main/Order"
import OrderAdd from "../views/backend/Main/OrderAdd"
import OrderView from "../views/backend/Main/OrderView"

// order
import Hotel from "../views/backend/Main/Hotel"
import HotelAdd from "../views/backend/Main/HotelAdd"
// import OrderView from "../views/backend/Main/OrderView"

//Invoice
import Invoice from "../views/backend/Main/Invoice"
import InvoiceView from "../views/backend/Main/InvoiceView"
import InvoiceComplete from "../views/backend/Main/InvoiceComplete"
import InvoiceAdd from "../views/backend/Main/InvoiceAdd"

//App
import UserProfile from "../views/backend/App/UserManagement/UserProfile"
import UserAdd from "../views/backend/App/UserManagement/UserAdd"
import UserList from "../views/backend/App/UserManagement/UserList"
import UserPrivacySetting from "../views/backend/App/UserManagement/UserPrivacySetting"
import UserAccountSettingList from "../views/backend/App/UserManagement/UserAccountSetting"
import UserProfileEdit from "../views/backend/App/UserManagement/UserProfileEdit"
import Chat from "../views/backend/App/Chat"
import Todo from "../views/backend/App/Todo"

//Chart
import ApexChart from "../views/backend/Chart/ApexChart"

//From
import Checkbox from "../views/backend/Forms/FormControls/Checkbox"
import Elements from "../views/backend/Forms/FormControls/Elements"
import Inputs from "../views/backend/Forms/FormControls/Inputs"
import Radio from "../views/backend/Forms/FormControls/Radio"
import FromSwitch from "../views/backend/Forms/FormControls/Switch"
import TextArea from "../views/backend/Forms/FormControls/TextArea"
import Validations from "../views/backend/Forms/FormControls/Validations"
import Datepicker from "../views/backend/Forms/Formwidget/Datepicker"
import Fileupload from "../views/backend/Forms/Formwidget/Fileupload"
import FormQuill from "../views/backend/Forms/Formwidget/FormQuill"
import Select from "../views/backend/Forms/Formwidget/Select"

//Extrapages
import Timeline1 from "../views/backend/pages/Timeline/Timeline1"
import Pricing1 from "../views/backend/pages/Pricing/Pricing1"
import FAQ from "../views/backend/pages/FAQ"
import BlankPage from "../views/backend/pages/BlankPage"
import TermsOfUse from "../views/backend/pages/Extrapages/TermsOfUse"
import PrivacyPolicy from "../views/backend/pages/Extrapages/PrivacyPolicy"

//Table
import BasicTable from "../views/backend/Table/BasicTable"
import DataTable from "../views/backend/Table/DataTable"
import EditTable from "../views/backend/Table/EditTable"

//ui
// import UiAlerts from "../views/backend/ui/UiAlerts"
// import UiAvatars from "../views/backend/ui/UiAvatars"
// import UiBadges from "../views/backend/ui/UiBadges"
// import UiBoxShadows from "../views/backend/ui/UiBoxShadows"
// import UiBreadcrumbs from "../views/backend/ui/UiBreadcrumbs"
// import UiButtonGroups from "../views/backend/ui/UiButtonGroups"
// import UiButtons from "../views/backend/ui/UiButtons"
// import UiCards from "../views/backend/ui/UiCards"
// import UiCarousels from "../views/backend/ui/UiCarousels"
// import UiColors from "../views/backend/ui/UiColors"
// import UiEmbed from "../views/backend/ui/UiEmbed"
// import UiGrids from "../views/backend/ui/UiGrids"
// import UiImages from "../views/backend/ui/UiImages"
// import UiListGroups from "../views/backend/ui/UiListGroups"
// import UiMediaObjects from "../views/backend/ui/UiMediaObjects"
// import UiModals from "../views/backend/ui/UiModals"
// import UiNotifications from "../views/backend/ui/UiNotifications"
// import UiPaginations from "../views/backend/ui/UiPaginations"
// import UiPopOvers from "../views/backend/ui/UiPopOvers"
// import UiProgressBars from "../views/backend/ui/UiProgressBars"
// import UiTabs from "../views/backend/ui/UiTabs"
// import UiTooltips from "../views/backend/ui/UiTooltips"
// import UiTypography from "../views/backend/ui/UiTypography"

// icon-heroicon
import Heroicons from "../views/backend/Icons/Heroicons "
import {
	Farms,
	Profiles,
	Monitor,
	DatabaseUI,
} from "../views/backend/Commander"

import { UI as HQ } from "../views/backend/HQ"

const Layout1Route = ({ role }) => {
	let location = useLocation()

	switch (role) {
		case "admin":
			return (
				<TransitionGroup>
					<CSSTransition key={location.key} classNames="fade" timeout={300}>
						<Switch location={location}>
							<Route path="/" exact component={Dashbord} />
							<Route path="/hq" component={HQ} />
							<Route path="/commander-farms" component={Farms} />
							<Route path="/commander-profiles" component={Profiles} />
							<Route path="/commander-monitor" component={Monitor} />
							<Route path="/commander-database" component={DatabaseUI} />
							<Route path="/user-profile" component={UserProfile} />
							<Route path="/user-add" component={UserAdd} />
							<Route path="/user-list" component={UserList} />
							<Route
								path="/user-privacy-setting"
								component={UserPrivacySetting}
							/>
							<Route
								path="/user-account-setting"
								component={UserAccountSettingList}
							/>
							<Route path="/user-profile-edit" component={UserProfileEdit} />
							<Route path="/chat" component={Chat} />
							<Route path="/todo" component={Todo} />
							<Route path="/chart-apex" component={ApexChart} />
							<Route path="/form-chechbox" component={Checkbox} />
							<Route path="/form-layout" component={Elements} />
							<Route path="/form-input-group" component={Inputs} />
							<Route path="/form-radio" component={Radio} />
							<Route path="/form-switch" component={FromSwitch} />
							<Route path="/form-textarea" component={TextArea} />
							<Route path="/form-validation" component={Validations} />
							<Route path="/form-datepicker" component={Datepicker} />
							<Route path="/form-file-uploader" component={Fileupload} />
							<Route path="/form-quill" component={FormQuill} />
							<Route path="/form-select" component={Select} />
							<Route path="/icon-heroicon" component={Heroicons} />
							<Route path="/timeline-1" component={Timeline1} />
							<Route path="/pricing-1" component={Pricing1} />
							<Route path="/pages-faq" component={FAQ} />
							<Route path="/pages-blank-page" component={BlankPage} />
							<Route path="/terms-of-service" component={TermsOfUse} />
							<Route path="/privacy-policy" component={PrivacyPolicy} />
							<Route path="/tables-basic" component={BasicTable} />
							<Route path="/tables-data" component={DataTable} />
							<Route path="/tables-editable" component={EditTable} />
							{/* <Route path="/ui-alerts" component={UiAlerts} />
							<Route path="/ui-avatars" component={UiAvatars} />
							<Route path="/ui-badges" component={UiBadges} />
							<Route path="/ui-boxshadow" component={UiBoxShadows} />
							<Route path="/ui-breadcrumb" component={UiBreadcrumbs} />
							<Route path="/ui-buttons-group" component={UiButtonGroups} />
							<Route path="/ui-buttons" component={UiButtons} />
							<Route path="/ui-cards" component={UiCards} />
							<Route path="/ui-carousel" component={UiCarousels} />
							<Route path="/ui-colors" component={UiColors} />
							<Route path="/ui-embed-video" component={UiEmbed} />
							<Route path="/ui-grid" component={UiGrids} />
							<Route path="/ui-images" component={UiImages} />
							<Route path="/ui-list-group" component={UiListGroups} />
							<Route path="/ui-media-object" component={UiMediaObjects} />
							<Route path="/ui-modal" component={UiModals} />
							<Route path="/ui-notifications" component={UiNotifications} />
							<Route path="/ui-pagination" component={UiPaginations} />
							<Route path="/ui-popovers" component={UiPopOvers} />
							<Route path="/ui-progressbars" component={UiProgressBars} />
							<Route path="/ui-tabs" component={UiTabs} />
							<Route path="/ui-tooltips" component={UiTooltips} />
							<Route path="/ui-typography" component={UiTypography} /> */}
							<Route path="/customer" component={Customer} />
							<Route path="/customer-add" component={CustomerAdd} />
							<Route path="/customer-view/:id" component={CustomerView} />
							<Route path="/customer-edit/:id" component={CustomerEdit} />
							<Route path="/miner" component={Miner} />
							<Route path="/miner-label" component={MinerLabel} />
							<Route path="/miner-add" component={MinerAdd} />
							<Route path="/miner-view/:id" component={MinerView} />
							<Route path="/miner-edit/:id" component={MinerEdit} />
							<Route path="/product" component={Product} />
							<Route path="/product-add" component={ProductAdd} />
							<Route path="/product-view/:id" component={ProductView} />
							<Route path="/order" component={Order} />
							<Route path="/order-add" component={OrderAdd} />
							<Route path="/order-view/:id" component={OrderView} />
							<Route path="/hotel" component={Hotel} />
							<Route path="/hotel-add" component={HotelAdd} />
							<Route path="/invoice" component={Invoice} />
							<Route path="/invoice-add" component={InvoiceAdd} />
							<Route path="/invoice-complete/:id" component={InvoiceComplete} />
							<Route path="/invoice-view/:id" component={InvoiceView} />
							<Route path="/calendar" component={Calendar} />
							<Route path="/team" component={Team} />
						</Switch>
					</CSSTransition>
				</TransitionGroup>
			)
		case "manager":
			return (
				<TransitionGroup>
					<CSSTransition key={location.key} classNames="fade" timeout={300}>
						<Switch location={location}>
							<Route path="/" exact component={Dashbord} />
							<Route path="/hq" component={HQ} />
							<Route path="/commander-farms" component={Farms} />
							<Route path="/commander-profiles" component={Profiles} />
							<Route path="/commander-monitor" component={Monitor} />
							<Route path="/commander-database" component={DatabaseUI} />
							<Route path="/user-profile" component={UserProfile} />
							<Route path="/user-add" component={UserAdd} />
							<Route path="/user-list" component={UserList} />
							<Route
								path="/user-privacy-setting"
								component={UserPrivacySetting}
							/>
							<Route
								path="/user-account-setting"
								component={UserAccountSettingList}
							/>
							<Route path="/user-profile-edit" component={UserProfileEdit} />
							<Route path="/chat" component={Chat} />
							<Route path="/todo" component={Todo} />
							<Route path="/terms-of-service" component={TermsOfUse} />
							<Route path="/privacy-policy" component={PrivacyPolicy} />
							<Route path="/customer" component={Customer} />
							<Route path="/customer-add" component={CustomerAdd} />
							<Route path="/customer-view/:id" component={CustomerView} />
							<Route path="/customer-edit/:id" component={CustomerEdit} />
							<Route path="/product" component={Product} />
							<Route path="/product-add" component={ProductAdd} />
							<Route path="/product-view/:id" component={ProductView} />
							<Route path="/order" component={Order} />
							<Route path="/order-add" component={OrderAdd} />
							<Route path="/order-view/:id" component={OrderView} />
							<Route path="/invoice" component={Invoice} />
							<Route path="/invoice-add" component={InvoiceAdd} />
							<Route path="/invoice-view/:id" component={InvoiceView} />
							<Route path="/invoice-view" component={InvoiceView} />
							<Route path="/calendar" component={Calendar} />
						</Switch>
					</CSSTransition>
				</TransitionGroup>
			)
		case "tech":
			return (
				<TransitionGroup>
					<CSSTransition key={location.key} classNames="fade" timeout={300}>
						<Switch location={location}>
							<Route path="/" exact component={Dashbord} />
							<Route
								path="/user-privacy-setting"
								component={UserPrivacySetting}
							/>
							<Route
								path="/user-account-setting"
								component={UserAccountSettingList}
							/>
							<Route path="/chat" component={Chat} />
							<Route path="/todo" component={Todo} />
							<Route path="/terms-of-service" component={TermsOfUse} />
							<Route path="/privacy-policy" component={PrivacyPolicy} />
							<Route path="/miner" component={Miner} />
							<Route path="/miner-add" component={MinerAdd} />
							<Route path="/miner-view/:id" component={MinerView} />
							<Route path="/miner-edit/:id" component={MinerEdit} />
							<Route path="/product" component={Product} />
							<Route path="/product-add" component={ProductAdd} />
							<Route path="/product-view/:id" component={ProductView} />
							<Route path="/calendar" component={Calendar} />
						</Switch>
					</CSSTransition>
				</TransitionGroup>
			)

		default:
			return (
				<div>
					<p>
						Your system administrator did not assign a meaningful role for you.
					</p>
					<p>
						Your current role: <code>"{role}"</code>.
					</p>
				</div>
			)
	}
}

export default Layout1Route
