import date from "date-and-time"

// Annotation position for 'Today'
const s =
  Date.parse(date.format(new Date(), "YYYY/MM/DD 00:00")) + 24 * 60 * 60 * 1000

export default {
  chart: {
    type: "bar",
    toolbar: {
      autoSelected: "pan",
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      horizontal: false,
    },
  },
  xaxis: {
    type: "datetime",
  },
  annotations: {
    xaxis: [
      {
        x: s,
        borderColor: "#00E396",
        label: {
          borderColor: "#00E396",
          orientation: "horizontal",
          style: {
            color: "#fff",
            background: "#00E396",
          },
          text: "Today",
        },
      },
    ],
  },
  yaxis: [
    {
      labels: {
        formatter: (value) => Math.floor(value / 1000) + " kW",
      },
    },
  ],
  tooltip: {
    // 	shared: true,
    // 	intersect: false,

    x: {
      format: "yyyy MMMMM d",
    },
    y: {
      formatter: (value) => Math.floor(value / 100) / 10 + " kW",
    },
  },
}
