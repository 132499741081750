import { useContext } from "react"
import { FarmContext, HQContext } from "../contexts"
import { BasicTooltip } from "."

const ConsumptionTooltip = <>View consumption history.</>

const PowerDraw = () => {
	const { consumption } = useContext(HQContext)
	const { farm } = useContext(FarmContext)
	const { power_draw } = farm.data.stats

	const showConsumption = () => consumption.show(farm)

	return (
		<div
			className="property"
			onClick={showConsumption}
			style={{ cursor: "pointer" }}
		>
			<BasicTooltip text={ConsumptionTooltip}>
				<div className="value">
					{power_draw === 0
						? "0 W"
						: power_draw > 1000
						? `${(power_draw / 1000).toFixed(2)} kW`
						: `${power_draw.toFixed(1)} W`}
				</div>
				<div className="label">Power</div>
			</BasicTooltip>
		</div>
	)
}

export default PowerDraw
