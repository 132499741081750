import pdfMake from "pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
import htmlToPdfmake from "html-to-pdfmake"
import EnContract from "./en"
import EtContract from "./et"
import { Button } from "react-bootstrap"

const PdfContract = ({
	companyName,
	companyCode,
	fullName,
	idCode,
	address,
	lang,
	children,
}) => {
	const printDocument = () => {
		const pdfTable = document.getElementById("contractPDF")
		const html = htmlToPdfmake(pdfTable.innerHTML)

		const fileName = `${fullName.replace(
			/\s/g,
			"_",
		)}_Contract_${new Date().toLocaleDateString()}`

		const documentDefinition = {
			content: html,
			info: {
				title: "Cryptotech Contract",
				author: "Cryptotech OÜ",
				subject: "Contract",
			},
		}
		pdfMake.vfs = pdfFonts.pdfMake.vfs
		pdfMake.createPdf(documentDefinition).download(`${fileName}.pdf`)
	}
	const language = (lang) => {
		switch (lang) {
			case "en":
				return (
					<EnContract
						companyName={companyName}
						companyCode={companyCode}
						fullName={fullName}
						idCode={idCode}
						address={address}
					/>
				)
			case "et":
				return (
					<EtContract
						companyName={companyName}
						companyCode={companyCode}
						fullName={fullName}
						idCode={idCode}
						address={address}
					/>
				)
			default:
				return (
					<EtContract
						companyName={companyName}
						companyCode={companyCode}
						fullName={fullName}
						idCode={idCode}
						address={address}
					/>
				)
		}
	}
	return (
		<div>
			<Button
				size="sm"
				variant="btn btn-block btn-primary"
				onClick={() => printDocument()}
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="mr-1"
					width="20"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
						d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
					/>
				</svg>
				{children}
			</Button>
			{language(lang)}
		</div>
	)
}
export default PdfContract
