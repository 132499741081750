import {
  Breadcrumb,
  Container,
  ButtonGroup,
  Button,
  OverlayTrigger,
  Tooltip,
  Tab,
  Nav,
} from "react-bootstrap"

import Card from "../../../../components/Card"
import { AddUI, Item, ReindexUI } from "."
import { Browser } from "./Browser"
import { Search } from "./Search"
import { Fragment, useEffect, useState } from "react"
import { Editor } from "./Editor"
import { generateUUIDv4 } from "./utils"
import { Prototype } from "./Prototype"

const suggestions = [
  ["a33efadd-cba2-4d03-aa2b-090605c32a05", "User Alessandro"],
  ["406e2c38-78f3-4850-938f-b38b8e3ab6b8", "User Else"],
  ["d298e337-61e9-4a77-b2f6-c085fde1e368", "OctoRig"],
  ["c1824e38-e822-41b2-aeda-356b83b77b87", "Some GPU"],
  ["e197f7ee-a720-4dac-9d52-338d4f285f99", "New GPU"],
]

const UI = () => {
  const [selected, setSelected] = useState(
    window.location.hash.length > 0 ? window.location.hash.slice(1) : undefined
  )

  const createNew = () => setSelected(generateUUIDv4())

  useEffect(() => {
    window.location.replace(selected ? "#" + selected : "#")
  }, [selected])

  useEffect(() => {
    setSelected(
      window.location.hash.length > 0
        ? window.location.hash.slice(1)
        : undefined
    )
  }, [window.location.hash])

  return (
    <Container fluid>
      <Card>
        <Card.Header>
          <Breadcrumb>
            <Breadcrumb.Item href="/">
              <i className="ri-home-4-line pt-1 mr-2 float-left"></i>
              Cryptotech
            </Breadcrumb.Item>
            <Breadcrumb.Item>Commander</Breadcrumb.Item>
            <Breadcrumb.Item active>Database</Breadcrumb.Item>
          </Breadcrumb>
          <Card.Header.Action>
            <ButtonGroup>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Add new item to the Database</Tooltip>}
              >
                <Button onClick={createNew} variant="outline-primary">
                  Add
                </Button>
              </OverlayTrigger>
              <ReindexUI variant="outline-primary" />
            </ButtonGroup>
          </Card.Header.Action>
        </Card.Header>
        <Card.Body>
          <>
            Suggestions:&nbsp;
            {suggestions.map(([uuid, name]) => (
              <Fragment key={uuid}>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{uuid}</Tooltip>}
                >
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    style={{
                      marginRight: "5px",
                    }}
                    onClick={() => {
                      setSelected(uuid)
                    }}
                  >
                    {name}
                  </Button>
                </OverlayTrigger>
              </Fragment>
            ))}
          </>
          <Prototype uuid={selected} setUuid={setSelected} />
          {false && (
            <Tab.Container defaultActiveKey="home">
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="home">Home</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="search">Search</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="browser">Browser</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="home">
                  <>
                    Suggestions:&nbsp;
                    {suggestions.map(([uuid, name]) => (
                      <Fragment key={uuid}>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>{uuid}</Tooltip>}
                        >
                          <Button
                            variant="outline-secondary"
                            size="sm"
                            style={{
                              marginRight: "5px",
                            }}
                            onClick={() => {
                              setSelected(uuid)
                            }}
                          >
                            {name}
                          </Button>
                        </OverlayTrigger>
                      </Fragment>
                    ))}
                  </>
                  <Editor
                    uuid={selected}
                    setUuid={setSelected}
                    folded={false}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="search">
                  <Search onSelect={setSelected} />
                </Tab.Pane>
                <Tab.Pane eventKey="browser">
                  <Browser />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          )}
        </Card.Body>
      </Card>
    </Container>
  )
}

export default UI
