import { useEffect, useState } from "react"
import { Button, ButtonGroup, Modal, Table } from "react-bootstrap"
import { Link } from "react-router-dom"
import { hiveapi } from "../../../../config"
import BasicTooltip from "./BasicTooltip"

const WorkersManager = ({ farm, setFarm }) => {
  const [farmapi, setFarmapi] = useState(undefined)
  const [farmname, setFarmname] = useState(undefined)
  const [workers, setWorkers] = useState(undefined)

  useEffect(() => {
    if (farm === undefined) setFarmapi(undefined)
    else hiveapi.farms.get(farm).then((api) => setFarmapi(api))
  }, [farm])

  useEffect(() => {
    if (farmapi === undefined) setFarmname(undefined)
    else setFarmname(farmapi.name)
  }, [farmapi])

  useEffect(() => {
    if (farmapi === undefined) setWorkers(undefined)
    else farmapi.workers.all().then((w) => setWorkers(w))
  }, [farmapi])

  return (
    <Modal
      size="lg"
      show={farm !== undefined}
      onHide={() => setFarm(undefined)}
    >
      <Modal.Header>
        <Modal.Title>
          Workers manager:&nbsp;
          {farmname === undefined ? "Loading farm data" : farmname}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {workers === undefined ? (
          <>Workers are loading</>
        ) : workers.length === 0 ? (
          <>This farm has no workers</>
        ) : (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>
                  <BasicTooltip
                    text={"Uinque ID of this worker in HiveOS system"}
                  >
                    #
                  </BasicTooltip>
                </th>
                <th>
                  <BasicTooltip text={"Worker's display name"}>
                    Name
                  </BasicTooltip>
                </th>
                <th>
                  <BasicTooltip text={"Current status of CryptotechOS"}>
                    Status
                  </BasicTooltip>
                </th>
              </tr>
            </thead>
            <tbody>
              {workers.map((worker) => (
                <tr key={worker.id}>
                  <td>
                    <BasicTooltip
                      text={"Link to this worker in HiveOS"}
                      placement={"right"}
                    >
                      <Link
                        to={{
                          pathname: `https://the.hiveos.farm/farms/${farm}/workers/${worker.id}/`,
                        }}
                        target="_blank"
                      >
                        {worker.id}
                      </Link>
                    </BasicTooltip>
                  </td>
                  <td>{worker.name}</td>
                  <td>
                    <Button
                      variant="outline-primary"
                      onClick={() => worker.command("message info ping")}
                    >
                      Loading...
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup className="btn-group-toggle">
          <Button
            variant="outline-primary button button-icon"
            onClick={() => setFarm(undefined)}
          >
            Close
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  )
}

export default WorkersManager
