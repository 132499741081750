import { Alert } from "react-bootstrap"

const Danger = ({ message, error }) =>
  message !== null || error !== null ? (
    <Alert variant="danger">
      <div className="iq-alert-icon">
        <i className="ri-information-line"></i>
      </div>
      <div className="iq-alert-text">
        {message !== null
          ? message
          : error !== null
          ? error.hasOwnProperty(message)
            ? error.message
            : error.toString()
          : "No message"}
      </div>
    </Alert>
  ) : (
    <></>
  )

Danger.defaultProps = {
  message: null,
  error: null,
}

export default Danger
