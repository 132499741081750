import { useEffect, useState } from "react"
import { Types } from "."

import { validate as isUUID } from "uuid"
import { database } from "../../../../config"
import {
  Button,
  ButtonGroup,
  Nav,
  Navbar,
  OverlayTrigger,
  Tab,
  Tooltip,
} from "react-bootstrap"
import {
  ArrowDownShort,
  ArrowUpShort,
  Link45deg,
  PencilSquare,
  Trash,
} from "react-bootstrap-icons"
import { DropdownSelector } from "../Components"
import { Selector } from "./Selector"

const ItemStyle = {
  padding: "7px 12px",
  border: "1px solid #e2e2e2",
  margin: "5px 0",
  borderRadius: "5px",
}

const Item = ({ uuid, folded: foldedInitial, editing: editMode }) => {
  const [data, setData] = useState()
  const [type, setType] = useState()
  const [element, setElement] = useState()
  const [folded, setFolded] = useState(foldedInitial)
  const [editing, setEditing] = useState(false)

  useEffect(() => {
    setElement(
      type !== undefined && type !== null && Types[type.element] !== undefined
        ? type.element
        : "Error"
    )
  }, [type])

  useEffect(() => {
    if (data !== undefined) {
      const type_uuid =
        data === null || data.type === undefined
          ? "7ae1c172-1e63-497a-bb80-2d7a79d24e3a"
          : data.type

      let isMounted = true

      database
        .ref("/database/items/" + type_uuid)
        .on("value", (snapshot) => isMounted && setType(snapshot.val()))

      return () => {
        isMounted = false
      }
    }
  }, [data])

  useEffect(() => {
    setElement() // Note: if you remove this line it creates nice trinsition animation

    if (isUUID(uuid) === true) {
      let isMounted = true

      database
        .ref("/database/items/" + uuid)
        .on("value", (snapshot) => isMounted && setData(snapshot.val()))

      return () => {
        isMounted = false
      }
    }
  }, [uuid])

  if (element === undefined) return <></>

  const TagName = Types[element]

  if (editMode === true) return <Selector type={data ? data.type : undefined} />

  return (
    <div style={ItemStyle}>
      <h5>
        <ButtonGroup>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>Expand view</Tooltip>}
          >
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => setFolded(!folded)}
            >
              {folded ? <ArrowDownShort /> : <ArrowUpShort />}
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>Edit this item</Tooltip>}
          >
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => !editMode && setEditing(!editing)}
            >
              <PencilSquare />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>Copy link to this item</Tooltip>}
          >
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => navigator.clipboard.writeText('/commander-database#' + uuid)}
            >
              <Link45deg />
            </Button>
          </OverlayTrigger>
          {editMode && (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Remove this item</Tooltip>}
            >
              <Button size="sm" variant="outline-secondary" onClick={() => {}}>
                <Trash />
              </Button>
            </OverlayTrigger>
          )}
        </ButtonGroup>
        &nbsp; {type.name || ""}:&nbsp;
        {(type.title !== "" && type.index && data[type.index]) || ""}
      </h5>
      {!folded && <TagName {...data} editing={editing} />}
    </div>
  )
}

Item.defaultProps = {
  folded: true,
}

export { Item }
