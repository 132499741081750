import {
  version as uuidVersion,
  validate as uuidValidate,
  v4 as uuidv4,
} from "uuid"

function isValidUUIDv4(uuid) {
  return uuidValidate(uuid) && uuidVersion(uuid) === 4
}

function generateUUIDv4() {
  return uuidv4()
}

// https://stackoverflow.com/a/41015840
function StringTemplate(string, params) {
  const names = Object.keys(params)
  const vals = Object.values(params)

  try {
    return new Function(...names, `return \`${string || ""}\`;`)(...vals)
  } catch (e) {
    return ""
  }
}

export { isValidUUIDv4, generateUUIDv4, StringTemplate }
