import React, {
	// useRef,
	useState,
} from "react"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import Card from "../../../components/Card"
import { Link, useHistory } from "react-router-dom"
import {
	db,
	// storage,
} from "../../../config"

const ProductAdd = () => {
	const [name, setName] = useState("")
	const [category, setCategory] = useState("")
	const [manufacturer, setManufacturer] = useState("")
	const [quantity, setQuantity] = useState("")
	const [price, setPrice] = useState("")
	const [tax, setTax] = useState("20")
	const [description, setDescription] = useState("")

	// const inputFile = useRef(null)

	const history = useHistory()

	const handleAddProduct = async (e) => {
		e.preventDefault()
		await db
			.collection("products")
			.add({
				name: name,
				category: category,
				manufacturer: manufacturer,
				quantity: quantity,
				price: price,
				tax: tax,
				description: description,
				created: Date.now(),
			})
			.then(history.push("/product"))
	}

	return (
		<>
			<Container fluid>
				<Row>
					<Col lg="12">
						<div className="d-flex flex-wrap align-items-center justify-content-between">
							<div className="d-flex align-items-center justify-content-between">
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb p-0 mb-0">
										<li className="breadcrumb-item">
											<Link to="/product">Products</Link>
										</li>
										<li className="breadcrumb-item active" aria-current="page">
											Add Product
										</li>
									</ol>
								</nav>
							</div>
							<Link
								to="/product"
								className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="20"
									viewBox="0 0 20 20"
									fill="currentColor"
								>
									<path
										fillRule="evenodd"
										d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
										clipRule="evenodd"
									/>
								</svg>
								<span className="ml-2">Back</span>
							</Link>
						</div>
					</Col>
					<Col lg="12" className="mb-3 d-flex justify-content-between">
						<h4 className="font-weight-bold d-flex align-items-center">
							New Product
						</h4>
					</Col>
					<Col lg="12">
						<Card>
							<Card.Body>
								<h5 className="font-weight-bold mb-3">Basic Information</h5>
								<Form className="row g-3">
									<div className="col-md-6 mb-3">
										<Form.Label
											htmlFor="Text1"
											className="form-label font-weight-bold text-muted text-uppercase"
										>
											Product Name
										</Form.Label>
										<Form.Control
											type="text"
											className="form-control"
											id="Text1"
											placeholder="Enter Product Name"
											onChange={(e) => setName(e.target.value)}
										/>
									</div>
									<div className="col-md-6 mb-3">
										<Form.Label
											htmlFor="inputState"
											className="form-label font-weight-bold text-muted text-uppercase"
										>
											Category
										</Form.Label>
										<select
											id="inputState"
											className="form-select form-control choicesjs"
											onChange={(e) => setCategory(e.target.value)}
										>
											<option value="selected">Select Category</option>
											<option value="GPU">GPU</option>
											<option value="CPU">CPU</option>
											<option value="CASE">CASE</option>
										</select>
									</div>
									<div className="col-md-6 mb-3">
										<Form.Label
											htmlFor="Text4"
											className="form-label font-weight-bold text-muted text-uppercase"
										>
											Manufacturer
										</Form.Label>
										<Form.Control
											type="text"
											className="form-control"
											id="Text4"
											placeholder="Enter Manufacturer"
											onChange={(e) => setManufacturer(e.target.value)}
										/>
									</div>
									<div className="col-md-6 mb-3">
										<Form.Label
											htmlFor="Text5"
											className="form-label font-weight-bold text-muted text-uppercase"
										>
											Quantity
										</Form.Label>
										<Form.Control
											type="text"
											className="form-control"
											id="Text5"
											placeholder="Enter Quantity"
											onChange={(e) => setQuantity(e.target.value)}
										/>
									</div>
									<div className="col-md-6 mb-3">
										<Form.Label
											htmlFor="Text6"
											className="form-label font-weight-bold text-muted text-uppercase"
										>
											Price
										</Form.Label>
										<Form.Control
											type="text"
											className="form-control"
											id="Text6"
											placeholder="Enter Price"
											onChange={(e) => setPrice(e.target.value)}
										/>
									</div>
									<div className="col-md-6 mb-3">
										<Form.Label
											htmlFor="Text7"
											className="form-label font-weight-bold text-muted text-uppercase"
										>
											Tax
										</Form.Label>
										<Form.Control
											type="text"
											className="form-control"
											id="Text7"
											value={tax}
											placeholder="Enter Tax"
											onChange={(e) => setTax(e.target.value)}
										/>
									</div>
									<div className="col-md-12 mb-3">
										<Form.Label
											htmlFor="Text9"
											className="form-label font-weight-bold text-muted text-uppercase"
										>
											Description
										</Form.Label>
										<Form.Control
											as="textarea"
											id="Text9"
											rows="2"
											placeholder="Enter Description"
											onChange={(e) => setDescription(e.target.value)}
										/>
									</div>
								</Form>
								<div className="d-flex justify-content-end mt-3">
									<Button
										variant="btn btn-primary"
										onClick={(e) => {
											handleAddProduct(e)
										}}
									>
										Create Product
									</Button>
								</div>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	)
}
export default ProductAdd
