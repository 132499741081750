import React, { useState, useEffect } from "react"
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	OverlayTrigger,
	Tooltip,
} from "react-bootstrap"
import Card from "../../../components/Card"
import { Link } from "react-router-dom"
import { db } from "../../../config"
import Modal from "../../../components/Modal"

const Order = () => {
	const [orders, setOrders] = useState([{}])
	const [deleteModal, setDeleteModal] = useState(false)

	const fetchOrders = async () => {
		const response = db.collection("orders")
		const data = await response.get()
		const ordersArray = []
		data.docs.forEach((item) => {
			console.log(item)
			const data = item.data()
			data.id = item.id
			ordersArray.push(data)
		})
		setOrders(ordersArray)
	}

	const deleteOrder = (id) => {
		db.collection("orders")
			.doc(id)
			.delete()
			.then(() => {
				const newArr = orders.filter((order) => order.id !== id)
				setDeleteModal(false)
				setOrders(newArr)
			})
			.catch((error) => {
				console.error("Error removing document: ", error)
			})
	}

	useEffect(() => {
		fetchOrders()
	}, [])

	return (
		<>
			<Modal
				show={deleteModal}
				close={() => setDeleteModal(false)}
				confirmDelete={() => deleteOrder(deleteModal.id)}
			/>
			<Container fluid>
				<Row>
					<Col lg="12">
						<div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
							<div className="d-flex align-items-center justify-content-between">
								<h4 className="font-weight-bold">All Orders</h4>
							</div>
							<div className="create-workform">
								<div className="d-flex flex-wrap align-items-center justify-content-between">
									<div className="modal-product-search d-flex">
										<Form className="mr-3 position-relative">
											<Form.Group className="mb-0">
												<input
													type="text"
													className="form-control"
													id="exampleInputText"
													placeholder="Search Order"
												/>
												<Link className="search-link" to="#">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														className=""
														width="20"
														fill="none"
														viewBox="0 0 24 24"
														stroke="currentColor"
													>
														<path
															strokeLinecap="round"
															strokeLinejoin="round"
															strokeWidth="2"
															d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
														/>
													</svg>
												</Link>
											</Form.Group>
										</Form>
										<Link
											to="/order-add"
											className="btn btn-primary position-relative d-flex align-items-center justify-content-between"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												className="mr-2"
												width="20"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth="2"
													d="M12 6v6m0 0v6m0-6h6m-6 0H6"
												/>
											</svg>
											Add Order
										</Link>
									</div>
								</div>
							</div>
						</div>

						<Row>
							<Col lg="12">
								<Card className="card-block card-stretch">
									<Card.Body className="p-0">
										<div className="d-flex justify-content-between align-items-center p-3">
											<h5 className="font-weight-bold">Orders List</h5>
											<Button variant="btn btn-secondary btn-sm">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													className="mr-1"
													width="20"
													fill="none"
													viewBox="0 0 24 24"
													stroke="currentColor"
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth="2"
														d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
													/>
												</svg>
												Export
											</Button>
										</div>
										<div className="table-responsive">
											<table className="table data-table mb-0">
												<thead className="table-color-heading">
													<tr className="text-light">
														<th scope="col" className="w-01 pr-0">
															<div className="d-flex justify-content-start align-items-end mb-1">
																<div className="custom-control custom-checkbox custom-control-inline">
																	<input
																		type="checkbox"
																		className="custom-control-input m-0"
																		id="customCheck"
																	/>
																	<label
																		className="custom-control-label"
																		htmlFor="customCheck"
																	></label>
																</div>
															</div>
														</th>
														<th scope="col">
															<label className="text-muted m-0">ID</label>
															{/* <input type="text" className="form-control mt-2" id="text1" aria-describedby="textHelp" placeholder="ID"/>  */}
														</th>
														<th scope="col" className="dates">
															<label className="text-muted mb-0">
																Customer
															</label>
															{/* <input type="text" className="form-control" id="usr1" name="event_date" placeholder="Enter Date" autocomplete="off" aria-describedby="basic-addon2"/> */}
														</th>
														<th scope="col">
															<label className="text-muted mb-0">Email</label>
															{/* <input type="text" className="form-control mt-2" id="text2" aria-describedby="textHelp" placeholder="Customer"/> */}
														</th>
														<th scope="col" className="text-right">
															<label className="text-muted mb-0">Price</label>
															{/* <input type="text" className="form-control mt-2" id="text3" aria-describedby="textHelp" placeholder="Total"/>  */}
														</th>
														<th scope="col">
															<label className="text-muted mb-0">Status</label>
															{/* <select id="validationServer02" className="custom-select custom-select-sm mt-2">
                                                        <option selected>Status</option>
                                                        <option value="1">Completed</option>
                                                        <option value="2">Pending</option>
                                                        <option value="3">Rejected</option>
                                                    </select>  */}
														</th>
														<th scope="col" className="text-right">
															<span className="text-muted">Action</span>
															{/* <p className="text-muted"></p>  */}
														</th>
													</tr>
												</thead>
												<tbody>
													{orders.map((item) => (
														<tr key={item.cust} className="white-space-no-wrap">
															<td className="pr-0">
																<div className="custom-control custom-checkbox custom-control-inline">
																	<input
																		type="checkbox"
																		className="custom-control-input m-0"
																		id="customCheck1"
																	/>
																	<label
																		className="custom-control-label"
																		htmlFor="customCheck1"
																	></label>
																</div>
															</td>
															<td>{item.id}</td>
															<td>{item.name}</td>
															<td>{item.email}</td>
															<td className="text-right">{item.price}</td>
															<td>
																<p
																	className={`mb-0 ${item.color} font-weight-bold d-flex justify-content-start align-items-center`}
																>
																	<small>
																		<svg
																			className="mr-2"
																			xmlns="http://www.w3.org/2000/svg"
																			width="18"
																			viewBox="0 0 24 24"
																			fill="none"
																		>
																			<circle
																				cx="12"
																				cy="12"
																				r="8"
																				fill="#3cb72c"
																			></circle>
																		</svg>
																	</small>
																	{item.status}
																</p>
															</td>
															<td>
																<div className="d-flex justify-content-end align-items-center">
																	<OverlayTrigger
																		placement="top"
																		overlay={<Tooltip>View</Tooltip>}
																	>
																		<Link
																			className="mr-2"
																			to={`/order-view/${item.id}`}
																		>
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				className="text-secondary"
																				width="20"
																				fill="none"
																				viewBox="0 0 24 24"
																				stroke="currentColor"
																			>
																				<path
																					strokeLinecap="round"
																					strokeLinejoin="round"
																					strokeWidth="2"
																					d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
																				/>
																				<path
																					strokeLinecap="round"
																					strokeLinejoin="round"
																					strokeWidth="2"
																					d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
																				/>
																			</svg>
																		</Link>
																	</OverlayTrigger>
																	<OverlayTrigger
																		placement="top"
																		overlay={<Tooltip>Edit</Tooltip>}
																	>
																		<Link className="mr-2" to="#">
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				className="text-secondary"
																				width="20"
																				fill="none"
																				viewBox="0 0 24 24"
																				stroke="currentColor"
																			>
																				<path
																					strokeLinecap="round"
																					strokeLinejoin="round"
																					strokeWidth="2"
																					d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
																				/>
																			</svg>
																		</Link>
																	</OverlayTrigger>
																	<OverlayTrigger
																		placement="top"
																		overlay={<Tooltip>Delete</Tooltip>}
																	>
																		<Button
																			className="bg-danger border-0"
																			size="sm"
																			onClick={() =>
																				setDeleteModal({
																					id: item.id,
																					show: true,
																				})
																			}
																		>
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				width="20"
																				fill="none"
																				viewBox="0 0 24 24"
																				stroke="currentColor"
																			>
																				<path
																					strokeLinecap="round"
																					strokeLinejoin="round"
																					strokeWidth="2"
																					d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
																				/>
																			</svg>
																		</Button>
																	</OverlayTrigger>
																</div>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</>
	)
}
export default Order
