import { configureStore } from "@reduxjs/toolkit"
import darkModeReducer from "../features/darkModeSlice"
import logger from "redux-logger"
import { combineReducers } from "redux"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import authReducer from "../features/userAuth"

const reducers = combineReducers({
	darkMode: darkModeReducer,
	auth: authReducer,
})

const persistConfig = {
	key: "root",
	storage,
}

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
	reducer: persistedReducer,
	devTools: process.env.NODE_ENV !== "production",
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
})

// const store = configureStore({
// 	middleware:
// })
