import React, { useState, useEffect } from "react"
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	OverlayTrigger,
	Tooltip,
	InputGroup,
} from "react-bootstrap"
import Card from "../../../components/Card"
import { Link } from "react-router-dom"

// img
import product1 from "../../../assets/images/products/1.jpg"
import { db } from "../../../config"
import Modal from "../../../components/Modal"

const Product = () => {
	const [users, setUsers] = useState([])
	const [deleteModal, setDeleteModal] = useState(false)

	const fetchUsers = async () => {
		const response = db.collection("users")
		const data = await response.get()
		const usersArray = []
		data.docs.forEach((item) => {
			const data = item.data()
			data.id = item.id
			usersArray.push(data)
		})
		setUsers(usersArray)
	}

	const handleApproveUser = async (user) => {
		const response = db.collection("users").doc(user.id)
		await response.update({ approved: !user.approved }).then(async () => {
			const response = db.collection("users").doc(user.id)
			await response.get().then((item) => {
				const data = item.data()
				data.id = item.id
				const usersArray = [...users]
				usersArray.forEach((oldUser) =>
					oldUser.id === data.id ? (oldUser.approved = data.approved) : null,
				)
				setUsers(usersArray)
			})
		})
	}

	const deleteUser = (id) => {
		db.collection("users")
			.doc(id)
			.delete()
			.then(() => {
				const newArr = users.filter((user) => user.id !== id)
				setDeleteModal(false)
				setUsers(newArr)
			})
			.catch((error) => {
				console.error("Error removing document: ", error)
			})
	}

	useEffect(() => {
		fetchUsers()
	}, [])

	// const Products = [
	// 	{
	// 		Pname: "FivePlus Nord 5G",
	// 		des: "(12GB RAM, 256GB Storage)",
	// 		product: `${product12}`,
	// 		Cat: "Mobile",
	// 		Price: "$2,399.99",
	// 		Quantity: "80",
	// 		Inventory: "In Stock",
	// 		color: "text-success",
	// 	},
	// ]

	return (
		<>
			<Modal
				show={deleteModal}
				close={() => setDeleteModal(false)}
				confirmDelete={() => deleteUser(deleteModal.id)}
			/>
			<Container fluid>
				<Row>
					<Col lg="12">
						<div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
							<div className="d-flex align-items-center justify-content-between">
								<h4 className="font-weight-bold">Team</h4>
							</div>
						</div>

						<Row>
							<Col lg="12">
								<Card className="card-block card-stretch">
									<Card.Body className="p-0">
										<div className="d-flex justify-content-between align-items-center p-3">
											<h5 className="font-weight-bold">Team Members</h5>
										</div>
										<div className="table-responsive">
											<table className="table data-table mb-0">
												<thead className="table-color-heading">
													<tr className="text-light">
														<th scope="col">
															<label className="text-muted m-0">Name</label>
														</th>
														<th scope="col">
															<label className="text-muted mb-0">Email</label>
														</th>
														<th scope="col" className="text-center">
															<label className="text-muted mb-0">
																Approved
															</label>
														</th>
														<th scope="col" className="text-right">
															<span className="text-muted">Action</span>
														</th>
													</tr>
												</thead>
												<tbody>
													{users.map((user, index) => (
														<tr key={user.id} className="white-space-no-wrap">
															<td className="">
																<div className="active-project-1 d-flex align-items-center mt-0 ">
																	<div className="h-avatar is-medium">
																		<img
																			className="avatar rounded"
																			alt="user-icon"
																			style={{ objectFit: "cover" }}
																			src={user.image || product1}
																		/>
																	</div>
																	<div className="data-content">
																		<div>
																			<span className="font-weight-bold">
																				{user.name}
																			</span>
																		</div>
																	</div>
																</div>
															</td>
															<td>{user.email}</td>
															<td className="text-center">
																<p className="mb-0">
																	<small>
																		<svg
																			className="mr-2"
																			xmlns="http://www.w3.org/2000/svg"
																			width="18"
																			viewBox="0 0 24 24"
																			fill="none"
																		>
																			<circle
																				cx="12"
																				cy="12"
																				r="8"
																				fill={
																					user.approved ? "#3cb72c" : "#f42b3d"
																				}
																			></circle>
																		</svg>
																	</small>
																	{user.quantity > 0 ? "In Stock" : null}
																</p>
															</td>
															<td>
																<div className="d-flex justify-content-end">
																	<div className="custom-switch custom-control-inline">
																		<Form.Check.Input
																			bsPrefix="custom-control-input"
																			id={`customSwitch${index}`}
																			onClick={() => handleApproveUser(user)}
																			defaultChecked={user.approved}
																		/>
																		<Form.Check.Label
																			bsPrefix="custom-control-label"
																			htmlFor={`customSwitch${index}`}
																		></Form.Check.Label>
																	</div>
																	<OverlayTrigger
																		placement="top"
																		overlay={<Tooltip>Delete</Tooltip>}
																	>
																		<Button
																			className="bg-danger border-0"
																			size="sm"
																			onClick={() =>
																				setDeleteModal({
																					id: user.id,
																					show: true,
																				})
																			}
																		>
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				width="20"
																				fill="none"
																				viewBox="0 0 24 24"
																				stroke="currentColor"
																			>
																				<path
																					strokeLinecap="round"
																					strokeLinejoin="round"
																					strokeWidth="2"
																					d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
																				/>
																			</svg>
																		</Button>
																	</OverlayTrigger>
																</div>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</>
	)
}

export default Product
