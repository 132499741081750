import { useState } from "react"
import Dropdown from "react-bootstrap/Dropdown"

const DropDownMenuStyle = {
  overflowY: "scroll",
  maxHeight: "300px",
}

function noop() {}

function serializeId(option) {
  if (typeof option === "string") return option
  else if (typeof option === "object") return option.id
  else return "idk"
}

function serializeLabel(option) {
  if (typeof option === "string") return option
  else if (typeof option === "object") return option.name
  else return "idk"
}

function DropdownSelector({
  options,
  onSelect,
  variant,
  initial,
  toId,
  toLabel,
}) {
  const [selected, setSelected] = useState(initial)

  return (
    <Dropdown>
      <Dropdown.Toggle variant={variant}>{toLabel(selected)}</Dropdown.Toggle>

      <Dropdown.Menu style={DropDownMenuStyle}>
        {options.map((option) => {
          return (
            <Dropdown.Item
              key={toId(option)}
              onClick={() => {
                onSelect(option)
                setSelected(option)
              }}
            >
              {toLabel(option)}
            </Dropdown.Item>
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  )
}

DropdownSelector.defaultProps = {
  options: [],
  onSelect: noop,
  variant: "",
  initial: "Select from dropdown",
  toId: serializeId,
  toLabel: serializeLabel,
}

export default DropdownSelector
