import React, { useState, useEffect, useRef } from "react"
import { Container, Row, Col, Button, Tab, Nav, Form } from "react-bootstrap"
import Card from "../../../components/Card"
import { Link, useParams } from "react-router-dom"
import { db } from "../../../config"
import { useReactToPrint } from "react-to-print"
import QRCode from "qrcode.react"
// import "./MinerLabel.css"
import Html from "react-pdf-html"

import user1 from "../../../assets/images/user/1.jpg"

const MinerLabel = () => {
	const [farmName, setFarmName] = useState("")
	const [farmNumber, setFarmNumber] = useState("")
	const [workerNumber, setWorkerNumber] = useState("W")

	class NameToPrint extends React.PureComponent {
		render() {
			return (
				<div style={{ width: "500px", textAlign: "center" }}>
					<div className="print_label">
						<p
							style={{
								fontSize: "80px",
								margin: 0,
								borderBottom: "2px solid #000",
							}}
						>
							{farmName}
						</p>
						<p
							style={{
								fontSize: "150px",
								margin: 0,
								borderBottom: "2px solid #000",
							}}
						>
							{farmNumber}
						</p>
						<p style={{ fontSize: "70px", margin: 0 }}>{workerNumber}</p>
					</div>
				</div>
			)
		}
	}

	const componentRef = useRef()

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	})

	return (
		<>
			<Container fluid>
				<Row>
					<Col lg="12">
						<div className="d-flex flex-wrap align-items-center justify-content-between">
							<div className="d-flex align-items-center justify-content-between">
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb p-0 mb-0">
										<li className="breadcrumb-item">
											<Link to="/miner">Miners</Link>
										</li>
										<li className="breadcrumb-item active" aria-current="page">
											Miner View
										</li>
									</ol>
								</nav>
							</div>
							<Link
								to="/miner"
								className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="20"
									viewBox="0 0 20 20"
									fill="currentColor"
								>
									<path
										fillRule="evenodd"
										d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
										clipRule="evenodd"
									/>
								</svg>
								<span className="ml-2">Back</span>
							</Link>
						</div>
					</Col>
					<Col lg="12" className="mb-3 d-flex justify-content-between">
						<h4 className="font-weight-bold d-flex align-items-center">
							Miner View
						</h4>
					</Col>
				</Row>

				<Row>
					<Col lg="12">
						<Card>
							<Card.Body className="p-0">
								<Tab.Container defaultActiveKey="first">
									<Nav as="ul" variant="nav tab-nav-pane nav-tabs pt-2 mb-0">
										<Nav.Item as="li" className="pb-2 mb-0 nav-item">
											<Nav.Link
												data-toggle="tab"
												eventKey="first"
												bsPrefix="font-weight-bold text-uppercase px-5 py-2 "
											>
												Invoices
											</Nav.Link>
										</Nav.Item>
									</Nav>
									<Tab.Content>
										<Tab.Pane eventKey="first" className="fade show">
											<div className="d-flex justify-content-between align-items-center p-3">
												<div className="d-flex flex-column">
													<div style={{ display: "none" }}>
														<NameToPrint ref={componentRef} />
													</div>
												</div>
												<div className="d-flex">
													<Button
														variant="btn btn-secondary btn-sm"
														onClick={() => handlePrint()}
													>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															className="mr-1"
															width="20"
															fill="none"
															viewBox="0 0 24 24"
															stroke="currentColor"
														>
															<path
																strokeLinecap="round"
																strokeLinejoin="round"
																strokeWidth="2"
																d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
															/>
														</svg>
														QR Code
													</Button>
												</div>
											</div>
											<div className="col-md-6 mb-3">
												<Form.Label
													htmlFor="Text1"
													className="font-weight-bold text-muted text-uppercase"
												>
													Farm Name
												</Form.Label>
												<Form.Control
													type="text"
													id="Text1"
													placeholder="Enter Farm Name"
													value={farmName}
													onChange={(e) => setFarmName(e.target.value)}
												/>
											</div>
											<div className="col-md-6 mb-3">
												<Form.Label
													htmlFor="Text1"
													className="font-weight-bold text-muted text-uppercase"
												>
													Farm Number
												</Form.Label>
												<Form.Control
													type="text"
													id="Text1"
													placeholder="Enter Farm Number"
													value={farmNumber}
													onChange={(e) => setFarmNumber(e.target.value)}
												/>
											</div>
											<div className="col-md-6 mb-3">
												<Form.Label
													htmlFor="Text1"
													className="font-weight-bold text-muted text-uppercase"
												>
													Worker Number
												</Form.Label>
												<Form.Control
													type="text"
													id="Text1"
													placeholder="Enter Worker Number"
													value={workerNumber}
													onChange={(e) => setWorkerNumber(e.target.value)}
												/>
											</div>
										</Tab.Pane>
									</Tab.Content>
								</Tab.Container>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	)
}
export default MinerLabel
