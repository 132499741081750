import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import reportWebVitals from "./reportWebVitals"
import { PersistGate } from "redux-persist/integration/react"
import { persistStore } from "redux-persist"
import "bootstrap/dist/css/bootstrap.min.css"
import "./assets/scss/backend.scss"
import "./assets/css/custom.css"
// import firebase from "firebase/app"

import { Provider } from "react-redux"
//reducer
import { store } from "./store"
//store
// import { firebaseConfig } from "./config"
let persistor = persistStore(store)
// firebase.initializeApp(firebaseConfig)

ReactDOM.render(
	<React.Fragment>
		<BrowserRouter basename={process.env.PUBLIC_URL}>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<App />
				</PersistGate>
			</Provider>
		</BrowserRouter>
	</React.Fragment>,
	document.getElementById("root"),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
