import { useContext } from "react"
import { FarmContext } from "../contexts"
import BasicTooltip from "./BasicTooltip"

const OnlineTooltip = (
  <>
    Shows how <b>online</b> workers currently there are, and how many has
    recently went <b>offline</b>.
  </>
)

const TotalTooltip = (
  <>
    Total workers count.
  </>
)

const WorkersCount = () => {
  const { farm } = useContext(FarmContext)

  const {
    workers_total: total,
    workers_online: online,
    workers_offline: offline,
  } = farm.data.stats

  return (
    <>
      <div className="property">
        <BasicTooltip text={OnlineTooltip}>
          <div className="value">
            <span>{online}</span>
            {offline > 0 && <span className="bad">{offline}</span>}
          </div>
          <div className="label">Online</div>
        </BasicTooltip>
      </div>
      <div className="property">
        <BasicTooltip text={TotalTooltip}>
          <div className="value">
            <span>{total}</span>
          </div>
          <div className="label">Workers</div>
        </BasicTooltip>
      </div>
    </>
  )
}

export default WorkersCount
