import React from "react"

// Partials
import HeaderStyle1 from "../../components/partials/backend/HeaderStyle/HeaderStyle1"
import {
	AdminSidebar,
	ManagerSidebar,
	TechSidebar,
} from "../../components/partials/backend/SidebarStyle"
import FooterStyle from "../../components/partials/backend/FooterStyle/FooterStyle"

// Router Component
import Layout1Route from "../../router/layout1-route"
import { useSelector } from "react-redux"

const Layout1 = () => {
	const role = useSelector((state) => state.auth.role)
	const Sidebar = () => {
		switch (role) {
			case "admin":
				return <AdminSidebar />
			case "manager":
				return <ManagerSidebar />
			case "tech":
				return <TechSidebar />
			default:
				return <></>
		}
	}

	return (
		<>
			<div className="wrapper">
				<HeaderStyle1 />
				<Sidebar />
				<div className="content-page">
					<Layout1Route role={role} />
				</div>
			</div>
			<FooterStyle />
		</>
	)
}

export default Layout1
