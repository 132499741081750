import { useContext } from "react"
import { FarmContext } from "../contexts"
import BasicTooltip from "./BasicTooltip"

const rates = [
  {
    power: Math.pow(10, 9),
    unit: "TH/s",
  },
  {
    power: Math.pow(10, 6),
    unit: "GH/s",
  },
  {
    power: Math.pow(10, 3),
    unit: "MH/s",
  },
  {
    power: 1,
    unit: "H/s",
  },
]

const parse_hashrate = (hashrate, precision = 1) => {
  let rate = 0

  while (rates[rate].power > hashrate) {
    if (rates[rate + 1] === undefined) break
    rate++
  }

  return (
    (hashrate / rates[rate].power).toFixed(precision) + " " + rates[rate].unit
  )
}

const HashratesBar = ({}) => {
  const { farm } = useContext(FarmContext)
  const { hashrates_by_coin } = farm.data

  if (
    hashrates_by_coin === null ||
    hashrates_by_coin === undefined ||
    hashrates_by_coin.length === 0
  )
    return <></>

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {hashrates_by_coin.map(({ coin, hashrate }) => (
        <BasicTooltip key={coin} text={coin}>
          <div style={{ display: "flex", alignItems: "center", marginRight: '5px' }}>
            <img
              src={`https://the.hiveos.farm/icons/${coin.toLowerCase()}.png`}
              height="18px"
            />
            {parse_hashrate(hashrate)}
          </div>
        </BasicTooltip>
      ))}
    </div>
  )
}

export default HashratesBar
