import { useState, useEffect } from "react"

import { database } from "../../../../config"

/**
 * @template {T}
 * @param {firebase.database.Reference|string} reference Value reference in realtime database or reference name
 * @param {T} initial Initial value if value in database is missing
 * @returns {[() => T, (newValue: T) => void]}
 */
function useStateRealtimeReference(reference, initial = undefined) {
  if (typeof reference === "string") reference = database.ref(reference)

  // Native state variables
  const [getter, setter] = useState(initial)

  // When database data changes call setter
  const update = (snapshot) => setter(snapshot.val() || initial)

  // Listen to database changes
  useEffect(() => {
    reference.on("value", update)
  }, [])

  // Catch setter
  const setterWrap = (value) =>
    // Update in database
    reference.set(value, () =>
      // Pass to real setter
      setter(value)
    )

  // Return as if an original
  return [getter, setterWrap]
}

export default useStateRealtimeReference
