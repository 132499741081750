import { useEffect, useState } from "react"
import { database } from "../../../../config"
import { DropdownSelector } from "../Components"

const Selector = ({ type }) => {
  const [options, setOptions] = useState([])

  const parse = (data) =>
    Object.keys(data).map((id) => ({ id, name: data[id] }))

  useEffect(() => {
    if (type === undefined) return

    let isMounted = true

    database.ref("/database/index/" + type).once("value", (snapshot) => {
      if (isMounted !== true) return
      const data = snapshot.val()

      if (data !== null) setOptions(parse(data))
    })

    return () => {
      isMounted = false
    }
  }, [type])

  return (
    <DropdownSelector
      options={options}
      variant="outline-secondary"
    ></DropdownSelector>
  )
}

export { Selector }
