import { useEffect, useState } from "react"
import { SmartAccountsAPI } from "../../../../../config"
import { ClientContext, SmartAccountsContext } from "../contexts"

const Clients = ({ children }) => {
  const [clients, setClients] = useState([])

  useEffect(() => {
    let mounted = true

    SmartAccountsAPI.request("purchasesales/clients", "get").then((response) =>
      setClients(response.clients)
    )

    return () => {
      mounted = false
    }
  }, [])

  return (
    <>
      {clients.map((client) => (
        <ClientContext.Provider key={client.id} value={client}>
          {children}
        </ClientContext.Provider>
      ))}
    </>
  )
}

Clients.defaultProps = {
  children: [],
}

export default Clients
