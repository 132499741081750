import React, { useEffect, useState } from "react"
import AsyncBoard from "react-trello"
// import { useHistory } from "react-router-dom"
import { db } from "../../../config"

const Todo = () => {
	const [todo, setTodo] = useState({ lanes: [] })
	// const history = useHistory()

	const admin = false

	const fetchTodo = async () => {
		const response = db.collection("todo")
		const data = await response.get()
		let todoArray = []

		// 	lanes: [
		// 		{
		// 			cards: [
		// 				{
		// 					description: "Transfer to bank account",
		// 					id: "Card1",
		// 					label: "2017-09-01",
		// 					laneId: "SORTED_LANE",
		// 					metadata: {
		// 						completedAt: "2017-09-01T10:00:00Z",
		// 						shortCode: "ga2",
		// 					},
		// 					title: "Pay Rent",
		// 				},
		// 			],
		// 			currentPage: 1,
		// 			id: "SORTED_LANE1",
		// 			label: "20/70",
		// 			title: "Sorted Lane",
		// 		},
		// 	],
		// }

		data.docs.forEach((item) => {
			const data = item.data()
			todoArray = data
		})
		setTodo(todoArray)
	}

	useEffect(() => {
		fetchTodo()
	}, [])

	return (
		<AsyncBoard
			style={{
				backgroundColor: "transparent",
			}}
			data={todo}
			draggable
			editable={admin ? true : false}
			t={(ee) => console.log(ee, "t")}
			onCardClick={(ee) => console.log(ee, "card")}
			onLaneClick={(ee) => console.log(ee, "lane")}
			handleDragEnd={(
				cardId,
				sourceLaneId,
				targetLaneId,
				position,
				cardDetails,
			) =>
				console.log(cardId, sourceLaneId, targetLaneId, position, cardDetails)
			}
		/>
		// <Board data={data} draggable />
	)
}

export default Todo
