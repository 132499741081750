import React, { useState, useEffect } from "react"
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	// OverlayTrigger,
	// Tooltip,
} from "react-bootstrap"
import Card from "../../../components/Card"
import { Link } from "react-router-dom"

// img
// import product1 from "../../../assets/images/products/1.jpg"
import { db } from "../../../config"
// import Modal from "../../../components/Modal"

const Product = () => {
	const [stock, setStock] = useState([])
	// const [deleteModal, setDeleteModal] = useState(false)

	const fetchStock = async () => {
		const productsArray = []
		const response = db.collection("stock").doc("17MWR3iYsrBYLXcff0LY")

		await response.get().then((item) =>
			item.data().stock.forEach((items) => {
				productsArray.push(items)
			}),
		)

		productsArray.sort((a, b) =>
			a.product.localeCompare(
				b.product,
				navigator.languages[0] || navigator.language,
				{
					numeric: true,
					ignorePunctuation: true,
				},
			),
		)

		setStock(productsArray)
	}

	// const deleteProduct = (id) => {
	// 	db.collection("products")
	// 		.doc(id)
	// 		.delete()
	// 		.then(() => {
	// 			const newArr = products.filter((product) => product.id !== id)
	// 			setDeleteModal(false)
	// 			setProducts(newArr)
	// 		})
	// 		.catch((error) => {
	// 			console.error("Error removing document: ", error)
	// 		})
	// }

	useEffect(() => {
		fetchStock()
	}, [])

	// const Products = [
	// 	{
	// 		Pname: "FivePlus Nord 5G",
	// 		des: "(12GB RAM, 256GB Storage)",
	// 		product: `${product12}`,
	// 		Cat: "Mobile",
	// 		Price: "$2,399.99",
	// 		Quantity: "80",
	// 		Inventory: "In Stock",
	// 		color: "text-success",
	// 	},
	// ]

	return (
		<>
			{/* <Modal
				show={deleteModal}
				close={() => setDeleteModal(false)}
				confirmDelete={() => deleteProduct(deleteModal.id)}
			/> */}
			<Container fluid>
				<Row>
					<Col lg="12">
						<div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
							<div className="d-flex align-items-center justify-content-between">
								<h4 className="font-weight-bold">Product</h4>
							</div>
							<div className="create-workform">
								<div className="d-flex flex-wrap align-items-center justify-content-between">
									<div className="modal-product-search d-flex">
										<Form className="mr-3 position-relative">
											<div className="form-group mb-0">
												<Form.Control
													type="text"
													className="form-control"
													id="exampleInputText"
													placeholder="Search Product"
												/>
												<Link className="search-link" to="#">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														className=""
														width="20"
														fill="none"
														viewBox="0 0 24 24"
														stroke="currentColor"
													>
														<path
															strokeLinecap="round"
															strokeLinejoin="round"
															strokeWidth="2"
															d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
														/>
													</svg>
												</Link>
											</div>
										</Form>
										<Link
											to="/product-add"
											className="btn btn-primary position-relative d-flex align-items-center justify-content-between"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												className="mr-2"
												width="20"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth="2"
													d="M12 6v6m0 0v6m0-6h6m-6 0H6"
												/>
											</svg>
											Add Product
										</Link>
									</div>
								</div>
							</div>
						</div>

						<Row>
							<Col lg="12">
								<Card className="card-block card-stretch">
									<Card.Body className="p-0">
										<div className="d-flex justify-content-between align-items-center p-3">
											<h5 className="font-weight-bold">Products List</h5>
											<Button variant="btn btn-secondary btn-sm">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													className="mr-1"
													width="20"
													fill="none"
													viewBox="0 0 24 24"
													stroke="currentColor"
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth="2"
														d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
													/>
												</svg>
												Export
											</Button>
										</div>
										<div className="table-responsive">
											<table className="table data-table mb-0">
												<thead className="table-color-heading">
													<tr className="text-light">
														<th scope="col">
															<label className="text-muted m-0">
																Product Name
															</label>
														</th>
														{/* <th scope="col">
															<label className="text-muted mb-0">
																Category
															</label>
														</th>
														<th scope="col" className="text-right">
															<label className="text-muted mb-0">Price</label>
														</th> */}
														<th scope="col">
															<label className="text-muted mb-0">
																Quantity
															</label>
														</th>
														<th scope="col">
															<label className="text-muted mb-0">
																Inventory
															</label>
														</th>
														{/* <th scope="col" className="text-right">
															<span className="text-muted">Action</span>
														</th> */}
													</tr>
												</thead>
												<tbody>
													{stock.map((item) => (
														<tr key={item.slug} className="white-space-no-wrap">
															<td>
																<div>
																	<span className="font-weight-bold">
																		{item.product}
																	</span>
																</div>
																{/* <p className="m-0 mt-1">
																	{product.description}
																</p> */}
															</td>
															{/* <td>{product.category}</td> */}
															{/* <td className="text-right">{product.price}</td> */}
															<td>{item.quantity}</td>
															<td>
																<p
																	className={`mb-0 text-success font-weight-bold d-flex justify-content-start align-items-center`}
																>
																	<small>
																		<svg
																			className="mr-2"
																			xmlns="http://www.w3.org/2000/svg"
																			width="18"
																			viewBox="0 0 24 24"
																			fill="none"
																		>
																			<circle
																				cx="12"
																				cy="12"
																				r="8"
																				fill="#3cb72c"
																			></circle>
																		</svg>
																	</small>
																	{item.quantity > 0 ? "In Stock" : null}
																</p>
															</td>
															{/* <td>
																<div className="d-flex justify-content-end align-items-center">
																	<OverlayTrigger
																		placement="top"
																		overlay={<Tooltip>Delete</Tooltip>}
																	>
																		<Button
																			className="bg-danger border-0"
																			size="sm"
																			onClick={() =>
																				setDeleteModal({
																					id: product.id,
																					show: true,
																				})
																			}
																		>
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				width="20"
																				fill="none"
																				viewBox="0 0 24 24"
																				stroke="currentColor"
																			>
																				<path
																					strokeLinecap="round"
																					strokeLinejoin="round"
																					strokeWidth="2"
																					d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
																				/>
																			</svg>
																		</Button>
																	</OverlayTrigger>
																</div>
															</td> */}
														</tr>
													))}
												</tbody>
											</table>
										</div>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</>
	)
}

export default Product
