import React from "react"
import { Route, Redirect } from "react-router-dom"
import { useSelector } from "react-redux"

import { isUserAuthenticatedSelector } from "../selectors/AuthSelector"

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
	const authenticated = useSelector(isUserAuthenticatedSelector)

	return (
		<Route
			{...rest}
			render={(props) =>
				authenticated && restricted ? (
					<Redirect to="/" />
				) : (
					<Component {...props} />
				)
			}
		/>
	)
}

export default PublicRoute
