import React, { useState, useRef } from "react"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import Card from "../../../components/Card"
import { Link, useHistory } from "react-router-dom"
import { Typeahead } from "react-bootstrap-typeahead"

//datepicker
import Datepickers from "../../../components/Datepicker"
import { db, storage } from "../../../config"

const HotelAdd = () => {
	const [customer, setCustomer] = useState("")
	const [miner, setMiner] = useState("")
	const [description, setDescription] = useState("")
	const [hangar, setHangar] = useState("")
	const [customerSearchResult, setCustomerSearchResult] = useState([])
	const [minerSearchResult, setMinerSearchResult] = useState([])

	const inputFile = useRef(null)

	const history = useHistory()

	const handleAddCustomer = async (e) => {
		e.preventDefault()
		await db
			.collection("hotel")
			.add({
				customer: customer,
				miner: miner,
				description: description,
				hangar: hangar,
				created: Date.now(),
			})
			.then(history.push("/hotel"))
	}

	const searchClient = async (value) => {
		if (value.length > 2) {
			const response = db.collection("customers")
			const data = await response.get()
			const customersArray = []
			const searchQuery = value.toLowerCase()
			data.docs.forEach((item) => {
				const data = item.data()
				const customerName = data.fullName.toLowerCase()
				if (
					customerName.slice(0, searchQuery.length).indexOf(searchQuery) !== -1
				) {
					data.id = item.id
					data.label = data.fullName
					customersArray.push(data)
				}
			})
			setCustomerSearchResult(customersArray)
		}
	}
	const searchMiner = async (value) => {
		if (value.length > 2) {
			const response = db.collection("miners")
			const data = await response.get()
			const minersArray = []
			const searchQuery = value.toLowerCase()
			data.docs.forEach((item) => {
				const data = item.data()
				const minerName = data.minerName.toLowerCase()
				if (
					minerName.slice(0, searchQuery.length).indexOf(searchQuery) !== -1
				) {
					data.id = item.id
					data.label = data.minerName
					minersArray.push(data)
				}
			})
			minersArray.sort((a, b) =>
				a.minerName.localeCompare(
					b.minerName,
					navigator.languages[0] || navigator.language,
					{
						numeric: true,
						ignorePunctuation: true,
					},
				),
			)
			setMinerSearchResult(minersArray)
		}
	}

	const handleSetCustomerData = (data) => {
		if (data && data.length !== 0) {
			setCustomer(data.id)
		}
	}

	const handleSetMinerData = (data) => {
		if (data && data.length !== 0) {
			setMiner(data.id)
		}
	}

	return (
		<>
			<Container fluid>
				<Row>
					<Col lg="12">
						<div className="d-flex flex-wrap align-items-center justify-content-between">
							<div className="d-flex align-items-center justify-content-between">
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb p-0 mb-0">
										<li className="breadcrumb-item">
											<Link to="/hotel">Hotel</Link>
										</li>
										<li className="breadcrumb-item active" aria-current="page">
											Add Hotel
										</li>
									</ol>
								</nav>
							</div>
							<Link
								to="/hotel"
								className="btn btn-primary btn-sm d-flex align-items-center justify-content-between"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="20"
									viewBox="0 0 20 20"
									fill="currentColor"
								>
									<path
										fillRule="evenodd"
										d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
										clipRule="evenodd"
									/>
								</svg>
								<span className="ml-2">Back</span>
							</Link>
						</div>
					</Col>
					<Col lg="12" className="mb-3 d-flex justify-content-between">
						<h4 className="font-weight-bold0 d-flex align-items-center">
							Add New Rig
						</h4>
					</Col>
					<Col lg="12">
						<Card>
							<Card.Body>
								<Row>
									<Col md="12">
										<Form className="row g-3 date-icon-set-modal">
											<div className="col-md-6 mb-3">
												<Form.Label
													htmlFor="Text1"
													className="form-label font-weight-bold text-muted text-uppercase"
												>
													Customer
												</Form.Label>
												<Form.Group>
													<Typeahead
														placeholder="Enter Customer"
														onInputChange={(e) => {
															searchClient(e)
														}}
														onChange={(value) =>
															handleSetCustomerData(value[0])
														}
														options={customerSearchResult}
													/>
												</Form.Group>
											</div>
											<div className="col-md-6 mb-3">
												<Form.Label
													htmlFor="Text1"
													className="form-label font-weight-bold text-muted text-uppercase"
												>
													Miner
												</Form.Label>
												<Form.Group>
													<Typeahead
														placeholder="Select Miner"
														onInputChange={(e) => {
															searchMiner(e)
														}}
														onChange={(value) => handleSetMinerData(value[0])}
														options={minerSearchResult}
													/>
												</Form.Group>
											</div>
											<div className="col-md-6 mb-3">
												<Form.Label
													htmlFor="inputcountry"
													className="font-weight-bold text-muted text-uppercase"
												>
													Hangar
												</Form.Label>
												<select
													defaultValue=""
													id="inputcountry"
													className="form-select form-control"
													onChange={(e) => setHangar(e.target.value)}
												>
													<option value="hangar1">Hangar 1</option>
													<option value="hangar2">Hangar 2</option>
												</select>
											</div>
											<div className="col-md-12 mb-3">
												<Form.Label
													htmlFor="Text9"
													className="font-weight-bold text-muted text-uppercase"
												>
													Description
												</Form.Label>
												<Form.Control
													as="textarea"
													id="Text9"
													rows="2"
													placeholder="Enter Description"
													onChange={(e) => setDescription(e.target.value)}
												/>
											</div>
										</Form>
										<div className="d-flex justify-content-end mt-3">
											<Button
												variant="btn btn-primary"
												onClick={(e) => {
													handleAddCustomer(e)
												}}
											>
												Add Rig
											</Button>
										</div>
									</Col>
								</Row>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	)
}

export default HotelAdd
