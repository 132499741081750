//router
import LayoutsRoute from "./router/layouts-route"
import classnames from "classnames"
import { useSelector } from "react-redux"
import React from 'react'
//scss files


function App() {
	const darkMode = useSelector((state) => state.darkMode.darkMode)

	return (
		<div className={classnames("App", darkMode ? "dark" : null)}>
			<LayoutsRoute />
		</div>
	)
}

export default App
