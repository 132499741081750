import { useContext, useEffect, useState } from "react"
import {
	Button,
	ButtonGroup,
	Col,
	Container,
	Row,
	Card as Skeleton,
	Placeholder,
} from "react-bootstrap"

import Card from "../../../components/Card"
import { database, hiveapi } from "../../../config"
import {
	BasicTooltip,
	ConsumptionCommander,
	FarmPreview,
	Navigator,
} from "./Components"

import "./styles.css"
import { Danger } from "./Components/Alert"

import { HQContext, FarmContext } from "./contexts"

const filters = {
	farm: {
		offline: (farm) => farm.data.stats.workers_offline > 0,
		offline_all: (farm) =>
			farm.data.stats.workers_total > farm.data.stats.workers_online,
	},
}

const useFilters = () => {
	const [table, setTable] = useState([])

	const toggle = (cb) => (enabled(cb) ? remove(cb) : add(cb))
	const enabled = (cb) => table.includes(cb) === true
	const add = (cb) => setTable([...table, cb])
	const remove = (cb) =>
		table.indexOf(cb) !== -1 &&
		setTable([
			...table.slice(0, table.indexOf(cb)),
			...table.slice(table.indexOf(cb) + 1, table.length),
		])
	const filter = (subject) =>
		table.length === 0
			? true
			: table.map((cb) => cb(subject)).includes(false) !== true

	return {
		add,
		remove,
		toggle,
		filter,
		enabled,
	}
}

const FilterToggle = ({ variant, cb, name, tooltip }) => {
	const { filter } = useContext(HQContext)

	return (
		<Button
			variant={filter.enabled(cb) ? variant : "outline-" + variant}
			onClick={() => filter.toggle(cb)}
		>
			<BasicTooltip text={tooltip}>{name}</BasicTooltip>
		</Button>
	)
}

FilterToggle.defaultProps = {
	variant: "secondary",
	name: "Filter",
	cb: () => {},
	tooltip: undefined,
}

const UI = () => {
	const [farms, setFarms] = useState([])
	const [profiles, setProfiles] = useState({})
	const [error, setError] = useState(null)
	const [consumptionFarm, setConsumptionFarm] = useState(null)
	const filter = useFilters()
	console.log(farms)
	useEffect(() => {
		database
			.ref("/profile")
			.on("value", (snapshot) => setProfiles(snapshot.val() || {}))
	}, [])

	useEffect(() => {
		let mounted = true

		hiveapi.farms
			.all()
			.catch((e) => {
				if (mounted === true) setError(e)
			})
			.then((f) => {
				if (mounted === true && f !== undefined) setFarms(f)
			})

		return () => {
			mounted = false
		}
	}, [])

	const hideConsumption = () => setConsumptionFarm(null)

	const context = {
		profiles,
		consumption: {
			show: setConsumptionFarm,
			hide: hideConsumption,
		},
		filter,
	}

	return (
		<HQContext.Provider value={context}>
			{/* <WorkersManager farm={selectedFarm} setFarm={setSelectedFarm} /> */}
			<ConsumptionCommander farm={consumptionFarm} hide={hideConsumption} />
			<Container fluid style={{ minHeight: 500 }} className="hq">
				<Danger error={error} />
				<Row>
					<Col lg="12" sm="12">
						<Card>
							<Card.Header>
								<Navigator />
								<Card.Header.Action>
									<ButtonGroup>
										<FilterToggle
											cb={filters.farm.offline}
											name="Offline < 24h"
											tooltip={
												<>
													Filter workers that went offline in the
													<b>last 24 hours</b>.
												</>
											}
										/>
										<FilterToggle
											cb={filters.farm.offline_all}
											name="Offline"
										/>
									</ButtonGroup>
								</Card.Header.Action>
							</Card.Header>
							<Card.Body className="hq-card-body">
								<Row className="farms">
									{farms.length > 0
										? farms.map((farm) => (
												<FarmContext.Provider value={{ farm }} key={farm.id}>
													<FarmPreview farm={farm} profiles={profiles} />
												</FarmContext.Provider>
										  ))
										: Array.apply(null, { length: 9 }).map(() => (
												<Skeleton
													style={{
														flexBasis: "32.9%",
														height: "245px",
														marginBottom: "9px",
														backgroundColor: "#f3f3f3",
													}}
													className="rounded-0 content"
												>
													<Skeleton.Body
														className="d-flex flex-column"
														style={{ padding: "5px 0" }}
													>
														<Placeholder
															as={Card.Title}
															animation="glow"
															className="mb-4 d-block"
														>
															<Placeholder xs={6} className="rounded" />
														</Placeholder>
														<Placeholder
															as={Card.Text}
															animation="glow"
															className="mb-4 d-block"
														>
															<Placeholder
																className="rounded"
																xs={2}
																style={{
																	marginRight: "10px",
																	minHeight: "45px",
																}}
															/>
															<Placeholder
																className="rounded"
																xs={2}
																style={{
																	marginRight: "10px",
																	minHeight: "45px",
																}}
															/>
															<Placeholder
																className="rounded"
																xs={2}
																style={{
																	marginRight: "10px",
																	minHeight: "45px",
																}}
															/>
															<Placeholder
																className="rounded"
																xs={2}
																style={{
																	marginRight: "10px",
																	minHeight: "45px",
																}}
															/>
														</Placeholder>
														<Placeholder
															as={Card.Text}
															animation="glow"
															className="mt-auto d-block"
														>
															<Placeholder
																xs={8}
																className="rounded"
																style={{ marginTop: "auto" }}
															/>
														</Placeholder>
													</Skeleton.Body>
												</Skeleton>
										  ))}
								</Row>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</HQContext.Provider>
	)
}

export { UI }
