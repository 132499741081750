import React, { useEffect, useState } from "react"
import {
	Container,
	Row,
	Col,
	Button,
	Form,
	OverlayTrigger,
	Tooltip,
} from "react-bootstrap"
import Card from "../../../components/Card"
import { Link } from "react-router-dom"
import { db } from "../../../config"

// img
import user2 from "../../../assets/images/user/2.jpg"

const Invoices = [
	{
		user: `${user2}`,
		name: "Jack McMullen",
		email: "jackmullen@blueberry.com",
		date: "12 Jan 2021",
		id: "IN-302240",
		total: "$25.99",
		status: "Paid",
		color: "text-success",
	},
]
const Hotel = () => {
	const [invoices, setInvoices] = useState([])

	const fetchHotel = async () => {
		const response = db.collection("hotel")
		const data = await response.get()
		const hotelArray = []
		data.docs.forEach((item) => {
			const data = item.data()
			data.id = item.id
			hotelArray.push(data)
		})
		// hotelArray.sort((a, b) =>
		// 	a.minerName.localeCompare(
		// 		b.minerName,
		// 		navigator.languages[0] || navigator.language,
		// 		{
		// 			numeric: true,
		// 			ignorePunctuation: true,
		// 		},
		// 	),
		// )
		setInvoices(hotelArray)
	}

	useEffect(() => {
		fetchHotel()
	}, [])
	return (
		<>
			<Container fluid>
				<Row>
					<Col lg="12">
						<div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
							<div className="d-flex align-items-center justify-content-between">
								<h4 className="font-weight-bold">All Rigs</h4>
							</div>
							<div className="create-workform">
								<div className="d-flex flex-wrap align-items-center justify-content-between">
									<div className="modal-product-search d-flex">
										<Form className="mr-3 position-relative">
											<Form.Group className="mb-0">
												<Form.Control
													type="text"
													className="form-control"
													id="exampleInputText"
													placeholder="Search Rig"
												/>
												<Link className="search-link" to="#">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														className=""
														width="20"
														fill="none"
														viewBox="0 0 24 24"
														stroke="currentColor"
													>
														<path
															strokeLinecap="round"
															strokeLinejoin="round"
															strokeWidth="2"
															d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
														/>
													</svg>
												</Link>
											</Form.Group>
										</Form>
										<Link
											to="/hotel-add"
											className="btn btn-primary position-relative d-flex align-items-center justify-content-between"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												className="mr-2"
												width="20"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth="2"
													d="M12 6v6m0 0v6m0-6h6m-6 0H6"
												/>
											</svg>
											Add Rig
										</Link>
									</div>
								</div>
							</div>
						</div>

						<Row>
							<Col lg="12">
								<Card className="card-block card-stretch">
									<Card.Body className="p-0">
										<div className="d-flex justify-content-between align-items-center p-3">
											<h5 className="font-weight-bold">Rig List</h5>
											<Button variant="btn btn-secondary btn-sm">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													className="mr-1"
													width="20"
													fill="none"
													viewBox="0 0 24 24"
													stroke="currentColor"
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth="2"
														d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
													/>
												</svg>
												Export
											</Button>
										</div>
										<div className="table-responsive">
											<table className="table data-table mb-0">
												<thead className="table-color-heading">
													<tr className="text-light">
														<th scope="col" className="pr-0 w-01">
															<div className="d-flex justify-content-start align-items-end mb-1">
																<div className="custom-control custom-checkbox custom-control-inline">
																	<input
																		type="checkbox"
																		className="custom-control-input m-0"
																		id="customCheck"
																	/>
																	<label
																		className="custom-control-label"
																		htmlFor="customCheck"
																	></label>
																</div>
															</div>
														</th>
														<th scope="col">
															<label className="text-muted m-0">Customer</label>
															{/* <input type="text" className="form-control mt-2" id="text1" aria-describedby="textHelp" placeholder="Customer"> */}
														</th>
														<th scope="col" className="dates">
															<label className="text-muted mb-0">
																Entry Date
															</label>
															{/* <input type="text" className="form-control" id="usr1" name="event_date" placeholder="Enter Date" autocomplete="off" aria-describedby="basic-addon2"> */}
														</th>
														<th scope="col">
															<label className="text-muted mb-0">
																Amount of Rigs
															</label>
															{/* <input type="text" className="form-control mt-2" id="text2" aria-describedby="textHelp" placeholder="ID"> */}
														</th>

														<th scope="col" className="text-right">
															<span className="text-muted">Action</span>
															{/* <p className="text-muted"></p> */}
														</th>
													</tr>
												</thead>
												<tbody>
													{invoices &&
														invoices.map((invoice) => (
															<tr
																key={invoice.name}
																className="white-space-no-wrap"
															>
																<td className="pr-0">
																	<div className="custom-control custom-checkbox custom-control-inline">
																		<input
																			type="checkbox"
																			className="custom-control-input m-0"
																			id="customCheck1"
																		/>
																		<label
																			className="custom-control-label"
																			htmlFor="customCheck1"
																		></label>
																	</div>
																</td>
																<td className="">
																	<div className="active-project-1 d-flex align-items-center mt-0 ">
																		<div className="h-avatar is-medium">
																			<img
																				className="avatar rounded-circle"
																				alt="user-icon"
																				src={invoice.user}
																			/>
																		</div>
																		<div className="data-content">
																			<div>
																				<span className="font-weight-bold">
																					{invoice.name}
																				</span>
																			</div>
																			<p className="m-0 text-secondary small">
																				{invoice.email}
																			</p>
																		</div>
																	</div>
																</td>
																<td>{invoice.date}</td>
																<td>{invoice.id}</td>
																<td className="text-right">{invoice.total}</td>
																<td>
																	<p
																		className={`mb-0 ${invoice.color} font-weight-bold d-flex justify-content-start align-items-center`}
																	>
																		<small>
																			<svg
																				className="mr-2"
																				xmlns="http://www.w3.org/2000/svg"
																				width="18"
																				viewBox="0 0 24 24"
																				fill="none"
																			>
																				<circle
																					cx="12"
																					cy="12"
																					r="8"
																					fill="#3cb72c"
																				></circle>
																			</svg>
																		</small>
																		{invoice.status}
																	</p>
																</td>
																<td>
																	<div className="d-flex justify-content-end align-items-center">
																		<OverlayTrigger
																			placement="top"
																			overlay={<Tooltip>View</Tooltip>}
																		>
																			<Link className="" to="/invoice-view">
																				<svg
																					xmlns="http://www.w3.org/2000/svg"
																					className="text-secondary mx-4"
																					width="20"
																					fill="none"
																					viewBox="0 0 24 24"
																					stroke="currentColor"
																				>
																					<path
																						strokeLinecap="round"
																						strokeLinejoin="round"
																						strokeWidth="2"
																						d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
																					/>
																					<path
																						strokeLinecap="round"
																						strokeLinejoin="round"
																						strokeWidth="2"
																						d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
																					/>
																				</svg>
																			</Link>
																		</OverlayTrigger>
																		<OverlayTrigger
																			placement="top"
																			overlay={<Tooltip>Print</Tooltip>}
																		>
																			<Link
																				className=""
																				data-toggle="tooltip"
																				data-placement="top"
																				title=""
																				data-original-title="Print"
																				to="#"
																			>
																				<svg
																					xmlns="http://www.w3.org/2000/svg"
																					className="text-secondary"
																					width="20"
																					fill="none"
																					viewBox="0 0 24 24"
																					stroke="currentColor"
																				>
																					<path
																						strokeLinecap="round"
																						strokeLinejoin="round"
																						strokeWidth="2"
																						d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
																					/>
																				</svg>
																			</Link>
																		</OverlayTrigger>
																	</div>
																</td>
															</tr>
														))}
													{/* <tr className="white-space-no-wrap">
                                                <td className="pr-0">
                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                    <input type="checkbox" className="custom-control-input m-0" id="customCheck2"/>
                                                    <label className="custom-control-label" htmlFor="customCheck2"></label>
                                                    </div>
                                                </td>
                                                <td className="">
                                                    <div className="active-project-1 d-flex align-items-center mt-0 ">
                                                        <div className="h-avatar is-medium">
                                                            <img className="avatar rounded-circle" alt="user-icon" src={user2}/>
                                                        </div>
                                                        <div className="data-content">
                                                            <div>
                                                            <span className="font-weight-bold">Matt Damon</span>                           
                                                            </div>
                                                            <p className="m-0 text-secondary small">
                                                            mattdamon@blueberry.com
                                                            </p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>02 Jan 2021</td>
                                                <td>
                                                    IN-120010
                                                </td>
                                                <td className="text-right">
                                                    $99.00
                                                </td>
                                                <td>
                                                    <p className="mb-0 text-success font-weight-bold d-flex justify-content-start align-items-center">
                                                        <small><svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">                                                
                                                        <circle cx="12" cy="12" r="8" fill="#3cb72c"></circle></svg>
                                                        </small>Paid
                                                    </p>
                                                </td>
                                                <td>
                                                    <div className="d-flex justify-content-end align-items-center">                                                        
                                                        <Link className="" data-toggle="tooltip" data-placement="top" title="" data-original-title="View" to="/invoice-view">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-secondary mx-4" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                            </svg>
                                                        </Link>
                                                        <Link className="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Print" to="#">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-secondary" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
                                                            </svg>
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className="white-space-no-wrap">
                                                <td className="pr-0">
                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                    <input type="checkbox" className="custom-control-input m-0" id="customCheck3"/>
                                                    <label className="custom-control-label" htmlFor="customCheck3"></label>
                                                    </div>
                                                </td>
                                                <td className="">
                                                    <div className="active-project-1 d-flex align-items-center mt-0 ">
                                                        <div className="h-avatar is-medium">
                                                            <img className="avatar rounded-circle"  alt="user-icon" src={user3}/>
                                                        </div>
                                                        <div className="data-content">
                                                            <div>
                                                            <span className="font-weight-bold">Caitriona Balfe</span>                           
                                                            </div>
                                                            <p className="m-0 text-secondary small">
                                                            caitrionabalfe204@blueberry.com
                                                            </p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>02 Jan 2021</td>
                                                <td>
                                                    IN-125623
                                                </td>
                                                <td className="text-right">
                                                    $249.49
                                                </td>
                                                <td>
                                                    <p className="mb-0 text-warning font-weight-bold d-flex justify-content-start align-items-center">
                                                        <small><svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">                                                
                                                        <circle cx="12" cy="12" r="8" fill="#db7e06"></circle></svg>
                                                        </small>Pending
                                                    </p>
                                                </td>
                                                <td>
                                                    <div className="d-flex justify-content-end align-items-center">                                                        
                                                        <Link className="" data-toggle="tooltip" data-placement="top" title="" data-original-title="View" to="/invoice-view">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-secondary mx-4" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                            </svg>
                                                        </Link>
                                                        <Link className="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Print" to="#">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-secondary" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
                                                            </svg>
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className="white-space-no-wrap">
                                                <td className="pr-0">
                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                    <input type="checkbox" className="custom-control-input m-0" id="customCheck4"/>
                                                    <label className="custom-control-label" htmlFor="customCheck4"></label>
                                                    </div>
                                                </td>
                                                <td className="">
                                                    <div className="active-project-1 d-flex align-items-center mt-0 ">
                                                        <div className="h-avatar is-medium">
                                                            <img className="avatar rounded-circle" alt="user-icon" src={user4}/>
                                                        </div>
                                                        <div className="data-content">
                                                            <div>
                                                            <span className="font-weight-bold">Josh Lucas</span>                           
                                                            </div>
                                                            <p className="m-0 text-secondary small">
                                                            joshlucas007@blueberry.com
                                                            </p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>02 Jan 2021</td>
                                                <td>
                                                    IN-662210
                                                </td>
                                                <td className="text-right">
                                                    $9.99
                                                </td>
                                                <td>
                                                    <p className="mb-0 text-success font-weight-bold d-flex justify-content-start align-items-center">
                                                        <small><svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">                                                
                                                        <circle cx="12" cy="12" r="8" fill="#3cb72c"></circle></svg>
                                                        </small>Paid
                                                    </p>
                                                </td>
                                                <td>
                                                    <div className="d-flex justify-content-end align-items-center">                                                        
                                                        <Link className="" data-toggle="tooltip" data-placement="top" title="" data-original-title="View" to="/invoice-view">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-secondary mx-4" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                            </svg>
                                                        </Link>
                                                        <Link className="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Print" to="#">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-secondary" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
                                                            </svg>
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className="white-space-no-wrap">
                                                <td className="pr-0">
                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                    <input type="checkbox" className="custom-control-input m-0" id="customCheck5"/>
                                                    <label className="custom-control-label" htmlFor="customCheck5"></label>
                                                    </div>
                                                </td>
                                                <td className="">
                                                    <div className="active-project-1 d-flex align-items-center mt-0 ">
                                                        <div className="h-avatar is-medium">
                                                            <img className="avatar rounded-circle" alt="user-icon" src={user5}/>
                                                        </div>
                                                        <div className="data-content">
                                                            <div>
                                                            <span className="font-weight-bold">Jon Bernthal</span>                           
                                                            </div>
                                                            <p className="m-0 text-secondary small">
                                                            jon.Bernthal@blueberry.com
                                                            </p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>03 Jan 2021</td>
                                                <td>
                                                    IN-901020
                                                </td>
                                                <td className="text-right">
                                                    $90.49
                                                </td>
                                                <td>
                                                    <p className="mb-0 text-danger font-weight-bold d-flex justify-content-start align-items-center">
                                                        <small><svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">                                                
                                                        <circle cx="12" cy="12" r="8" fill="#F42B3D"></circle></svg>
                                                        </small>Cancelled
                                                    </p>
                                                </td>
                                                <td>
                                                    <div className="d-flex justify-content-end align-items-center">                                                        
                                                        <Link className="" data-toggle="tooltip" data-placement="top" title="" data-original-title="View" to="/invoice-view">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-secondary mx-4" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                            </svg>
                                                        </Link>
                                                        <Link className="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Print" to="#">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-secondary" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
                                                            </svg>
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className="white-space-no-wrap">
                                                <td className="pr-0">
                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                    <input type="checkbox" className="custom-control-input m-0" id="customCheck6"/>
                                                    <label className="custom-control-label" htmlFor="customCheck6"></label>
                                                    </div>
                                                </td>
                                                <td className="">
                                                    <div className="active-project-1 d-flex align-items-center mt-0 ">
                                                        <div className="h-avatar is-medium">
                                                            <img className="avatar rounded-circle" alt="user-icon" src={user6}/>
                                                        </div>
                                                        <div className="data-content">
                                                            <div>
                                                            <span className="font-weight-bold">Noah Jupe</span>                           
                                                            </div>
                                                            <p className="m-0 text-secondary small">
                                                            nnoahjupe@blueberry.com
                                                            </p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>05 Jan 2021</td>
                                                <td>
                                                    IN-902210
                                                </td>
                                                <td className="text-right">
                                                    $39.99
                                                </td>
                                                <td>
                                                    <p className="mb-0 text-success font-weight-bold d-flex justify-content-start align-items-center">
                                                        <small><svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">                                                
                                                        <circle cx="12" cy="12" r="8" fill="#3cb72c"></circle></svg>
                                                        </small>Paid
                                                    </p>
                                                </td>
                                                <td>
                                                    <div className="d-flex justify-content-end align-items-center">                                                        
                                                        <Link className="" data-toggle="tooltip" data-placement="top" title="" data-original-title="View" to="/invoice-view">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-secondary mx-4" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                            </svg>
                                                        </Link>
                                                        <Link className="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Print" to="#">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-secondary" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
                                                            </svg>
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className="white-space-no-wrap">
                                                <td className="pr-0">
                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                    <input type="checkbox" className="custom-control-input m-0" id="customCheck7"/>
                                                    <label className="custom-control-label" htmlFor="customCheck7"></label>
                                                    </div>
                                                </td>
                                                <td className="">
                                                    <div className="active-project-1 d-flex align-items-center mt-0 ">
                                                        <div className="h-avatar is-medium">
                                                            <img className="avatar rounded-circle" alt="user-icon" src={user3}/>
                                                        </div>
                                                        <div className="data-content">
                                                            <div>
                                                            <span className="font-weight-bold">Tracy Letts</span>                           
                                                            </div>
                                                            <p className="m-0 text-secondary small">
                                                            tracyetts@blueberry.com
                                                            </p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>06 Jan 2021</td>
                                                <td>
                                                    IN-902559
                                                </td>
                                                <td className="text-right">
                                                    $19.22
                                                </td>
                                                <td>
                                                    <p className="mb-0 text-warning font-weight-bold d-flex justify-content-start align-items-center">
                                                        <small><svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">                                                
                                                        <circle cx="12" cy="12" r="8" fill="#db7e06"></circle></svg>
                                                        </small>Pending
                                                    </p>
                                                </td>
                                                <td>
                                                    <div className="d-flex justify-content-end align-items-center">                                                        
                                                        <Link className="" data-toggle="tooltip" data-placement="top" title="" data-original-title="View" to="/invoice-view">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-secondary mx-4" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                            </svg>
                                                        </Link>
                                                        <Link className="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Print" to="#">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-secondary" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
                                                            </svg>
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className="white-space-no-wrap">
                                                <td className="pr-0">
                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                    <input type="checkbox" className="custom-control-input m-0" id="customCheck8"/>
                                                    <label className="custom-control-label" htmlFor="customCheck8"></label>
                                                    </div>
                                                </td>
                                                <td className="">
                                                    <div className="active-project-1 d-flex align-items-center mt-0 ">
                                                        <div className="h-avatar is-medium">
                                                            <img className="avatar rounded-circle" alt="user-icon" src={user5}/>
                                                        </div>
                                                        <div className="data-content">
                                                            <div>
                                                            <span className="font-weight-bold">Ray McKinnon</span>                           
                                                            </div>
                                                            <p className="m-0 text-secondary small">
                                                            raymcKinnon@blueberry.com
                                                            </p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>09 Jan 2021</td>
                                                <td>
                                                    IN-911211
                                                </td>
                                                <td className="text-right">
                                                    $102.9
                                                </td>
                                                <td>
                                                    <p className="mb-0 text-danger font-weight-bold d-flex justify-content-start align-items-center">
                                                        <small><svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">                                                
                                                        <circle cx="12" cy="12" r="8" fill="#F42B3D"></circle></svg>
                                                        </small>Cancelled
                                                    </p>
                                                </td>
                                                <td>
                                                    <div className="d-flex justify-content-end align-items-center">                                                        
                                                        <Link className="" data-toggle="tooltip" data-placement="top" title="" data-original-title="View" to="/invoice-view">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-secondary mx-4" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                            </svg>
                                                        </Link>
                                                        <Link className="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Print" to="#">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-secondary" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
                                                            </svg>
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className="white-space-no-wrap">
                                                <td className="pr-0">
                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                    <input type="checkbox" className="custom-control-input m-0" id="customCheck9"/>
                                                    <label className="custom-control-label" htmlFor="customCheck9"></label>
                                                    </div>
                                                </td>
                                                <td className="">
                                                    <div className="active-project-1 d-flex align-items-center mt-0 ">
                                                        <div className="h-avatar is-medium">
                                                            <img className="avatar rounded-circle" alt="user-icon" src={user4}/>
                                                        </div>
                                                        <div className="data-content">
                                                            <div>
                                                            <span className="font-weight-bold">Remo Girone</span>                           
                                                            </div>
                                                            <p className="m-0 text-secondary small">
                                                            remo.girone420@blueberry.com
                                                            </p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>10 Jan 2021</td>
                                                <td>
                                                    IN-902559
                                                </td>
                                                <td className="text-right">
                                                    $13.99
                                                </td>
                                                <td>
                                                    <p className="mb-0 text-success font-weight-bold d-flex justify-content-start align-items-center">
                                                        <small><svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">                                                
                                                        <circle cx="12" cy="12" r="8" fill="#3cb72c"></circle></svg>
                                                        </small>Paid
                                                    </p>
                                                </td>
                                                <td>
                                                    <div className="d-flex justify-content-end align-items-center">                                                        
                                                        <Link className="" data-toggle="tooltip" data-placement="top" title="" data-original-title="View" to="/invoice-view">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-secondary mx-4" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                            </svg>
                                                        </Link>
                                                        <Link className="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Print" to="#">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-secondary" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
                                                            </svg>
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className="white-space-no-wrap">
                                                <td className="pr-0">
                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                    <input type="checkbox" className="custom-control-input m-0" id="customCheck10"/>
                                                    <label className="custom-control-label" htmlFor="customCheck10"></label>
                                                    </div>
                                                </td>
                                                <td className="">
                                                    <div className="active-project-1 d-flex align-items-center mt-0 ">
                                                        <div className="h-avatar is-medium">
                                                            <img className="avatar rounded-circle" alt="user-icon" src={user2}/>
                                                        </div>
                                                        <div className="data-content">
                                                            <div>
                                                            <span className="font-weight-bold">Jack McMullen</span>                           
                                                            </div>
                                                            <p className="m-0 text-secondary small">
                                                            jackmullen@blueberry.com
                                                            </p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>12 Jan 2021</td>
                                                <td>
                                                    IN-302240
                                                </td>
                                                <td className="text-right">
                                                    $25.99
                                                </td>
                                                <td>
                                                    <p className="mb-0 text-success font-weight-bold d-flex justify-content-start align-items-center">
                                                        <small><svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">                                                
                                                        <circle cx="12" cy="12" r="8" fill="#3cb72c"></circle></svg>
                                                        </small>Paid
                                                    </p>
                                                </td>
                                                <td>
                                                    <div className="d-flex justify-content-end align-items-center">                                                        
                                                        <Link className="" data-toggle="tooltip" data-placement="top" title="" data-original-title="View" to="/invoice-view">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-secondary mx-4" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                            </svg>
                                                        </Link>
                                                        <Link className="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Print" to="#">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-secondary" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
                                                            </svg>
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr> */}
												</tbody>
											</table>
										</div>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</>
	)
}

export default Hotel
