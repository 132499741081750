import { useContext } from "react"
import { FarmContext } from "../contexts"

const colors = {
  default: "#9a9a9a",
  online: "#84bf40",
  offline: "red",
}

const status_style = {
  width: "100%",
  display: "flex",
  background: colors.default,
  height: "3px",
}

const status_item_style = {
  height: "3px",
}

const WorkersStatusBar = ({}) => {
  const { farm } = useContext(FarmContext)
  const {
    workers_total: total,
    workers_online: online,
    workers_offline: offline,
  } = farm.data.stats

  if (total === 0) return <div style={status_style}></div>

  const width = 100 / total + "%"

  const array = Array(total)
    .fill(colors.online, 0, online)
    .fill(colors.offline, online, offline)

  return (
    <div style={status_style}>
      {array.map((background, index) => (
        <div
          key={index}
          style={Object.assign({}, status_item_style, { background, width })}
        ></div>
      ))}
    </div>
  )
}

export default WorkersStatusBar
