import { Modal, Button } from "react-bootstrap"

const DeleteModal = ({ confirmDelete, close, show }) => {
	return (
		<Modal show={show.show}>
			<Modal.Header closeButton onHide={close}>
				<Modal.Title>Confirm Delete</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				Are you sure that you want to{" "}
				<span className="text-danger">delete</span> this record?
			</Modal.Body>
			<Modal.Footer>
				<Button variant="outline-secondary" onClick={close}>
					Close
				</Button>
				<Button variant="outline-danger" onClick={confirmDelete}>
					Delete
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default DeleteModal
