import { useEffect, useState } from "react"
import { SmartAccountsAPI } from "../../../../../config"
import { ArticleContext } from "../contexts"

const Articles = ({ children }) => {
  const [articles, setArticles] = useState([])

  useEffect(() => {
    let mounted = true

    SmartAccountsAPI.request("purchasesales/articles", "get").then((response) =>
      setArticles(response.articles)
    )

    return () => {
      mounted = false
    }
  }, [])

  return (
    <>
      {articles.map((article) => (
        <ArticleContext.Provider key={article.code} value={article}>
          {children}
        </ArticleContext.Provider>
      ))}
    </>
  )
}

Articles.defaultProps = {
  children: [],
}

export default Articles
