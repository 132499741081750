import React, { useState, useRef, useEffect } from "react"
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	InputGroup,
	Nav,
	Tab,
} from "react-bootstrap"
import Card from "../../../components/Card"
import { Link, useHistory, useParams } from "react-router-dom"
//datepicker
import classnames from "classnames"
import Datepickers from "../../../components/Datepicker"
import { db, storage } from "../../../config"

const CustomerAdd = () => {
	const [activeTab, setActiveTab] = useState("Entity 1")
	const [invoice, setInvoice] = useState({})
	const { id } = useParams()

	const history = useHistory()

	const fetchInvoice = async () => {
		const response = db.collection("invoices").doc(id)
		await response.get().then((item) => setInvoice(item.data()))
	}

	useEffect(() => {
		fetchInvoice()
	}, [])

	return (
		<>
			<Container fluid>
				<Row>
					<Col lg="12">
						<div className="d-flex flex-wrap align-items-center justify-content-between">
							<div className="d-flex align-items-center justify-content-between">
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb p-0 mb-0">
										<li className="breadcrumb-item">
											<Link to="/invoice">Invoices</Link>
										</li>
										<li className="breadcrumb-item active" aria-current="page">
											Completed Invoice
										</li>
									</ol>
								</nav>
							</div>
							<Link
								to="/invoice"
								className="btn btn-primary btn-sm d-flex align-items-center justify-content-between"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="20"
									viewBox="0 0 20 20"
									fill="currentColor"
								>
									<path
										fillRule="evenodd"
										d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
										clipRule="evenodd"
									/>
								</svg>
								<span className="ml-2">Back</span>
							</Link>
						</div>
					</Col>
					<Col lg="12" className="mb-3 d-flex justify-content-between">
						<h4 className="font-weight-bold0 d-flex align-items-center">
							Completed Invoice
						</h4>
					</Col>
					<Col lg="12">
						<Card>
							<Card.Body>
								<Row>
									<Col>
										<p class="text-capitalize">
											Name:{" "}
											<span class="text-lowercase">{invoice.imgName}</span>
										</p>
									</Col>
								</Row>
							</Card.Body>
						</Card>
					</Col>

					<Col lg="12">
						<Card>
							<Card.Body className="p-0">
								<Tab.Container defaultActiveKey={activeTab}>
									<Nav
										variant="tabs"
										activeKey={activeTab}
										onChange={(e) => console.log(e)}
									>
										{invoice.entities
											? invoice.entities.map((entity) => (
													<Nav.Item className="p-1">
														<Nav.Link
															data-toggle="tab"
															eventKey={entity.flag}
															bsPrefix="font-weight-bold text-uppercase px-5 py-2"
															onClick={(e) => setActiveTab(e.target.id)}
															id={entity.flag}
														>
															{entity.flag}
														</Nav.Link>
													</Nav.Item>
											  ))
											: null}
									</Nav>

									<Tab.Content className="p-3">
										{invoice.entities
											? invoice.entities.map((entity) => (
													<Tab.Pane
														eventKey={entity.flag}
														className="fade show"
													>
														<Row>
															<Col>
																<p class="text-capitalize">
																	GPU Type:{" "}
																	<span class="text-uppercase">
																		{entity.product}
																	</span>
																</p>
															</Col>
															<Col md="12">
																<div className="table-responsive">
																	<table className="table data-table mb-0">
																		<thead className="table-color-heading">
																			<tr className="text-muted">
																				<th scope="col"></th>
																				<th scope="col">Gpu Serial</th>
																			</tr>
																		</thead>
																		<tbody>
																			{entity.fields &&
																				entity.fields.map((serial, index) => (
																					<tr>
																						<td>#{index + 1}</td>
																						<td className="w-100">
																							{serial.value}
																						</td>
																					</tr>
																				))}
																		</tbody>
																	</table>
																</div>
															</Col>
														</Row>
													</Tab.Pane>
											  ))
											: null}
									</Tab.Content>
								</Tab.Container>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	)
}

export default CustomerAdd
