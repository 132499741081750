import React from 'react'

const EtContract = ({
	companyName,
	companyCode,
	fullName,
	idCode,
	address,
}) => {
	return (
		<div className="d-none">
			<div id="contractPDF" className="contractPDF">
				<div>
					<div style={{ textAlign: "center" }}>
						<b>Contract for the services of equipment co-location</b>
					</div>
					<br />
					<br />
					Tallinn, {new Date().toLocaleDateString()}
					<br />
					<br />
					OÜ CryptoTech, registrikood 14725379, asukoht Pikaliiva tn 90/1-6,
					13516, Tallinn, mida esindab põhikirja alusel juhatuse liige Vladislav
					Žurbenko / Jan Kolossovski (edaspidi nimetatud CryptoTech), ühelt
					poolt
					<br />
					ja
					<br />
					{companyName ? companyName : fullName},{" "}
					{companyName ? `registrikood ${companyCode} /` : null} isikukood (
					{idCode}),{" "}
					{companyName
						? `asukohaga ${address} mida esindab põhikirja alusel juhatuse liige ${fullName}`
						: `aadress ${address}`}
					, (edaspidi nimetatud Klient), teiselt poolt sõlmisid käesoleva
					lepingu (edaspidi nimetatud Leping) alljärgneva kohta :
					<br />
					<br />
				</div>
				<div>
					<ol>
						<li>
							<b>1. Lepingu ese</b>
							<ol>
								<li>
									Lepingu esemeks on Kliendile arvutitehnika majutamise teenuse
									osutamine, s.t. CryptoTech tehnikakeskuses koha pakkumine
									Kliendi seadmete paigutamiseks, samuti Kliendile interneti- ja
									elektrivõrguühenduse ning muude täiendavate teenuste pakkumine
									(edaspidi nimetatud Teenused).
								</li>
								<li>
									CryptoTech tehnikakeskuse tehnilised näitajad on ära toodud
									Lepingu Lisas 1.
								</li>
							</ol>
						</li>
						<li>
							<b>Teenuste osutamise kord</b>
							<ol>
								<li>
									Teenuste osutamise alguskuupäevaks loetakse päeva, millal on
									Kliendi seadmed paigutatud CryptoTech tehnikakeskusesse ja on
									ühendatud elektri- ja internetivõrguga.
								</li>
								<li>
									Seadmete loetelu, mille Klient on CryptoTech’ile üle on
									andnud, on ära toodud Lepingu Lisas 2.
								</li>
							</ol>
						</li>
						<li>
							<b>CryptoTech’i õigused ja kohustused</b>
							<ol>
								<li>
									CryptoTech kohustub tagama Kliendile Teenuste ööpäevaringse
									kättesaadavuse.
								</li>
								<li>
									CryptoTech kohustub teavitama Klienti seadme töös tekkinud
									tõrgetest ja asuma tõrke eemaldamiseks vajalikku diagnostikat
									tegema kolme tööpäeva jooksul. Pärast diagnostikat võtab
									CryptoTech Kliendiga ühendust ja kooskõlastab temaga tekkinud
									probleemi võimalikud lahendused ja maksumuse juhul, kui tõrge
									ei ole tekkinud CryptoTech’ist tingitud põhjusel.
								</li>
								<li>
									CryptoTech kohustub likvideerima tõrke omal kulul kolme (3)
									päeva jooksul, kui tõrke tekkimise asjaolud on seotud
									CryptoTech’iga.
								</li>
								<li>
									CryptoTech kohustub informeerima Klienti vähemalt 48 tundi
									ette kõigist võimalikest Teenuse kasutamist takistavatest
									asjaoludest, sealhulgas tõrgetest tehniliste põhjuste tõttu.
								</li>
								<li>
									CryptoTech’il on õigus teha muudatusi osutatavate Teenuste
									hinnas ja tingimustes, teavitades sellest Klienti kirjalikult
									ette vähemalt 30 kalendripäeva. Juhul, kui muudatused on
									Kliendile vastuvõetamatud, on tal õigus Leping ülesöelda 30
									päeva jooksul peale vastava CryptoTech’i teavituse edastamist.
								</li>
								<li>
									CryptoTech’il on õigust peatada ja piirata Teenuste osutamist,
									kui Klient rikub Lepingu tingimusi. Sealhulgas juhul, kui
									Klient ei ole tasunud oma makset talle esitatud arvel ära
									näidatud kuupäevaks, või kui ta on viivitanud makse tegemisega
									enam kui 5 päeva. Enne Teenuste osutamise peatamist või
									piiramist teavitab CryptoTech Klienti elektronposti teel
									vähemalt 5 päeva ette. Kui Klient ei ole nimetatud 5 päeva
									jooksul likvideerinud oma võlgnevust, lülitab CryptoTech
									seadmed elektri- ja internetivõrgust välja. Teenuste osutamise
									peatamine või piiramine ei lõpeta seadmete CryptoTech
									tehnikakeskusesse paigutamise eest maksmisele kuuluva tasu
									maksmist, milline kuulub tasumisele vastavalt Lepingu Lisa 3
									sätetele.
								</li>
								<li>
									CryptoTech tagab tema käsutuses olevate kliendiandmete
									töötlemise vastavalt seadusega kehtestatud nõuetele.
								</li>
								<li>
									Täiendava teenusena pakub CryptoTech Kliendi seadmete 95%
									komponentide väljavahetamist. Nimetatud teenust osutatakse
									eraldi hinnapakkumise alusel.
								</li>
							</ol>
						</li>
						<li>
							<b>Kliendi õigused ja kohustused</b>
							<ol>
								<li>
									Kliendil on õigus kasutada Teenust vastavalt Lepingule ja
									õigusaktidele.
								</li>
								<li>
									Klient kohustub:
									<ol>
										<li>
											tasuma osutatud Teenuse eest CryptoTech esitatud arvetel
											näidatud tähtaegadel;
										</li>
										<li>
											teavitama CryptoTech’i tuvastatud puudustest, tõrgetest ja
											talitlushäiretest telefonil + 372 53236058 või
											elektronposti aadressil info@cryptotech.com.
										</li>
									</ol>
								</li>
								<li>
									Klient vastutab täies ulatuses oma seadmete seadistamise,
									korrasoleku ja monitooringu eest.
								</li>
								<li>
									Seadmete üleandmisel nende paigaldamiseks CryptoTech
									tehnikakeskusesse võib Klient tellida CryptoTech’ilt seadmete
									diagnostika. Rikete avastamise korral pakub CryptoTech seadme
									vigade parandamise variandid ja teeb ka parandustööde kohta
									vastava hinnapakkumise.
								</li>
							</ol>
						</li>
						<li>
							<b>Teenuste eest tasumine</b>
							<ol>
								<li>
									CryptoTech tehnikakeskuse hinnad on toodud Lepingu Lisas 3.
								</li>
								<li>
									Teenuste maksumus kujuneb CryptoTech’ile üle antud seadmete
									ühikute arvu alusel, samuti Kliendi seadmete tööga seotud
									hüvitamisele kuuluvaid kulutuse alusel. Kliendi seadmetega
									seotud kulutused arvutatakse igakuiselt tegeliku tarbimise
									alusel.
								</li>
								<li>
									CryptoTech väljastab Kliendile igakuiselt arve Teenuse
									osutamise eest. Arve maksetähtaeg on 7 päeva arvates selle
									väljastamisest.
								</li>
								<li>
									Arve mittetähtaegse tasumise korral on Klient kohustatud
									maksma tasumata summalt viivist 0,066% päevas iga maksmisega
									viivitatud päeva eest.
								</li>
								<li>
									Kui Klient ei tasu tähtaegselt, lülitatakse Kliendi seadmed
									Lepingu punkti 3.6 kohaselt elektri -ja internetivõrgust
									välja. Teenuste osutamise peatamine või piiramine ei lõpeta
									seadmete CryptoTech tehnikakeskusesse paigutamise eest
									maksmisele kuuluva tasu maksmist, milline kuulub tasumisele
									vastavalt Lepingu Lisa 3 sätetele.
								</li>
								<li>
									Klient on kohustatud hüvitama kõik võlgnevuse väljanõudmisega
									seoses tehtud kulud, sealhulgas ka meeldetuletuste
									väljasaatmise, õigusabi osutamise ja inkassoteenustega seotud
									kulud.
								</li>
							</ol>
						</li>
						<li>
							<b>Konfidentsiaalsusnõue</b>
							<ol>
								<li>
									Klient kohustub mitte avalikustama CryptoTechi tehnikakeskuse
									täpset asukohta, ligipääsukoode nende olemasolu korral, samuti
									ka mistahes muud teavet CryptoTechi tehnikakeskuse ja selle
									Lepingu kohta. Nimetatud tingimuse rikkumise korral kohustub
									Klient maksma trahvi summas 15 000 eurot iga üksiku rikkumise
									eest, tehes seda 10 päeva jooksul arvates CryptoTech’i vastava
									nõude saamisest.
								</li>
								<li>
									Kliendi informatsioon loetakse konfidentsiaalseks teabeks ning
									ka CryptoTech kohustub seda teavet kolmandatele isikutele
									mitte edastama ilma teise Poole vastava eelneva kirjaliku
									nõusolekuta, välja arvatud juhtudel, milliste puhul on teabe
									edastamine ette nähtud Eesti Vabariigi seadusega.
								</li>
								<li>
									CryptoTech võib edastada ja avalikustada Kliendi andmeid
									krediidiregistrile ja inkassoettevõtetele seose Kliendi
									võlgnevusega. Klient annab selleks oma nõusoleku.
								</li>
								<li>
									Konfidentsiaalsuse kohustus kehtib tähtajatult nii Lepingu
									kehtivuse ajal kui ka pärast selle kehtivuse lõppemist.
								</li>
							</ol>
						</li>
						<li>
							<b>Lepingu kehtivus, selle muutmine ja lõpetamine</b>
							<ol>
								<li>
									Leping jõustub selle allakirjutamise momendil ja kehtib
									tähtajatult.
								</li>
								<li>
									Pooltel on õigus sõltumata põhjusest Leping lõpetada, andes
									sellest teisele Poolele teada 10 päeva ette. Seadmed
									tagastatakse Kliendile etteteatamisaja möödumisel.
								</li>
								<li>
									Lepingu olulise rikkumise korral on kummalgi Poolel õigus
									lõpetada Leping ette teatamata.
								</li>
								<li>
									Lepingu mitteolulise rikkumise korral on Lepingu lõpetamine
									võimalik üksnes pärast Lepingut rikkunud poolele kohustuse
									täitmiseks antud täiendava tähtaja möödumist. Kui Pool ei
									täida oma kohustust täiendava tähtaja jooksul, kuulub Leping
									lõpetamisele.
								</li>
							</ol>
						</li>
						<li>
							<b>Poolte vastutus</b>
							<ol>
								<li>
									Pooled vabanevad vastutusest, kui lepinguliste kohustuste
									mittetäitmine või nende mittenõuetekohane täitmine on tingitud
									vääramatu jõu asjaoludest.
								</li>
								<li>
									Vääramatu jõu asjaoludeks loetakse mistahes ettenägematuid
									olukordi, mis ei allu poolte mistahes kontrollile. Sealhulgas,
									kuid mitte ainult, tulekahju, plahvatus, loodusõnnetus, sõda
									jne.
								</li>
								<li>
									Vääramatu jõu asjaolud ei vabasta pooli vääramatu jõu
									asjaolude ja nende tagajärgede likvideerimise kohustustest.
									Samuti peavad Pooled jätkama oma kohustuste täitmist kohe
									pärast takistuste kõrvaldamist.
								</li>
								<li>
									CryptoTech on kohustatud hüvitama kahju, mis on tekkinud
									seoses Kliendi seadmete hävinemise või kahjustamisega, kui
									kahju tekkis perioodil alates seadmete CryptoTech
									tehnikakeskusesse paigaldamiseks vastuvõtmise momendist kuni
									seadmete väljastamiseni ja kui kahju tekkimise põhjuseks on
									CryptoTech’ist sõltuvad asjaolud. CryptoTech ei vastuta
									Kliendi seadmete hävinemise või nende kahjustamise eest, kui
									kahju tekkis Kliendi seadmete mittekorrasoleku või muude
									CryptoTech’ist sõltumatute asjaolude tõttu.
								</li>
								<li>
									Klient on kohustatud hüvitama CryptoTech’ile ja/või teistele
									CryptoTech’i tehnikakeskuse klientidele kahju, mis on tekkinud
									Kliendi CryptoTech’i tehnikakeskusele sinna paigaldamiseks
									üleantud seadmetega seonduva ohu või mittekorrasoleku tõttu.
								</li>
								<li>
									CryptoTech’il on pandiõigus Kliendi seadmetele, mis paiknevad
									CryptoTech tehnilises keskuses, kõikide Lepingust tulenevate
									nõuete tagamiseks.
								</li>
							</ol>
						</li>
						<li>
							<b>Lõppsätted</b>
							<ol>
								<li>
									Klient kinnitab, et ta on enne Lepingu sõlmimist tutvunud
									kõikide Lepingu tingimustega, et need on talle arusaadavad,
									ning et ta on teadlik Lepingust tulenevatest õigustest ja
									kohustustest.
								</li>
								<li>
									Lepingu täitmisel, muutmisel või katkestamisel tekkivad
									vaidlused lahendatakse poolte kokkuleppel. Kui kokkulepet ei
									saavutata, lahendatakse vaidlus Harju Maakohtus.
								</li>
								<li>
									Leping on koostatud kahes ühesugust õiguslikku jõudu omavas
									eksemplaris, millest ühe eksemplari saab CryptoTech ja teise
									Klient.
								</li>
							</ol>
						</li>
						<li>
							<b>Poolte allkirjad</b>
							<br />
							<div>CryptoTech OÜ Esindaja</div>
							<br />
							<br />
							<div>____________________</div>
							<br />
							<br />
							<div>Klient</div>
							<br />
							<br />
							<div>____________________</div>
							<br />
						</li>
					</ol>
				</div>
			</div>
		</div>
	)
}

export default EtContract
