import { useState, useRef, useEffect, Fragment } from "react"
import { validate as isUUID } from "uuid"
import Form from "react-bootstrap/Form"
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap"

const SuggestionStyle = {
  marginRight: "5px",
}

const UUIDInput = ({ children: callback, suggestions }) => {
  const [id, setId] = useState(undefined)
  const [input, setInput] = useState("")
  const inputReference = useRef(null)
  const className = "border-warning"

  useEffect(() => {
    if (inputReference.current === null) return
    if (id === undefined) inputReference.current.classList.add(className)
    else inputReference.current.classList.remove(className)
  }, [id])

  useEffect(() => {
    setId(isUUID(input) === true ? input : undefined)
  }, [input])

  const onChange = (event) => setInput(event.target.value)
  const onClick = (event) => setInput(event.target.getAttribute("data-uuid"))

  return (
    <>
      <>
        <Form.Label htmlFor="item-uuid">Item UUID</Form.Label>
        <Form.Control
          ref={inputReference}
          className="border"
          type="text"
          id="item-uuid"
          aria-describedby="item-uuidHelpBlock"
          onChange={onChange}
          value={input}
        />
        {suggestions.length > 0 ? (
          <Form.Text id="item-uuidHelpBlock" muted>
            Item UUID v4, for example:&nbsp;
            {suggestions.map(([uuid, name]) => (
              <Fragment key={uuid}>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{uuid}</Tooltip>}
                >
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    style={SuggestionStyle}
                    onClick={onClick}
                    data-uuid={uuid}
                  >
                    {name}
                  </Button>
                </OverlayTrigger>
              </Fragment>
            ))}
          </Form.Text>
        ) : (
          <></>
        )}
      </>
      <>{callback({ uuid: id })}</>
    </>
  )
}

UUIDInput.defaultProps = {
  suggestions: [],
}

export default UUIDInput
