import { Switch, BrowserRouter } from "react-router-dom"
import Layout1 from "../layouts/backend/Layout1"
import BlankLayout from "../layouts/BlankLayout"
import PublicRoute from "./PublicRoute"
import PrivateRoute from "./PrivateRoute"

const LayoutsRoute = (props) => {
	return (
		<BrowserRouter>
			<Switch>
				<PublicRoute
					restricted={true}
					component={BlankLayout}
					path={[
						"/auth/confirm-mail",
						"/auth/lock-screen",
						"/auth/recover-password",
						"/auth/sign-in",
						"/auth/sign-up",
						"/404",
						"/500",
						"/coming-soon",
						"/maintenance",
					]}
				/>
				<PrivateRoute component={Layout1} path="/" />
			</Switch>
		</BrowserRouter>
	)
}

export default LayoutsRoute
