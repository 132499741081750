import React, { useEffect, useState } from "react"
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	OverlayTrigger,
	Tooltip,
	Modal,
} from "react-bootstrap"
import Card from "../../../components/Card"
import { Link, useHistory } from "react-router-dom"
import QrReader from "react-qr-scanner"
import ModalDelete from "../../../components/Modal"

// img
import User3 from "../../../assets/images/user/3.jpg"

import { db } from "../../../config"

// const miners = [
// 	{
// 		name: "Jack McMullen",
// 		des: "Noor Cafeteria",
// 		img: `${User3}`,
// 		email: "jackmullen@blueberry.com",
// 		location: "California, USA",
// 		phone: "+25 4415-21445",
// 	},
// ]

const Miner = () => {
	const [miners, setMiners] = useState([])
	const [showQRModal, setShowQRModal] = useState(false)
	const [showGPUModal, setShowGPUModal] = useState(false)
	const [gpuSearch, setGPUSearch] = useState("")
	const [foundGPUMiner, setFoundGPUMiner] = useState([])
	const [foundQR, setFoundQR] = useState("")
	const [foundMinerByID, setFoundMinerByID] = useState({})
	const [deleteModal, setDeleteModal] = useState(false)
	const history = useHistory()

	const fetchMiners = async () => {
		const response = db.collection("miners")
		const data = await response.get()
		const minersArray = []

		data.docs.forEach((item) => {
			const data = item.data()
			data.id = item.id
			minersArray.push(data)
		})
		minersArray.sort((a, b) =>
			a.minerName.localeCompare(
				b.minerName,
				navigator.languages[0] || navigator.language,
				{
					numeric: true,
					ignorePunctuation: true,
				},
			),
		)
		setMiners(minersArray)
	}

	const deleteMiner = (id) => {
		db.collection("miners")
			.doc(id)
			.delete()
			.then(() => {
				const newArr = miners.filter((miner) => miner.id !== id)
				setDeleteModal(false)
				setMiners(newArr)
			})
			.catch((error) => {
				console.error("Error removing document: ", error)
			})
	}

	const handleQRSearch = (data) => {
		if (data && data.text) {
			miners.map((miner) =>
				miner.id === data.text
					? (setShowQRModal(false), history.push(`/miner-view/${data.text}`))
					: null,
			)
		}
	}

	const handleGPUSearch = async (e) => {
		e.preventDefault()
		db.collection("miners")
			.where("gpuSerials", "array-contains", { value: gpuSearch })
			.get()
			.then(function (data) {
				const minersArray = []
				data.forEach((item) => {
					const data = item.data()
					data.id = item.id
					minersArray.push(data)
				})
				setFoundGPUMiner(minersArray)
			})

		// console.log(data)

		// data.docs.forEach((item) => {
		// 	const data = item.data()
		// 	data.id = item.id
		// 	minersArray.push(data)
		// })
		// minersArray.sort((a, b) =>
		// 	a.minerName.localeCompare(
		// 		b.minerName,
		// 		navigator.languages[0] || navigator.language,
		// 		{
		// 			numeric: true,
		// 			ignorePunctuation: true,
		// 		},
		// 	),
		// )
		// setMiners(minersArray)
	}

	const handleOpenModal = (e, modal) => {
		e.preventDefault()
		switch (modal) {
			case "gpuModal":
				setShowGPUModal(!showGPUModal)
				break
			case "qrModal":
				setShowQRModal(!showQRModal)
				break
			default:
				break
		}
	}

	useEffect(() => {
		fetchMiners()
	}, [])
	return (
		<>
			<Container fluid>
				<ModalDelete
					show={deleteModal}
					close={() => setDeleteModal(false)}
					confirmDelete={() => deleteMiner(deleteModal.id)}
				/>
				<Modal show={showGPUModal} onHide={() => setShowGPUModal(false)}>
					<Modal.Header closeButton>
						<Modal.Title>Find GPU</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="modal-product-search d-flex flex-wrap">
							<Form className="d-flex flex-fill position-relative">
								<Form.Group className="mb-0 w-100">
									<Form.Control
										type="text"
										className="form-control w-100"
										id="exampleInputText"
										placeholder="Search Miner"
										onChange={(e) => setGPUSearch(e.target.value)}
									/>
									<Link
										onClick={(e) => handleGPUSearch(e)}
										className="search-link"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											className=""
											width="20"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
											/>
										</svg>
									</Link>
								</Form.Group>
							</Form>
						</div>
						{foundGPUMiner?.map((miner) => (
							<div className="d-flex justify-content-between align-items-center mt-2">
								<div>Miner Name: {miner.minerName}</div>
								<div>Miner Bio: {miner.minerBio}</div>
								<Link
									to={`/miner-view/${miner.id}`}
									className="btn btn-primary"
								>
									Open Miner
								</Link>
							</div>
						))}
					</Modal.Body>
					{/* <Modal.Footer>
						<Button
							variant="secondary"
							onClick={() => setShowModal(!showModal)}
						>
							Close
						</Button>
						<Button variant="primary" onClick={() => setShowModal(!showModal)}>
							Save Changes
						</Button>
					</Modal.Footer> */}
				</Modal>
				<Modal show={showQRModal} onHide={() => setShowQRModal(false)}>
					<Modal.Header closeButton>
						<Modal.Title>QR Scanner</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div>
							{showQRModal ? (
								<QrReader
									style={{ display: "block", width: "100%" }}
									delay={1500}
									facingMode="front"
									legacyMode={true}
									onError={() => console.log("QrScanError")}
									onScan={(data) => handleQRSearch(data)}
								/>
							) : null}
						</div>
					</Modal.Body>
					{/* <Modal.Footer>
						<Button
							variant="secondary"
							onClick={() => setShowModal(!showModal)}
						>
							Close
						</Button>
						<Button variant="primary" onClick={() => setShowModal(!showModal)}>
							Save Changes
						</Button>
					</Modal.Footer> */}
				</Modal>
				<Row>
					<Col lg="12">
						<div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
							<div className="d-flex align-items-center justify-content-between">
								<h4 className="font-weight-bold">Miner</h4>
							</div>
							<div className="create-workform">
								<div className="d-flex flex-wrap align-items-center justify-content-between">
									<div className="modal-product-search d-flex flex-wrap">
										{/* <Form className="mr-3 position-relative">
											<Form.Group className="mb-0">
												<Form.Control
													type="text"
													className="form-control"
													id="exampleInputText"
													placeholder="Search Miner"
												/>
												<Link to="#" className="search-link">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														className=""
														width="20"
														fill="none"
														viewBox="0 0 24 24"
														stroke="currentColor"
													>
														<path
															strokeLinecap="round"
															strokeLinejoin="round"
															strokeWidth="2"
															d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
														/>
													</svg>
												</Link>
											</Form.Group>
				</Form> */}
										<Link
											className="mr-2 btn btn-primary position-relative d-flex align-items-center justify-content-between"
											onClick={(e) => handleOpenModal(e, "gpuModal")}
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												className="mr-2"
												width="20"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth="2"
													d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
												/>
											</svg>
											Search GPU
										</Link>
										<Link
											className="mr-2 btn btn-primary position-relative d-flex align-items-center justify-content-between"
											onClick={(e) => handleOpenModal(e, "qrModal")}
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												className="mr-2"
												width="20"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth="2"
													d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
												/>
											</svg>
											Search QRCode
										</Link>
										<Link
											to="/miner-add"
											className="btn btn-primary position-relative d-flex align-items-center justify-content-between"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												className="mr-2"
												width="20"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth="2"
													d="M12 6v6m0 0v6m0-6h6m-6 0H6"
												/>
											</svg>
											Add Miner
										</Link>
									</div>
								</div>
							</div>
						</div>
						<Row>
							<Col lg="12">
								<Card className="card-block card-stretch">
									<Card.Body className="p-0">
										<div className="d-flex justify-content-between align-items-center p-3">
											<h5 className="font-weight-bold">Miner List</h5>
											<Button variant="btn btn-secondary btn-sm">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													className="mr-1"
													width="20"
													fill="none"
													viewBox="0 0 24 24"
													stroke="currentColor"
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth="2"
														d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
													/>
												</svg>
												Export
											</Button>
										</div>
										<div className="table-responsive">
											<table className="table data-table mb-0">
												<thead className="table-color-heading">
													<tr className="">
														<th scope="col" className="pr-0 w-01">
															<div className="d-flex justify-content-start align-items-end mb-1 ">
																<div className="custom-control custom-checkbox custom-control-inline">
																	<input
																		type="checkbox"
																		className="custom-control-input m-0"
																		id="customCheck1"
																	/>
																	<label
																		className="custom-control-label"
																		htmlFor="customCheck1"
																	></label>
																</div>
															</div>
														</th>
														<th scope="col">Name</th>
														<th scope="col">Miner Bio</th>
														<th scope="col">Gpu Amount</th>
														<th scope="col" className="text-right">
															Action
														</th>
													</tr>
												</thead>
												<tbody>
													{miners &&
														miners.map((miner) => (
															<tr
																key={miner.id}
																className="white-space-no-wrap"
															>
																<td className="pr-0">
																	<div className="custom-control custom-checkbox custom-control-inline">
																		<input
																			type="checkbox"
																			className="custom-control-input m-0"
																			id="customCheck"
																		/>
																		<label
																			className="custom-control-label"
																			htmlFor="customCheck"
																		></label>
																	</div>
																</td>

																<td className="">
																	<div className="active-project-1 d-flex align-items-center mt-0 ">
																		{/* <div className="h-avatar is-medium">
																			<img
																				className="avatar rounded-circle"
																				alt="user-icon"
																				// src={miner.image}
																				src={User3}
																			/>
																		</div> */}
																		{/* <div className="data-content"> */}
																		<div>
																			<span className="font-weight-bold">
																				{miner.minerName}
																			</span>
																		</div>
																		{/* </div> */}
																	</div>
																</td>
																<td className="pr-0">
																	<div className="active-project-1 d-flex align-items-center mt-0 ">
																		{/* <div className="h-avatar is-medium">
																			<img
																				className="avatar rounded-circle"
																				alt="user-icon"
																				// src={miner.image}
																				src={User3}
																			/>
																		</div> */}
																		{/* <div className="data-content"> */}
																		<div>
																			<span className="font-weight-bold">
																				{miner.minerBio}
																			</span>
																		</div>
																		{/* </div> */}
																	</div>
																</td>
																<td>{miner.gpuSerials.length}</td>
																<td>
																	<div className="d-flex justify-content-end align-items-center">
																		<OverlayTrigger
																			placement="top"
																			overlay={<Tooltip>View</Tooltip>}
																		>
																			<Link
																				className=""
																				to={`/miner-view/${miner.id}`}
																			>
																				<svg
																					xmlns="http://www.w3.org/2000/svg"
																					className="text-secondary mx-4"
																					width="20"
																					fill="none"
																					viewBox="0 0 24 24"
																					stroke="currentColor"
																				>
																					<path
																						strokeLinecap="round"
																						strokeLinejoin="round"
																						strokeWidth="2"
																						d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
																					/>
																					<path
																						strokeLinecap="round"
																						strokeLinejoin="round"
																						strokeWidth="2"
																						d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
																					/>
																				</svg>
																			</Link>
																		</OverlayTrigger>
																		{/* <OverlayTrigger
																			placement="top"
																			overlay={<Tooltip>Edit</Tooltip>}
																		>
																			<Link
																				className=""
																				to={`/miner-edit?id=${miner.id}`}
																			>
																				<svg
																					xmlns="http://www.w3.org/2000/svg"
																					className="text-secondary mx-4"
																					width="20"
																					fill="none"
																					viewBox="0 0 24 24"
																					stroke="currentColor"
																				>
																					<path
																						strokeLinecap="round"
																						strokeLinejoin="round"
																						strokeWidth="2"
																						d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
																					/>
																				</svg>
																			</Link>
																		</OverlayTrigger> */}
																		<OverlayTrigger
																			placement="top"
																			overlay={<Tooltip>Delete</Tooltip>}
																		>
																			<Button
																				className="bg-danger border-0"
																				size="sm"
																				onClick={() =>
																					setDeleteModal({
																						id: miner.id,
																						show: true,
																					})
																				}
																			>
																				<svg
																					xmlns="http://www.w3.org/2000/svg"
																					width="20"
																					fill="none"
																					viewBox="0 0 24 24"
																					stroke="currentColor"
																				>
																					<path
																						strokeLinecap="round"
																						strokeLinejoin="round"
																						strokeWidth="2"
																						d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
																					/>
																				</svg>
																			</Button>
																		</OverlayTrigger>
																	</div>
																</td>
															</tr>
														))}
												</tbody>
											</table>
										</div>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</>
	)
}
export default Miner
