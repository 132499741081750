import { OverlayTrigger, Tooltip } from "react-bootstrap"

const BasicTooltip = ({ text, children, placement }) => {
  const renderTooltip = (props) => (
    <Tooltip id="tooltip-top" {...props}>
      {text}
    </Tooltip>
  )

  return children === undefined ? (
    <></>
  ) : text !== undefined ? (
    <OverlayTrigger placement={placement} overlay={renderTooltip}>
      <div>{children}</div>
    </OverlayTrigger>
  ) : (
    <>{children}</>
  )
}

BasicTooltip.defaultProps = {
  text: undefined,
  children: undefined,
  placement: "top",
}

export default BasicTooltip
